import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function FilterComponent({
  ReturnStatusList,
  Filter,
  UpdateFilter,
  RefundSelected,
  RevisionSelected,
}) {
  const { t } = useTranslation("common");
  const [orderId, setOrderId] = useState("");
  const [returnNumber, setReturnNumber] = useState("");
  const [returnStatus, setReturnStatus] = useState(Filter.statusId);

  return (
    <Container>
      {t("General.SearchByOrderId")}
      <Row>
        <Col xs="2">
          <Button
            size="sm"
            className="nowrap"
            type="button"
            onClick={RefundSelected}
          >
            {t("ReturnManager.RefundSelected")}
          </Button>
        </Col>
        <Col xs="2">
          <Button size="sm" type="button" onClick={RevisionSelected}>
            {t("ReturnManager.RevisionAll")}
          </Button>
        </Col>
        <Col xs="2">
          <Form.Control
            as="select"
            size="sm"
            value={returnStatus}
            onChange={HandleReturnStatusChange}
          >
            <option>Return Status</option>
            {ReturnStatusList.map((st, id) => {
              return (
                <option key={id} value={st.Id}>
                  {t(st.Text)}
                </option>
              );
            })}
          </Form.Control>
        </Col>
        <Col xs="2">
          <Form.Control
            type="text"
            size="sm"
            id="OrderIdText"
            placeholder="Order Number"
            value={orderId}
            onChange={(e) => {
              const target = e.target; // Need to persist the event since setState is async.
              setOrderId(target.value);
            }}
            onKeyPress={(e) => {
              if(e.key === 'Enter') {
                GetReturnOrders();
              }
            }}
          />
        </Col>
        <Col xs="2">
          <Form.Control
            type="text"
            size="sm"
            id="startsWith"
            placeholder="Return Number"
            value={returnNumber}
            onChange={(e) => {
              const target = e.target; // Need to persist the event since setState is async.
              setReturnNumber(target.value);
            }}
            onKeyPress={(e) => {
              if(e.key === 'Enter') {
                GetReturnOrders();
              }
            }}
          />
        </Col>
        <Col xs="2" className="nowrap">
          <Button size="sm" type="button" onClick={GetReturnOrders}>
            {t("General.Search")}
          </Button>{" "}
          <Button
            size="sm"
            type="button"
            onClick={ClearFilters}
            className="nowrap"
          >
            {t("General.RemoveFilters")}
          </Button>
        </Col>
      </Row>
    </Container>
  );

  function GetReturnOrders() {
    UpdateFilter({ ...Filter, orderId: orderId, startsWith: returnNumber });
  }

  function ClearFilters() {
    UpdateFilter({
      statusId: "",
      orderId: "",
      sortKey: "Created",
      sortDirection: false,
      startsWith: "",
    });

    setReturnStatus("");
    setOrderId("");
    setReturnNumber("");
  }

  function HandleReturnStatusChange(e) {
    const target = e.target; // Need to persist the event since setState is async.
    setReturnStatus(target.value);
    UpdateFilter({ ...Filter, statusId: target.value });
  }
}
