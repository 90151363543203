import React, { useState, useEffect } from "react";
import { Button, Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import CommentsApiClient from "../ApiClients/CommentsApi";
import { SkruvatCollapsableCard } from "../Components/SkruvatComponents";

export default function Comments({ ReturnOrderId, SetNumberOfComments }) {
  const { t } = useTranslation("common");
  const [comments, setComments] = useState([]);
  const [approveComments, setApproveComments] = useState([]);
  const [addCommentText, setAddCommentText] = useState("");

  useEffect(() => {
    PopulateComments();
  }, []);

  return (
    <>
      {comments.map((comment, index) => {
        return (
          <React.Fragment key={index}>
            <Row>
              <Col xs="11">
                <div className="font-size18">
                  {comment.FromFullName === "Jag"
                    ? t("General.Self")
                    : Comment.FromFullName}
                </div>

                <div className="font-size14">
                  {comment.CreateDateInStringFormat}
                </div>

                <div className="font-size14">
                  <ShowText text={comment.Text} />
                </div>
              </Col>
              <Col xs="1" className="paddingBefore20">
                <Button
                  hidden={comment.DisplayMode != 1}
                  size="sm"
                  variant="danger"
                  onClick={() =>
                    DeleteComment(ReturnOrderId, comment.CommentId)
                  }
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="order-note"></div>
              </Col>
            </Row>
          </React.Fragment>
        );
      })}
      {approveComments.length > 0 ? (
        <SkruvatCollapsableCard
          Title={`${t("ReturnManager.RMApproveCommts")} - ${
            approveComments.length
          }`}
        >
          {approveComments.map((comment, index) => {
            return (
              <React.Fragment key={index}>
                <Row>
                  <Col xs="11">
                    <div className="font-size18">
                      {comment.FromFullName === "Jag"
                        ? t("General.Self")
                        : Comment.FromFullName}
                    </div>

                    <div className="font-size14">
                      {comment.CreateDateInStringFormat}
                    </div>

                    <div className="font-size14">
                      <ShowText text={comment.Text} />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="order-note"></div>
                  </Col>
                </Row>
              </React.Fragment>
            );
          })}
        </SkruvatCollapsableCard>
      ) : null}

      <Row>
        <Col className="font-size18">{t("ReturnManager.RMAddnewNote")}</Col>
      </Row>
      <Row>
        <Col>
          <Form.Control
            size="sm"
            type="text"
            value={addCommentText}
            onChange={(e) => {
              setAddCommentText(e.target.value);
            }}
          />
        </Col>
        <Col>
          <Button
            size="sm"
            variant="success"
            disabled={addCommentText.length < 1}
            onClick={() =>
              CommentsApiClient.AddComment(ReturnOrderId, addCommentText).then(
                (res) => {
                  setAddCommentText("");
                  if (res.success) PopulateComments();
                }
              )
            }
          >
            {t("General.Create")}
          </Button>{" "}
          <Button
            size="sm"
            variant="outline-secondary"
            onClick={() => setAddCommentText("")}
          >
            {t("General.Cancel")}
          </Button>
        </Col>
      </Row>
    </>
  );

  async function PopulateComments() {
    async function GetComments() {
      return await CommentsApiClient.GetComments(ReturnOrderId);
    }

    async function GetApproveComments() {
      return await CommentsApiClient.GetApproveComments(ReturnOrderId);
    }

    GetComments().then((result) => {
      setComments(result);
      SetNumberOfComments(result.length);
    });

    GetApproveComments().then((result) => setApproveComments(result));
  }

  function DeleteComment(ReturnOrderId, CommentId) {
    CommentsApiClient.DeleteComment(ReturnOrderId, CommentId).then(
      PopulateComments()
    );
  }

  function ShowText({ text }) {
    const [expanded, setExpanded] = useState(false);

    if (text.length < 40 || expanded) return text;

    return (
      <span>
        {text.substring(0, 40)}...
        <Button variant="link" onClick={() => setExpanded(true)}>
          More
        </Button>
      </span>
    );
  }
}
