import React, { useContext } from "react";
import { Button, Row, Col, Container, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { SessionContext } from "./Context";
import ReturnWebClient from "./ApiClients/ReturnWebClient";
function Header() {
  const { t } = useTranslation("common");
  const sessionInfo = useContext(SessionContext);
  return (
    <div>
      <Container fluid className="whiteContainer">
        <Row>
          <Col xs="0" sm="3" md="5" lg="7"></Col>

          <Col xs="auto">
            <Button
              className="button navbar inline"
              variant="linkt"
              onClick={GotoMyAccount}
            >
              {t("Menu.MYACCOUNT")}
            </Button>

            <Button
              className="button navbar inline"
              variant="link"
              onClick={Logout}
            >
              {t("Menu.SignOut")}
            </Button>
            <span className="inline">
              <Image
                id="imgNavSel"
                src="/return/Images/flag-sv_SE.png"
                className="d-none d-sm-block vertical-middle"
                style={{ padding: "10px" }}
                alt=""
              />
              <span
                className="d-none d-sm-block"
                style={{ paddingLeft: "40px" }}
              ></span>
            </span>
            <Button
              className="contactButton inline"
              variant="warning"
              onClick={GotoContact}
            >
              {t("Menu.Contactform")}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Image
              id="imgLogo"
              src="/return/Images/logo.png"
              alt=""
              className="img-responsive"
            />

            {/* <div class="search-box" style="display:none;" >  
                <div class="input-group">
                  <input
                    class="search-query form-control"
                    placeholder="Menu.SearchPlaceholder|translate"
                    ng-model="textsearch"
                    type="text"
                  />
                  <div class="input-group-btn">
                    <button
                      class="btn btn-default"
                      type="submit"
                      ng-click="onLinkClick('search');"
                    >
                      <i class="glyphicon glyphicon-search"></i>
                    </button>
                  </div>
                </div>
              </div> */}
          </Col>
        </Row>
        <Row className="headerBar">
          <Col xs="12">
            <Button
              variant="link"
              className="headerBar contactButton float-right"
            >
              <FontAwesomeIcon icon={faPhoneAlt} />
              &nbsp; 08-120 20 420
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );

  function Logout() {
    ReturnWebClient.Logout().finally(() =>
      window.location.replace(sessionInfo.Domain)
    );
  }

  function GotoMyAccount() {
    window.location.href = sessionInfo.Domain + "Members/MyAccount.aspx";
  }

  function GotoContact() {
    window.location.href = sessionInfo.Domain + "contact.aspx";
  }
}

export default Header;
