import React, { useEffect, useState, useContext } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import { LoadingContext } from "../Context";
import { ImageUploadComponent } from "./ImageUploadComponent";
import { ListImagesComponent } from "./ListImagesComponent";

export default function ReturnInformationComponent({
  ApiClient,
  ReturnOrderDetails,
  OnError,
  OnReturnOrderUpdated,
  showRefund,
}) {
  const { t } = useTranslation("common");
  const [edit, setEdit] = useState(false);
  const [tempReturnOrderDetails, setTempReturnOrderDetails] = useState({
    ...ReturnOrderDetails,
  });
  const [imageList, setImageList] = useState([]);
  const [declineImageList, setDeclineImageList] = useState([]);
  const [returnReasonIdentities, setReturnReasonIdentities] = useState([]);
  const [returnReasonSpecs, setReturnReasonSpecs] = useState([]);
  const [returnRefundType, setReturnRefundType] = useState([]);
  const loader = useContext(LoadingContext);

  useEffect(() => {
    async function GetReturnReasonIdentities() {
      return await ApiClient.GetReturnReasonIdentities();
    }

    async function GetReturnReasonSpecs() {
      return await ApiClient.GetReturnReasonSpecs();
    }

    async function GetReturnRefundType() {
      return await ApiClient.GetReturnRefundType();
    }

    GetReturnReasonIdentities().then((result) =>
      setReturnReasonIdentities(result)
    );

    GetReturnReasonSpecs().then((result) => setReturnReasonSpecs(result));

    if (showRefund)
      GetReturnRefundType().then((result) => setReturnRefundType(result));
  }, []);

  useEffect(() => {
    setImageList(
      ReturnOrderDetails.ReturnOrderImages.filter(
        (predicate) => !predicate.IsDeclineReport
      ).map((img) => {
        return {
          FileName: img.Name,
          FileType: img.FileType,
          Url: img.Path,
        };
      })
    );

    setDeclineImageList(
      ReturnOrderDetails.ReturnOrderImages.filter(
        (predicate) => predicate.IsDeclineReport
      ).map((img) => {
        return {
          FileName: img.Name,
          FileType: img.FileType,
          Url: img.Path,
        };
      })
    );
  }, [ReturnOrderDetails]);

  return (
    <>
      <Row>
        <Col hidden={edit}>
          <Button size="sm" variant="outline-primary" onClick={BeginEdit}>
            <FontAwesomeIcon icon={faPencilAlt} />
          </Button>
          <br />
          <br />
        </Col>
        <Col hidden={!edit}>
          <Button
            size="sm"
            variant="success"
            onClick={SaveReturnInformationChanges}
          >
            Save
          </Button>{" "}
          <Button size="sm" variant="success" onClick={CancelEdit}>
            Cancel
          </Button>
          <br />
          <br />
        </Col>
      </Row>
      <Row>
        <Col sm="6">
          <span className="font-size14 bold">Return order created</span>
          <p className="font-size14">
            {moment(tempReturnOrderDetails.Created).format("YYYY-MM-DD")}
          </p>
          <span className="font-size14 bold">Reason</span>
          <p className="font-size14" hidden={edit}>
            {LookupReturnReasonIdentities(
              tempReturnOrderDetails.RetReasonIdentityId
            )}
          </p>
          <p className="font-size14" hidden={!edit}>
            <select
              value={tempReturnOrderDetails.RetReasonIdentityId}
              onChange={(e) => {
                const target = e.target;
                setTempReturnOrderDetails((prev) => {
                  return { ...prev, RetReasonIdentityId: target.value };
                });
              }}
            >
              {returnReasonIdentities.map((reason, index) => {
                return (
                  <option key={index} value={reason.Id}>
                    {t(reason.Text)}
                  </option>
                );
              })}
            </select>
          </p>
          <span className="font-size14 bold">Replacement</span>
          <p className="font-size14" hidden={edit}>
            {LookupReturnReasonSpecs(tempReturnOrderDetails.RetReasonSpecId)}
          </p>
          <p className="font-size14" hidden={!edit}>
            <select
              value={tempReturnOrderDetails.RetReasonSpecId}
              onChange={(e) => {
                const target = e.target;
                setTempReturnOrderDetails((prev) => {
                  return { ...prev, RetReasonSpecId: target.value };
                });
              }}
            >
              {returnReasonSpecs.map((reason, index) => {
                return (
                  <option key={index} value={reason.Id}>
                    {t(reason.Text)}
                  </option>
                );
              })}
            </select>
          </p>
        </Col>
        <Col sm="6">
          <span className="font-size14 bold">
            {t("ReturnManager.RMDescriptionShortTitle")}
          </span>
          <p className="font-size14" hidden={edit}>
            {tempReturnOrderDetails.ReturnReasonDescription}
          </p>
          <p className="font-size14" hidden={!edit}>
            <textarea
              className="form-control"
              maxLength="1000"
              rows="5"
              defaultValue={tempReturnOrderDetails.ReturnReasonDescription}
              onChange={(e) => {
                const target = e.target;
                setTempReturnOrderDetails((prev) => {
                  return { ...prev, ReturnReasonDescription: target.value };
                });
              }}
            ></textarea>
          </p>
          <span className="font-size14 bold" hidden={!showRefund}>
            {t("ReturnManager.ChangeRefundType")}
          </span>
          <p className="font-size14" hidden={!showRefund || edit}>
            {LookupRefundType(tempReturnOrderDetails.ReturnReasonRefundTypeId)}
          </p>
          <p className="font-size14" hidden={!showRefund || !edit}>
            <select
              value={tempReturnOrderDetails.ReturnReasonRefundTypeId}
              onChange={(e) => {
                const target = e.target;
                setTempReturnOrderDetails((prev) => {
                  return { ...prev, ReturnReasonRefundTypeId: target.value };
                });
              }}
            >
              {returnRefundType.map((reason, index) => {
                return (
                  <option key={index} value={reason.Id}>
                    {t(reason.Text)}
                  </option>
                );
              })}
            </select>
          </p>
        </Col>
      </Row>
      <Row>
        <Col hidden={!tempReturnOrderDetails.ReturnReasonUserCarNumberInfo}>
          <span className="font-size14 bold">
            License plate number and Car model and model year:
          </span>
          <p className="font-size14">
            {tempReturnOrderDetails.ReturnReasonUserCarNumberInfo}
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <div hidden={!tempReturnOrderDetails.WaybillLink}>
            <span className="font-size14 bold">Other Details</span>
            <p
              className="font-size14"
              hidden={!tempReturnOrderDetails.WaybillLink.includes("?")}
            >
              <span className="font-size14">WaybillLink: </span>
              <a
                href={tempReturnOrderDetails.WaybillLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {tempReturnOrderDetails.WaybillLink}
              </a>
            </p>
            <p
              className="font-size14"
              hidden={tempReturnOrderDetails.WaybillLink.includes("?")}
            >
              <span className="font-size14">Tracking number: </span>
              {tempReturnOrderDetails.WaybillLink}
            </p>
            <SetValues Data={tempReturnOrderDetails.SetValues} />
          </div>{" "}
        </Col>
        <Col hidden={edit || imageList.length === 0}>
          <div className="font-size14 bold">Photo of the damaged product</div>
          <ListImagesComponent ImageList={imageList} />
        </Col>
        <Col hidden={!edit}>
          <div className="font-size14 bold">Photo of the damaged product</div>
          <ImageUploadComponent
            ImageList={imageList}
            UpdateImageList={setImageList}
            SaveFileApiEndpoint={ApiClient.SaveFileEdit}
            RemoveFileApiEndpoint={ApiClient.RemoveFile}
            FolderName={ReturnOrderDetails.RetOrderId}
          />
        </Col>
      </Row>
      <Row>
        <Col hidden={declineImageList.length === 0}>
          <div className="font-size14 bold">Decline Report</div>
          <ListImagesComponent ImageList={declineImageList} />
        </Col>
      </Row>
    </>
  );

  function SaveReturnInformationChanges() {
    loader.updateShowLoader(true);
    setEdit(false);

    ApiClient.UpdateReturnOrderEditDetail({
      ...tempReturnOrderDetails,
      ReturnOrderImages: imageList.map((img) => {
        return {
          Name: img.FileName,
          FileType: img.FileType,
          Path: img.Url,
        };
      }),
    })
      .then(
        (res) => {
          if (res.success)
            OnReturnOrderUpdated({
              ...tempReturnOrderDetails,
              ReturnOrderImages: imageList.map((img) => {
                return {
                  Name: img.FileName,
                  FileType: img.FileType,
                  Path: img.Url,
                };
              }),
            });
        },
        (error) => {
          OnError(error);
        }
      )
      .finally(() => {
        loader.updateShowLoader(false);
      });
  }

  function BeginEdit() {
    setEdit(true);
  }

  function CancelEdit() {
    setTempReturnOrderDetails({ ...ReturnOrderDetails });

    setEdit(false);
  }

  function LookupReturnReasonIdentities(id) {
    var returnReasonIdentity = returnReasonIdentities.filter((e) => e.Id == id);
    if (returnReasonIdentity.length === 0) return null;

    return t(returnReasonIdentity[0].Text);
  }

  function LookupReturnReasonSpecs(id) {
    var returnReasonSpec = returnReasonSpecs.filter((e) => e.Id == id);
    if (returnReasonSpec.length === 0) return null;

    return t(returnReasonSpec[0].Text);
  }

  function LookupRefundType(id) {
    var returnReasonSpec = returnRefundType.filter((e) => e.Id == id);
    if (returnReasonSpec.length === 0) return null;

    return t(returnReasonSpec[0].Text);
  }
}

function SetValues({ Data }) {
  if (!Data) return null;
  return Data.map((setValue, index) => {
    return (
      <React.Fragment key={index}>
        <SetValueKey Key={setValue.Key} />
        {setValue.Key === "SET * GARANT1"
          ? moment(setValue.Value, "DD/MM/YYYY").format("YYYY-MM-DD")
          : setValue.Value}
        <br />
      </React.Fragment>
    );
  });
}

function SetValueKey({ Key }) {
  if (Key === "SET * GARANT1")
    return <strong>Date when the problem occurred?: </strong>;
  if (Key === "SET * GARANT2")
    return (
      <strong>
        Approximately how many kilometers have you used the car since you
        installed the part?:{" "}
      </strong>
    );
  if (Key === "SET * GARANT3")
    return <strong>Who has mounted the product on the car?: </strong>;
  if (Key === "SET * GARANT4")
    return <strong>Personal Security Number: </strong>;
}
