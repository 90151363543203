import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  Alert,
  Container,
  Table,
  Card,
  Row,
  Col,
  FormControl,
  Button,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";

import {
  SkruvatCheckbox,
  SkruvatRadioButton,
} from "../Components/SkruvatComponents";
import { LoadingContext } from "../Context";
import ClaimComponent from "../Components/ClaimComponent";
import ReturnComponent from "../Components/ReturnComponent";
import { SkruvatAdminTimelimits } from "../SkruvatHelperFunctions";
import ReturnManagerApiClient from "../ApiClients/ReturnManagerApiClient";

export default function Create() {
  const { t } = useTranslation("common");
  const history = useHistory();
  const [errorMsg, setErrorMsg] = useState("");
  const [IsWholeOrderReturned, setIsWholeOrderReturned] = useState(false);
  const [orderNumber, setOrderNumber] = useState();

  const [returnReason, setReturnReason] = useState("N/A");
  const [order, setOrder] = useState({
    OrderItems: [],
  });
  const [orderItemsSelectionList, setOrderItemsSelectionList] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const [returnDescription, setReturnDescription] = useState("");
  const loader = useContext(LoadingContext);

  useEffect(() => {
    setIsWholeOrderReturned(false);
  }, []);

  function ClearSearchBoxes() {
    setOrderNumber("");
    setUserInfo({});
    setOrder({
      OrderItems: [],
    });
    setOrderItemsSelectionList([]);
    setReturnReason("N/A");
    setIsWholeOrderReturned(false);
    setErrorMsg("");
  }

  function ShowOrder() {
    setErrorMsg("");
    loader.updateShowLoader(true);

    ReturnManagerApiClient.GetOrderItems(orderNumber)
      .then((res) => {
        setUserInfo(res.OrderOwner);
        var neworder = InitializeNewReturnOrder();
        neworder.OrderId = res.orderItems[0].OrderId;
        neworder.OrderDate = res.orderItems[0].OrderDate;
        neworder.OrderItems = [];

        setOrder(neworder);
        setOrderItemsSelectionList(res.orderItems);
        setIsWholeOrderReturned(res.orderItems.every((p) => p.Quantity === 0));
      })
      .catch((err) => {
        setErrorMsg(err);
      })
      .finally(() => {
        loader.updateShowLoader(false);
      });
  }

  return (
    <Container className="whiteContainer mobileResponsiveMarginFix paddingBefore20 paddingAfter20">
      <div className="font-size22">
        {t("Step1.CreateReturnPageTitle")}
        <br />
        <br />
      </div>
      <Alert
        hidden={errorMsg.length === 0}
        variant="danger"
        onClose={() => setErrorMsg("")}
        dismissible
      >
        {errorMsg}
      </Alert>
      <Card>
        <Card.Header>
          <Row>
            <Col>
              <span className="font-size14 bold">{t("Order.OrderId")}</span>
              <FormControl
                placeholder="Or enter order number"
                type="text"
                defaultValue={orderNumber}
                onChange={(e) => setOrderNumber(e.target.value)}
                disabled={order.OrderId !== undefined}
              />
            </Col>
            <Col>
              <br />
              <Button variant="primary" onClick={ShowOrder}>
                {t("General.Search")}
              </Button>{" "}
              <Button variant="outline-secondary" onClick={ClearSearchBoxes}>
                Clear
              </Button>
            </Col>
            <Col>
              <span className="font-size14 bold">Name</span>
              <br />
              {userInfo.FullName ?? "-"}
            </Col>
            <Col>
              <span className="font-size14 bold">Email</span>
              <br />
              {userInfo.Email ?? "-"}
            </Col>
          </Row>
        </Card.Header>
      </Card>
      <br />
      <div hidden={!IsWholeOrderReturned} className="font-size14">
        {t("Order.ReturnedWholeOrderText")}
      </div>
      <Table hidden={IsWholeOrderReturned}>
        <thead>
          <tr>
            <th>
              <SkruvatCheckbox
                onChange={HandleCheckboxAllClick}
                checked={
                  orderItemsSelectionList.length > 0 &&
                  orderItemsSelectionList.every((order) => order.selected)
                }
              />
            </th>
            <th>Product no</th>
            <th>Product name</th>
            <th>Quantity</th>
            <th>Amount to return</th>
          </tr>
        </thead>

        <tbody>
          {orderItemsSelectionList.map((orderItem, index) => {
            return (
              <OrderItem key={index} id={index} item={orderItem}></OrderItem>
            );
          })}
        </tbody>
      </Table>
      <Card hidden={IsWholeOrderReturned}>
        <Card.Body>
          <div className="font-size18 bold">
            {t("Step2.Step2Header")} <span className="asterisk-red">*</span>
          </div>
          <SkruvatRadioButton
            value="WITHDRAWN"
            checked={returnReason === "WITHDRAWN"}
            name="ReturnProduct"
            onChange={() => {
              ShowReturnForm("WITHDRAWN");
            }}
            label={t("Step2.Step2HeaderReturn")}
            disabled={
              new SkruvatAdminTimelimits().IsTooLateForWithdraw(
                order.OrderDate
              ) || !order.OrderItems?.some((o) => o.selected)
            }
          ></SkruvatRadioButton>{" "}
          <SkruvatRadioButton
            value="WRONG_PRODUCT"
            checked={returnReason === "WRONG_PRODUCT"}
            name="WrongProduct"
            onChange={() => {
              ShowReturnForm("WRONG_PRODUCT");
            }}
            label={t("Step2.Step2HeaderComplaint")}
            disabled={
              new SkruvatAdminTimelimits().IsTooLateForWrongProduct(
                order.OrderDate
              ) || !order.OrderItems?.some((o) => o.selected)
            }
          ></SkruvatRadioButton>{" "}
          <SkruvatRadioButton
            value="NOT_PICKED_UP"
            checked={returnReason === "NOT_PICKED_UP"}
            name="ReturnProduct"
            onChange={() => {
              ShowReturnForm("NOT_PICKED_UP");
            }}
            label="Not picked up"
            disabled={!order.OrderItems?.some((o) => o.selected)}
          ></SkruvatRadioButton>
        </Card.Body>
      </Card>
      <br />
      <Card hidden={returnReason !== "WITHDRAWN"}>
        <Card.Body>
          {ReturnComponent(
            order,
            ReturnManagerApiClient,
            OnReturnHandled,
            true
          )}
        </Card.Body>
      </Card>
      <Card hidden={returnReason !== "WRONG_PRODUCT"}>
        <Card.Body>
          {ClaimComponent(
            ReturnManagerApiClient,
            order,
            OnClaimHandled,
            new SkruvatAdminTimelimits(),
            true
          )}
        </Card.Body>
      </Card>
      <Card hidden={returnReason !== "NOT_PICKED_UP"}>
        <Card.Body>
          <Row>
            <Col xs="12" md="6">
              <div className="font-size14 bold">{t("Step3.Description")}</div>
              <textarea
                className="form-control"
                placeholder="If you want, describe more about your case here"
                name="ClaimDescription"
                maxLength="1000"
                rows="5"
                onChange={(e) => setReturnDescription(e.target.value)}
                value={returnDescription}
              ></textarea>
              <br />

              <Button
                className="form-control"
                disabled={returnReason === "N/A"}
                variant="success"
                onClick={CreateNotPickedUpReturnOrder}
              >
                {t("General.Confirm")}
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );

  function ShowReturnForm(retReason) {
    setReturnReason(retReason);
  }

  function OnReturnHandled(event) {
    if (event.Error) {
      setErrorMsg(event.Error);
    } else {
      history.push("/RM/");
    }
  }

  function OnClaimHandled(event) {
    if (event.Error) {
      setErrorMsg(event.Error);
    } else {
      history.push("/RM/");
    }
  }

  async function CreateNotPickedUpReturnOrder() {
    if (returnReason !== "N/A") {
      var result = await ReturnManagerApiClient.SaveReturnOrder(
        order,
        returnReason,
        returnDescription,
        ""
      );

      OnReturnHandled(result);
    }
  }

  function CheckboxClick(event) {
    var findObj = orderItemsSelectionList.findIndex(
      (o) => o.OrderItemId === event.target.name
    );

    if (orderItemsSelectionList[findObj].Quantity <= 0) return;

    orderItemsSelectionList[findObj].selected = !orderItemsSelectionList[
      findObj
    ].selected;
    orderItemsSelectionList[findObj].ReturnQuantity = orderItemsSelectionList[
      findObj
    ].selected
      ? orderItemsSelectionList[findObj].Quantity
      : 0;

    setOrderItemsSelectionList(orderItemsSelectionList);

    setOrder((prev) => {
      return {
        ...prev,
        OrderItems: [
          ...orderItemsSelectionList.filter(
            (o) => o.selected && o.Quantity > 0
          ),
        ],
      };
    });
  }

  function HandleCheckboxAllClick(e) {
    const target = e.target;
    orderItemsSelectionList.forEach((element) => {
      element.selected = target.value;
      element.ReturnQuantity = target.value ? element.Quantity : 0;
    });

    setOrderItemsSelectionList(orderItemsSelectionList);

    setOrder((prev) => {
      return {
        ...prev,
        OrderItems: [
          ...orderItemsSelectionList.filter(
            (o) => o.selected && o.Quantity > 0
          ),
        ],
      };
    });
  }

  function ReturnQuantityChange(event) {
    var findObj = orderItemsSelectionList.findIndex(
      (o) => o.OrderItemId === parseInt(event.target.id, 10)
    );

    orderItemsSelectionList[findObj].ReturnQuantity = parseInt(
      event.target.value,
      10
    );
    orderItemsSelectionList[findObj].selected =
      parseInt(event.target.value, 10) > 0;

    setOrderItemsSelectionList(orderItemsSelectionList);

    setOrder((prev) => {
      return {
        ...prev,
        OrderItems: [
          ...orderItemsSelectionList.filter(
            (o) => o.selected && o.Quantity > 0
          ),
        ],
      };
    });
  }

  function OrderItem({ id, item }) {
    const options = [];
    for (let index = 0; index < item.Quantity + 1; index++) {
      options.push(<option key={index}>{index}</option>);
    }

    return (
      <tr className={item.Quantity <= 0 ? "table-row-disable" : ""}>
        <td>
          <SkruvatCheckbox
            id={id}
            name={item.OrderItemId}
            checked={item.selected}
            onChange={CheckboxClick}
          />
        </td>
        <td>{item.Product.ProductId}</td>
        <td>{item.Product.Name}</td>
        <td>{item.Quantity}</td>
        <td>
          <select
            id={item.OrderItemId}
            value={item.ReturnQuantity}
            onChange={ReturnQuantityChange}
          >
            {options}
          </select>
        </td>
      </tr>
    );
  }
}

function InitializeNewReturnOrder() {
  return {
    ReturnIssues: [],
    RetOrderTag: "0",
    RetOrderStateId: 1,
    Created: "na",
    OwnerId: "0",
    LastModified: "na",
    BIC: "",
    IBAN: "",
    SWIFT: "Sweden (sv-SE)",
    OrderId: 0,
    ReturnReasonDescription: "",
    ReturnReasonUserCarNumber: "",
    ReturnReasonUserCarNumberInfo: "",
    CLEARING: "",
    ACCOUNT: "",
    BANKNAME: "",
    TransactionId: "0",
    SetValuesString: "na",
    OrderOwnerNameAndEmail: "na",
    DecliningComment: "na",
    ReturnReasonRefundTypeId: 1,
    OrderDate: "",
  };
}
