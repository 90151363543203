import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
import common_en from "./translations/en_US.json";
import common_se from "./translations/sv_SE.json";
import common_no from "./translations/nn_NO.json";
import common_fi from "./translations/fi_FI.json";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: "en_US",
    fallbackLng: "en_US",
    debug: true,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    resources: {
      en_US: {
        common: common_en, // 'common' is our custom namespace
      },
      sv_SE: {
        common: common_se,
      },
      nn_NO: {
        common: common_no,
      },
      fi_FI: {
        common: common_fi,
      },
    },
  });

export default i18n;
