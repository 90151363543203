import React, { useEffect, useState, useContext } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import {
  Card,
  Container,
  Button,
  Row,
  Col,
  Modal,
  Form,
  Alert,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt, faUndo } from "@fortawesome/free-solid-svg-icons";

import {
  SkruvatCollapsableCard,
  ActivityAndEmailLogs,
} from "../Components/SkruvatComponents";
import ReturnManagerApiClient from "../ApiClients/ReturnManagerApiClient";
import CustomerApiClient from "../ApiClients/CustomerApiClient";
import Calculator from "../Components/CalculatorComponent";
import Comments from "../Components/CommentComponent";
import { LoadingContext } from "../Context";
import { ImageUploadComponent } from "../Components/ImageUploadComponent";
import ReturnInformationComponent from "../Components/ReturnInformationComponent";
import {
  ReturnDetailsHeader,
  ProductInformation,
  CustomerInformation,
} from "../Components/ReturnDetailsComponents";

export default function ReturnDetails() {
  const { t } = useTranslation("common");
  const { ReturnOrderId } = useParams();
  const [returnOrderDetails, setReturnOrderDetails] = useState({});
  const [originalOrderItems, setOriginalOrderItems] = useState([]);
  const [returnStatusList, setReturnStatusList] = useState([]);
  const [customerDetails, setCustomerDetails] = useState({});
  const [activities, setActivities] = useState([]);
  const [activityAndEmailLogs, setActivityAndEmailLogs] = useState([]);
  const [returnPolicies, setReturnPolicies] = useState([]);
  const [manualTa, setManualTa] = useState(false);
  const [modalParameters, setModalParameters] = useState({
    Show: false,
    Body: () => {
      return <></>;
    },
    Footer: () => {
      return <></>;
    },
    Images: [],
    ManualTrackingNo: "",
  });
  const [errorMsg, setErrorMsg] = useState("");
  const [hideCalculator, setHideCalculator] = useState(true);
  const [numberOfComments, setNumberOfComments] = useState(0);
  const loader = useContext(LoadingContext);
  const history = useHistory();

  useEffect(() => {
    loader.updateShowLoader(true);

    async function GetReturnOrderMoreDetail() {
      return await ReturnManagerApiClient.GetReturnOrderMoreDetail(
        ReturnOrderId
      );
    }

    async function GetOrderItems(orderId) {
      return await ReturnManagerApiClient.GetOrderItems(orderId);
    }

    async function GetActivities() {
      return await ReturnManagerApiClient.GetActivitiesforReturnManager(
        ReturnOrderId
      );
    }

    async function GetActivityAndEmailLogs() {
      return await ReturnManagerApiClient.GetActivityAndEmailLogs(
        ReturnOrderId
      );
    }

    async function GetReturnStatuses() {
      return await ReturnManagerApiClient.GetAllReturnStatus();
    }

    async function GetReturnPolicies() {
      return await ReturnManagerApiClient.GetReturnPolicies();
    }

    var promiseReturnStatus = GetReturnStatuses();
    var promiseReturnOrderMoreDetail = GetReturnOrderMoreDetail();
    var promiseActivities = GetActivities();
    var promiseActivityAndEmailLogs = GetActivityAndEmailLogs();
    var promiseReturnPolicies = GetReturnPolicies();

    Promise.all([
      promiseReturnStatus,
      promiseActivities,
      promiseReturnOrderMoreDetail,
      promiseActivityAndEmailLogs,
      promiseReturnPolicies,
    ]).then((values) => {
      setReturnStatusList(values[0]);
      setActivities(values[1]);
      setReturnOrderDetails(values[2].returnOrder);
      setCustomerDetails(values[2].user);
      setActivityAndEmailLogs(values[3]);
      setReturnPolicies(values[4]);

      if (values[2].returnOrder) {
        GetOrderItems(values[2].returnOrder.OrderId).then((result) => {
          setOriginalOrderItems(result.orderItems);
          loader.updateShowLoader(false);
        });
      } else {
        setErrorMsg("Failed to get return order details");
        loader.updateShowLoader(false);
      }
    });
  }, [ReturnOrderId]);

  if (!returnOrderDetails?.RetOrderId)
    return (
      <Alert hidden={errorMsg.length === 0} variant="danger">
        {errorMsg}
      </Alert>
    );

  return (
    <>
      <Container className="paddingBefore20">
        <Alert
          hidden={errorMsg.length === 0}
          variant="danger"
          onClose={() => setErrorMsg("")}
          dismissible
        >
          {errorMsg}
        </Alert>
        <Card>
          <Card.Header>
            <ReturnDetailsHeader
              ReturnOrderDetails={returnOrderDetails}
              ReturnStateTranslated={LookupReturnState(
                returnOrderDetails.RetOrderStateId
              )}
              IsRM={true}
              OnResendToAc={ResendReturnOrderToAc}
            />
          </Card.Header>
          <Card.Body>
            <Row>
              <Col className="font-size18 bold">Product information</Col>
            </Row>
            <Row>
              <ProductInformation
                ReturnOrderDetails={returnOrderDetails}
                AllOrderItems={originalOrderItems}
                ReturnPolicies={returnPolicies}
                OnHideCalculatorButtonClick={() => {
                  setHideCalculator((current) => !current);
                }}
                OnUpdateReturnOrderItems={UpdateReturnOrderIssues}
              />
              <Col>
                <Card>
                  <Card.Header className="font-size14 bold nowrap">
                    Select activity
                  </Card.Header>
                  <Card.Body
                  hidden={
                    activities.length === 0 &&
                    returnOrderDetails.RetOrderStateId === 6
                  }
                  style={{padding: "6px"}}>
                    {activities.map((activity, index) => {
                      return (
                        <Button
                          key={index}
                          size="sm"
                          variant="secondary"
                          className={
                            "activity-btn-" +
                            activity.Id +
                            " activityButton nowrap"
                          }
                          onClick={() => ActivityClick(activity.Id)}
                        >
                          {activity.Text}
                        </Button>
                      );
                    })}
                    <div hidden={returnOrderDetails.RetOrderStateId === 4 || returnOrderDetails.RetOrderStateId === 6}>
                      <a href={returnOrderDetails.ImpersonateUserLoginUrl} target="_blank">
                         <Button
                           size="sm"
                           variant="secondary"
                           className=" activityButton nowrap"
                         >
                           Replacement order <FontAwesomeIcon icon={faExternalLinkAlt} />
                         </Button>
                       </a>
                    </div>
                    
                    <div hidden={returnOrderDetails.RetOrderStateId !== 5}>
                      <br />{" "}
                      <input
                        type="checkbox"
                        onClick={() => setManualTa((prevValue) => !prevValue)}
                      />{" "}
                      Do you want to create manual TA?
                    </div>
                  </Card.Body>
                  <Card.Body style={{padding: "6px"}}>
                    <Button
                      disabled={returnOrderDetails.RetOrderStateId === 10 || returnOrderDetails.RetOrderStateId === 3}
                      size="sm"
                      variant="info"
                      className="activityButton nowrap"
                      onClick={UndoReturnHistoryByOrderTag}
                    >
                      Undo Activity <FontAwesomeIcon icon={faUndo} />
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <br />
        <SkruvatCollapsableCard
          Hidden={!returnOrderDetails.IsCalculationEnabled}
          Title="Calculator"
          ExternalControl={hideCalculator}
        >
          <Calculator
            ReturnOrderId={returnOrderDetails.RetOrderId}
            OnTotalOrderAmountChanged={HandleCalculatorChangedOrderTotalAmount}
          />
        </SkruvatCollapsableCard>
        <br />
        <SkruvatCollapsableCard Title="Return Information" ShowByDefault={true}>
          <ReturnInformationComponent
            ApiClient={ReturnManagerApiClient}
            ReturnOrderDetails={returnOrderDetails}
            OnReturnOrderUpdated={(updatedReturnOrder) => {
              setReturnOrderDetails(updatedReturnOrder);
            }}
            OnError={setErrorMsg}
          />
        </SkruvatCollapsableCard>
        <br />
        <SkruvatCollapsableCard Title="Customer Information">
          <CustomerInformation CustomerDetails={customerDetails} />
        </SkruvatCollapsableCard>
        <br />
        <SkruvatCollapsableCard Title={`Comments - ${numberOfComments}`}>
          <Comments
            ReturnOrderId={returnOrderDetails.RetOrderId}
            SetNumberOfComments={setNumberOfComments}
          />
        </SkruvatCollapsableCard>
        <br />
        <SkruvatCollapsableCard Title="Logs">
          <ActivityAndEmailLogs
            ActivityLogs={activityAndEmailLogs?.activityLogs}
            EmailLogs={activityAndEmailLogs?.emailLogs}
            Translate={t}
          />
        </SkruvatCollapsableCard>
      </Container>
      <Modal
        show={modalParameters.Show}
        onHide={() => {
          return;
        }}
      >
        <Modal.Header className="headerBar text-white">
          <Modal.Title>{modalParameters.Title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <modalParameters.Body Parameters={modalParameters} />
        </Modal.Body>
        <Modal.Footer>
          <modalParameters.Footer Parameters={modalParameters} />
        </Modal.Footer>
      </Modal>
    </>
  );

  function ResendReturnOrderToAc() {
    loader.updateShowLoader(true);
    setErrorMsg("");

    ReturnManagerApiClient.ResendToAc(ReturnOrderId)
      .then(
        (res) => {
          console.log(res);
        },
        (err) => {
          setErrorMsg(err);
        }
      )
      .finally(() => loader.updateShowLoader(false));
  }

  function UpdateReturnOrderIssues(newReturnIssues) {
    returnOrderDetails.ReturnIssues = newReturnIssues;
    ReturnManagerApiClient.UpdateReturnOrderIssues(returnOrderDetails).then(
      (res) => {
        history.go(0);
      },
      (err) => {
        setErrorMsg(err);
      }
    );
  }

  function HandleCalculatorChangedOrderTotalAmount(TotalAmount) {
    setReturnOrderDetails((prevValue) => {
      return { ...prevValue, OrderTotalAmount: TotalAmount };
    });
  }

  function ActivityClick(id) {
    setErrorMsg("");

    if (id === 4) {
      // Decline = 4
      ShowDeclineModal();
    } else if (id === 7) {
      // Cancel = 7
      ShowCancelModal();
    } else if (returnOrderDetails.RetOrderStateId === 5 && manualTa) {
      // Processing = 5
      if (id === 1) {
        // Open = 1
        ShowManualTABoxModal(1);
      } else if (id === 12) {
        //AdvanceReplacement = 12
        ShowManualTABoxModal(12);
      } else {
        ChangeStatus(id);
      }
    } else {
      ChangeStatus(id);
    }
  }

  function ShowDeclineModal() {
    const modalBody = ({ Parameters }) => {
      return (
        <>
          {t("ReturnManager.RMDelciningWindowInfoText")}
          <br />
          <textarea
            className="form-control"
            maxLength="1000"
            rows="5"
            onChange={(e) => {
              const target = e.target; // Need to persist the event since setState is async.
              setModalParameters((prev) => {
                return { ...prev, DeclineDescription: target.value };
              });
            }}
            value={Parameters.DeclineDescription}
          />
          <br />
          <ImageUploadComponent
            ImageList={Parameters.Images}
            UpdateImageList={(newImages) =>
              setModalParameters((prev) => {
                return { ...prev, Images: [...newImages] };
              })
            }
            SaveFileApiEndpoint={CustomerApiClient.SaveDeclineReport}
            RemoveFileApiEndpoint={CustomerApiClient.RemoveFile}
          />
        </>
      );
    };

    const modalFooter = ({ Parameters }) => {
      return (
        <>
          <Button
            size="sm"
            variant="success"
            onClick={() => {
              ReturnManagerApiClient.SetActivityApproveDeclineText(
                returnOrderDetails.RetOrderId,
                4,
                Parameters.DeclineDescription,
                Parameters.Images
              ).then(
                (res) => {
                  if (res.success) history.push("/RM/");
                },
                (error) => {
                  setErrorMsg(error);
                }
              );
            }}
          >
            Submit
          </Button>
          <Button
            size="sm"
            variant="outline-secondary"
            onClick={() => {
              setModalParameters((prevParam) => {
                return { ...prevParam, Show: false };
              });
            }}
          >
            {t("General.Close")}
          </Button>
        </>
      );
    };

    setModalParameters((prev) => {
      return {
        ...prev,
        Show: true,
        Title: t("ReturnManager.RMDelciningWindowTitle"),
        Body: modalBody,
        Footer: modalFooter,
      };
    });
  }

  function ShowCancelModal() {
    const modalBody = () => {
      return (
        <>
          This is not the normal procedure for return orders. If you continue
          the customer will not get any more e-mails regarding this case.
          Continue?
        </>
      );
    };

    const modalFooter = () => {
      return (
        <>
          <Button
            size="sm"
            variant="success"
            onClick={() => {
              ReturnManagerApiClient.SetActivityCancelCase(
                returnOrderDetails.RetOrderId
              ).then(
                (res) => {
                  history.push("/RM/");
                },
                (error) => {
                  setErrorMsg(error);
                }
              );
            }}
          >
            {t("General.Yes")}
          </Button>
          <Button
            size="sm"
            variant="outline-secondary"
            onClick={() => {
              setModalParameters((prevParam) => {
                return { ...prevParam, Show: false };
              });
            }}
          >
            {t("General.No")}
          </Button>
        </>
      );
    };

    setModalParameters((prev) => {
      return {
        ...prev,
        Show: true,
        Title: "Warning!",
        Body: modalBody,
        Footer: modalFooter,
      };
    });
  }

  function ShowManualTABoxModal(ActivityId) {
    const modalBody = ({ Parameters }) => {
      return (
        <>
          Please enter shipment details
          <Form.Control
            type="text"
            size="sm"
            placeholder="Enter tracking details"
            onChange={(e) => {
              const target = e.target; // Need to persist the event since setState is async.
              setModalParameters((prev) => {
                return { ...prev, ManualTrackingNo: target.value };
              });
            }}
            value={Parameters.ManualTrackingNo}
          />
        </>
      );
    };

    const modalFooter = ({ Parameters }) => {
      return (
        <>
          <Button
            className={"activity-btn-" + ActivityId + " activityButton nowrap"}
            size="sm"
            variant="secondary"
            disabled={Parameters.ManualTrackingNo?.length === 0}
            onClick={() => {
              ReturnManagerApiClient.ChangeStatus(
                returnOrderDetails.RetOrderId,
                ActivityId,
                manualTa,
                Parameters.ManualTrackingNo
              ).then(
                (res) => {
                  if (res.success) history.push("/RM/");
                },
                (error) => {
                  setErrorMsg(error);
                }
              );
            }}
          >
            {ActivityId === 1 ? "Open" : "Advance replacement"}
          </Button>
          <Button
            size="sm"
            variant="outline-secondary"
            onClick={() => {
              setModalParameters((prevParam) => {
                return { ...prevParam, Show: false };
              });
            }}
          >
            {t("General.No")}
          </Button>
        </>
      );
    };

    setModalParameters((prev) => {
      return {
        ...prev,
        Show: true,
        Title: "Shipping Information",
        Body: modalBody,
        Footer: modalFooter,
      };
    });
  }

  function ChangeStatus(Id) {
    ReturnManagerApiClient.ChangeStatus(
      returnOrderDetails.RetOrderId,
      Id,
      manualTa,
      ""
    ).then(
      (res) => {
        if (res.success) history.push("/RM/");
      },
      (error) => {
        setErrorMsg(error);
      }
    );
  }

  function UndoReturnHistoryByOrderTag() {
    const modalBody = () => {
      return (
        <>
          You are performing a non standard action and the customer might have
          got an email about previous actions. If you proceed with the undo
          action you shall contact the customer to make sure he/she will get the
          correct information about what's going on. Continue?
        </>
      );
    };

    const modalFooter = () => {
      return (
        <>
          <Button
            size="sm"
            variant="success"
            onClick={() => {
              ReturnManagerApiClient.UndoReturnHistoryByOrderTag(
                returnOrderDetails.RetOrderId
              ).then(
                (res) => {
                  if (res.success) history.push("/RM/");
                },
                (error) => {
                  setErrorMsg(error);
                  setModalParameters((prevParam) => {
                    return { ...prevParam, Show: false };
                  });
                }
              );
            }}
          >
            {t("General.Yes")}
          </Button>
          <Button
            size="sm"
            variant="outline-secondary"
            onClick={() => {
              setModalParameters((prevParam) => {
                return { ...prevParam, Show: false };
              });
            }}
          >
            {t("General.No")}
          </Button>
        </>
      );
    };

    setModalParameters((prev) => {
      return {
        ...prev,
        Show: true,
        Title: "Warning!",
        Body: modalBody,
        Footer: modalFooter,
      };
    });
  }

  function LookupReturnState(id) {
    var returnOrderState = returnStatusList.filter((e) => e.Id === id);
    if (returnOrderState.length === 0) return null;

    return t(returnOrderState[0].Text);
  }
}
