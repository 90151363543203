import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function ReturnManagerFooter({ noflow }) {
  const { t } = useTranslation("common");

  return (
    <Container
      fluid
      className={"footer" + (noflow ? "-noflow" : "") + " paddingBefore10"}
    >
      <Row>
        <Col>
          <Container>
            <Row>
              <Col xs="7" md="8" lg="9">
                <a
                  href="https://www.skruvat.com/Contact.aspx"
                  className="link-white"
                >
                  {t("FooterPublic.FooterPublicContact")}
                </a>{" "}
                |{" "}
                <a
                  href="https://www.skruvat.com/Faq.aspx"
                  className="link-white"
                >
                  {t("FooterPublic.FooterPublicLeft2")}
                </a>
              </Col>
              <Col xs="5" md="4" lg="3" className="justify-content-md-center">
                {t("FooterPublic.FooterPublicRight")}
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}

export default ReturnManagerFooter;
