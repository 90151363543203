import React from "react";
import { Button, Table, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import SkruvatTimelimits from "../SkruvatHelperFunctions";
import useWindowSize from "../CustomHooks/useWindowSize";

export default function ReturnOrClaim({ Order, HandleNextStep }) {
  const { t } = useTranslation("common");
  const { isMobile } = useWindowSize();

  if (Order.OrderItems === undefined || Order.OrderItems.length === 0)
    return null;

  return (
    <React.Fragment>
      <Row>
        <Col className="alignCenter">
          <p className="font-size22">{t("Step2.Step2ReturnHeader")}</p>
          <p className="font-size14">{t("Step2.Step2Header_BelowText")}</p>
          <p className="font-size14">{t("Step2.Step2Header_BelowText1")}</p>
        </Col>
      </Row>
      <Row>
        <Col className="alignCenter">
          <Button
            variant="primary"
            onClick={ReturnClick}
            disabled={SkruvatTimelimits.IsTooLateForWithdraw(
              Order.OrderItems[0].OrderDate
            )}
          >
            {t("Step2.Step2HeaderReturn")}
          </Button>
          <span> </span>
          <Button
            variant="primary"
            onClick={ClaimClick}
            disabled={
              SkruvatTimelimits.IsTooLateForWrongProduct(
                Order.OrderItems[0].OrderDate
              ) &&
              SkruvatTimelimits.IsTooLateForDamagedProduct(
                Order.OrderItems[0].OrderDate
              )
            }
          >
            {t("Step2.Step2HeaderComplaint")}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col className="return-description">
          <div className="font-size22">{t("Step2.Step2HeaderReturn")}</div>
          <div className="font-size14">
            {t("Step2.Step2Header_ReturnButtonInformation")}
          </div>
          <div className="font-size14">
            {t("Step2.Step2Header_ReturnButtonInformation2")}
            <a
              href={t("Step2.Step2Header_ReturnButtonInformation4")}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("Step2.Step2Header_ReturnButtonInformation3")}
            </a>
          </div>
          <div className="font-size22">{t("Step2.Step2HeaderComplaint")}</div>
          <div className="font-size14">
            {t("Step2.Step2Header_ComplaintButtonInformation1")}
          </div>
          <div className="font-size14">
            {t("Step2.Step2Header_ComplaintButtonInformation2")}
            <a
              href={t("Step2.Step2Header_ReturnButtonInformation4")}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("Step2.Step2Header_ReturnButtonInformation3")}
            </a>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="font-size22">
            {t("Step2.OrdersInfoText")} - {Order.OrderItems[0].OrderId}
          </p>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col>
          {isMobile ? (
            <ReturnOrderItemsComponentMobile OrderItems={Order.OrderItems} />
          ) : (
            <ReturnOrderItemsComponent OrderItems={Order.OrderItems} />
          )}
        </Col>
      </Row>
    </React.Fragment>
  );

  function ReturnOrderItemsComponent({ OrderItems }) {
    return (
      <Table>
        <thead>
          <tr>
            <th>{t("OrderItems.Product")}</th>
            <th>{t("Product.ProductId")}</th>
            <th>{t("OrderItems.QuantityEdit")}</th>
          </tr>
        </thead>
        <tbody>
          {OrderItems.map((order, key) => {
            return (
              <ReturnOrderItem
                key={key}
                id={key}
                item={order}
              ></ReturnOrderItem>
            );
          })}
        </tbody>
      </Table>
    );
  }

  function ReturnOrderItemsComponentMobile({ OrderItems }) {
    return OrderItems.map((order, key) => {
      return (
        <Table key={key}>
          <tbody>
            <ReturnOrderItemMobile id={key} item={order} />
          </tbody>
        </Table>
      );
    });
  }

  function ReturnOrderItem({ id, item }) {
    return (
      <tr>
        <td>{item.Product.Name}</td>
        <td>{item.Product.ProductId}</td>
        <td>{item.Quantity}</td>
      </tr>
    );
  }

  function ReturnOrderItemMobile({ id, item }) {
    return (
      <tr>
        <td>
          <Row>
            <Col>
              <span className="bold">{t("OrderItems.Product")}</span>
              <br />
              {item.Product.Name}
            </Col>
            <Col>
              <span className="bold">{t("OrderItems.QuantityEdit")}</span>
              <br />
              {item.Quantity}
            </Col>
          </Row>
          <Row className="paddingBefore10">
            <Col>
              <span className="bold">{t("Product.ProductId")}</span>
              <br />
              {item.Product.ProductId}
            </Col>
            <Col></Col>
          </Row>
        </td>
      </tr>
    );
  }

  function ReturnClick() {
    HandleNextStep(3);
  }
  function ClaimClick() {
    HandleNextStep(4);
  }
}
