import axios from "axios";
import config from "../config.json";

class CreditManagerApiClient {
  GetAllOrders = (Filter) => {
    return new Promise(function (resolve) {
      fetch(
        config.RETURNWEB_API +
          `/CreditManager/GetCreditMangerReturnOrders?statusid=${Filter.statusId}&orderId=${Filter.orderId}&sortKey=${Filter.sortKey}&sortDirection=${Filter.sortDirection}&startsWith=${Filter.startsWith}`,
        {
          credentials: "include",
        }
      )
        .then((res) => res.json())
        .then((result) => {
          return resolve(result.content);
        })
        .catch(() => {
          console.log("Error fetching order data.");
          return resolve([]);
        });
    });
  };

  GetAllReturnStatus = () => {
    return new Promise(function (resolve) {
      fetch(
        config.RETURNWEB_API +
          "/CreditManager/GetReturnStatusesforCreditManager",
        {
          credentials: "include",
        }
      )
        .then((res) => res.json())
        .then((result) => {
          return resolve(result);
        })
        .catch(() => {
          console.log("Error fetching data.");
          return resolve();
        });
    });
  };

  GetReturnOrderMoreDetail = (ReturnOrderid) => {
    return new Promise(function (resolve) {
      fetch(
        config.RETURNWEB_API +
          `/CreditManager/GetMoreDetailsForCreditManager?id=${ReturnOrderid}`,
        {
          credentials: "include",
        }
      )
        .then((res) => res.json())
        .then((result) => {
          return resolve(result);
        })
        .catch(() => {
          console.log("Error fetching return order data.");
          return resolve([]);
        });
    });
  };

  GetActivitiesforCreditManager = () => {
    return new Promise(function (resolve) {
      fetch(
        config.RETURNWEB_API + `/CreditManager/GetActivitiesforCreditManager`,
        {
          credentials: "include",
        }
      )
        .then((res) => res.json())
        .then((result) => {
          return resolve(result);
        })
        .catch(() => {
          console.log("Error fetching return order data.");
          return resolve([]);
        });
    });
  };

  GetAllReturnStatus = () => {
    return new Promise(function (resolve) {
      fetch(
        config.RETURNWEB_API +
          "/CreditManager/GetReturnStatusesforCreditManager",
        {
          credentials: "include",
        }
      )
        .then((res) => res.json())
        .then((result) => {
          return resolve(result);
        })
        .catch(() => {
          console.log("Error fetching data.");
          return resolve();
        });
    });
  };

  GetReturnReasonIdentities = () => {
    return new Promise(function (resolve) {
      fetch(
        config.RETURNWEB_API + "/CreditManager/GetReturnReasonIdentitiesText",
        {
          credentials: "include",
        }
      )
        .then((res) => res.json())
        .then((result) => {
          return resolve(result);
        })
        .catch(() => {
          console.log("Error fetching data.");
          return resolve();
        });
    });
  };

  GetReturnReasonSpecs = () => {
    return new Promise(function (resolve) {
      fetch(config.RETURNWEB_API + "/CreditManager/GetReturnReasonSpecText", {
        credentials: "include",
      })
        .then((res) => res.json())
        .then((result) => {
          return resolve(result);
        })
        .catch(() => {
          console.log("Error fetching data.");
          return resolve();
        });
    });
  };

  GetReturnPolicies = () => {
    return new Promise(function (resolve) {
      fetch(config.RETURNWEB_API + "/CreditManager/GetReturnPoliciesText", {
        credentials: "include",
      })
        .then((res) => res.json())
        .then((result) => {
          return resolve(result);
        })
        .catch(() => {
          console.log("Error fetching data.");
          return resolve();
        });
    });
  };

  GetReturnRefundType = () => {
    return new Promise(function (resolve) {
      fetch(config.RETURNWEB_API + "/CreditManager/GetReturnReasonRefundText", {
        credentials: "include",
      })
        .then((res) => res.json())
        .then((result) => {
          return resolve(result);
        })
        .catch(() => {
          console.log("Error fetching data.");
          return resolve();
        });
    });
  };

  GetActivityAndEmailLogs = (ReturnOrderid) => {
    return new Promise(function (resolve) {
      fetch(
        config.RETURNWEB_API +
          `/CreditManager/GetRMActivityLogs?returnOrderId=${ReturnOrderid}`,
        {
          credentials: "include",
        }
      )
        .then((res) => res.json())
        .then((result) => {
          return resolve(result);
        })
        .catch(() => {
          console.log("Error fetching data.");
          return resolve();
        });
    });
  };

  GetOrderItems = (OrderId) => {
    return new Promise(function (resolve, reject) {
      fetch(
        config.RETURNWEB_API +
          "/CreditManager/GetOrderItems?orderId=" +
          OrderId,
        { credentials: "include" }
      )
        .then((res) => {
          if (res.status === 200) {
            res.json().then(resolve);
          } else {
            reject("Error fetching customer order items");
          }
        })

        .catch(() => {
          reject("Error fetching customer order items");
        });
    });
  };

  ChangeStatusAndRefundType = (ReturnOrderId, state, refundType) => {
    var data = JSON.stringify({
      returnOrderId: ReturnOrderId,
      state: state,
      refundType: refundType,
    });

    return new Promise(function (resolve, reject) {
      fetch(config.RETURNWEB_API + `/CreditManager/ChangeStatusAndRefundType`, {
        credentials: "include",
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.success) {
            return resolve(result);
          } else {
            reject(result.errors);
          }
        })
        .catch(() => {
          reject("Error changing status");
        });
    });
  };

  AllChangeStatusAndRefundType = (ReturnOrderList, state) => {
    var data = JSON.stringify({
      returnOrderIds: ReturnOrderList,
      state: state,
      refundType: 0,
    });

    return new Promise(function (resolve, reject) {
      fetch(
        config.RETURNWEB_API + `/CreditManager/AllChangeStatusAndRefundType`,
        {
          credentials: "include",
          method: "POST",
          body: data,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((result) => {
          if (result.success) {
            return resolve(result);
          } else {
            reject(result.errors);
          }
        })
        .catch(() => {
          reject("Error changing status");
        });
    });
  };

  SaveFile = (file, folderName, updateProgress) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", "");
    formData.append("dirName", folderName);
    formData.append("IsEdit", true);

    return axios
      .request({
        method: "post",
        url: config.RETURNWEB_API + "/CreditManager/SaveFiles",
        data: formData,
        onUploadProgress: (progress) => {
          updateProgress(Math.round((progress.loaded / progress.total) * 100));
        },
      })

      .then((response) => {
        return {
          Success: response.data.success,
          FileName: response.data.success
            ? response.data.content.imageFileName
            : null,
          FolderName: response.data.success
            ? response.data.content.imagefolderName
            : null,
          FileType: response.data.success
            ? response.data.content.FileType
            : null,
          Url: response.data.success
            ? response.data.content.imageFullPath
            : null,
          Errors: response.data.errors,
        };
      })
      .catch(() => {
        console.log("Error uploading file.");
      });
  };

  RemoveFile = (url) => {
    var fileNameArray = url.split("/");
    var imageName = fileNameArray[fileNameArray.length - 1];
    var dirName = fileNameArray[fileNameArray.length - 2];

    return new Promise(function (resolve) {
      fetch(
        config.RETURNWEB_API +
          "/CreditManager/RemoveImage?foldername=" +
          dirName +
          "&imageName=" +
          imageName,
        { credentials: "include" }
      )
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          console.log("Error removing file.");
          return resolve();
        });
    });
  };

  UpdateReturnOrderEditDetail = (ReturnOrder) => {
    var data = JSON.stringify(ReturnOrder);

    return new Promise(function (resolve, reject) {
      fetch(
        config.RETURNWEB_API + `/CreditManager/UpdateReturnOrderEditDetail`,
        {
          credentials: "include",
          method: "POST",
          body: data,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((result) => {
          if (result.success) {
            return resolve(result);
          } else {
            reject(result.errors);
          }
        })
        .catch(() => {
          reject("Error updating data");
        });
    });
  };

  UpdateReturnOrderIssues = (ReturnOrder) => {
    ReturnOrder.ReturnIssues.forEach((issue) => {
      issue.Quantity = issue.ReturnQuantity;

      if (!issue.RetIssueStateId) issue.RetIssueStateId = 1;
      if (!issue.RetReasonIdentityId) issue.RetReasonIdentityId = 1;
      if (!issue.RetReasonSpecId) issue.RetReasonSpecId = 1;
      if (!issue.ReturnReasonOtherText) issue.ReturnReasonOtherText = "na"; // $scope.TitleDetail;
      if (!issue.ReturnIssueProductName)
        issue.ReturnIssueProductName = issue.PublicProduct.Name;
      if (!issue.ReturnIssueProductId)
        issue.ReturnIssueProductId = issue.PublicProduct.ProductId;
      if (!issue.ReturnIssueProductSku)
        issue.ReturnIssueProductSku = issue.PublicProduct.Sku;
      if (!issue.ReturnIssueProductTireRimSku)
        issue.ReturnIssueProductTireRimSku = issue.PublicProduct.TireSku
          ? issue.PublicProduct.RimSku
            ? issue.PublicProduct.TireSku + " / " + issue.PublicProduct.RimSku
            : issue.PublicProduct.TireSku
          : "";
    });

    var data = JSON.stringify(ReturnOrder);

    return new Promise(function (resolve, reject) {
      fetch(config.RETURNWEB_API + `/CreditManager/UpdateReturnOrderIssues`, {
        credentials: "include",
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.success) {
            return resolve(result);
          } else {
            reject(result.errors);
          }
        })
        .catch(() => {
          reject("Error updating data");
        });
    });
  };
}

export default new CreditManagerApiClient();
