import config from "../config.json";
import axios from "axios";

class CustomerApiClient {
  SaveReturnOrder = (
    order,
    returnReason,
    returnDescription,
    language,
    specificReason = "REFUND_ONLY",
    setValuesString = "na",
    directoryName = ""
  ) => {
    return new Promise(function (resolve) {
      let OrderId = order.OrderId;

      var swift = "";
      switch (language) {
        case "Norway":
          swift = "Norway (nn-NO)";
          break;
        case "Sweden":
          swift = "Sweden (sv-SE)";
          break;
        case "Finland":
          swift = "Finland (fi-FI)";
          break;
        case "Denmark":
          swift = "Denmark (da-DK)";
          break;
        default:
          swift = "";
      }

      var retObj = {
        ReturnIssues: order.OrderItems.map((issue) => {
          return {
            RetOrderId: 0,
            OrderId: OrderId,
            RetIssueStateId: 1,
            OrderItemId: issue.OrderItemId,
            ProductId: [],
            Created: "na",
            LastModified: "na",
            UpdaterId: 0,
            Quantity: issue.ReturnQuantity, // Så här är det i returwebben idag, men ska kanske sättas till issue.Quantity egentligen?
            QuantityIncrementalStep: "",
            ReturnQuantity: issue.ReturnQuantity,
            RetReasonIdentityId: 1,
            RetReasonSpecId: 1,
            ReturnReasonOtherText: "na", // $scope.TitleDetail;
            ReturnIssueProductName: issue.PublicProduct.Name,
            ReturnIssueProductPrice: issue.PublicProduct.Price,
            ReturnIssueProductId: issue.PublicProduct.ProductId,
            ReturnIssueProductSku: issue.PublicProduct.Sku,
            ReturnIssueProductTireRimSku: issue.PublicProduct.TireSku
              ? issue.PublicProduct.RimSku
                ? issue.PublicProduct.TireSku +
                  " / " +
                  issue.PublicProduct.RimSku
                : issue.PublicProduct.TireSku
              : "",
          };
        }),

        RetOrderTag: "0",
        RetOrderStateId: 1,
        Created: "na",
        OwnerId: "0",
        LastModified: "na",
        BIC: "",
        IBAN: "",
        SWIFT: swift,
        OrderId: OrderId,
        ReturnReasonDescription: returnDescription,
        ReturnReasonUserCarNumber: "",
        ReturnReasonUserCarNumberInfo: "",
        CLEARING: "",
        ACCOUNT: "",
        BANKNAME: "",
        TransactionId: "0",
        SetValuesString: setValuesString,
        OrderOwnerNameAndEmail: "na",
        DecliningComment: "na",
        ReturnReasonRefundTypeId: 1,
        OrderDate: order.OrderDate,
      };

      var UserDataDataString = JSON.stringify({
        returnOrder: retObj,
        detailedReason: returnReason,
        specificReason: specificReason,
        dirName: directoryName,
      });

      fetch(config.RETURNWEB_API + "/Customer/SaveReturnOrder", {
        credentials: "include",
        method: "POST",
        body: UserDataDataString,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((result) => {
          return resolve(result);
        })
        .catch(() => {
          console.log("Error saving return order.");
          return resolve();
        });
    });
  };

  GetOrderItems = (orderId) => {
    return new Promise(function (resolve) {
      fetch(
        config.RETURNWEB_API + "/Customer/GetOrderItems?orderId=" + orderId,
        {
          credentials: "include",
        }
      )
        .then((res) => res.json())
        .then((result) => {
          return resolve(result);
        })
        .catch(() => {
          console.log("Error fetching order data.");
          return resolve();
        });
    });
  };

  SaveFile = (file, folderName, updateProgress) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", "");
    formData.append("dirName", folderName);

    return axios
      .request({
        method: "post",
        url: config.RETURNWEB_API + "/Customer/SaveFiles",
        data: formData,
        onUploadProgress: (progress) => {
          updateProgress(Math.round((progress.loaded / progress.total) * 100));
        },
      })

      .then((response) => {
        return {
          Success: response.data.success,
          FileName: response.data.success
            ? response.data.content.imageFileName
            : null,
          FolderName: response.data.success
            ? response.data.content.imagefolderName
            : null,
          FileType: response.data.success
            ? response.data.content.FileType
            : null,
          Url: response.data.success
            ? response.data.content.imageFullPath
            : null,
          Errors: response.data.errors,
        };
      })
      .catch(() => {
        console.log("Error uploading file.");
      });
  };

  SaveDeclineReport = (file, folderName, updateProgress) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", "");
    formData.append("dirName", folderName);

    return axios
      .request({
        method: "post",
        url: config.RETURNWEB_API + "/CustomerService/SaveDeclineReport",
        data: formData,
        onUploadProgress: (progress) => {
          updateProgress(Math.round((progress.loaded / progress.total) * 100));
        },
      })

      .then((response) => {
        return {
          Success: response.data.success,
          FileName: response.data.success
            ? response.data.content.imageFileName
            : null,
          FolderName: response.data.success
            ? response.data.content.imagefolderName
            : null,
          FileType: response.data.success
            ? response.data.content.FileType
            : null,
          Url: response.data.success
            ? response.data.content.imageFullPath
            : null,
          Errors: response.data.errors,
        };
      })
      .catch(() => {
        console.log("Error uploading file.");
      });
  };

  RemoveFile = (url) => {
    var fileNameArray = url.split("/");
    var imageName = fileNameArray[fileNameArray.length - 1];
    var dirName = fileNameArray[fileNameArray.length - 2];

    return new Promise(function (resolve) {
      fetch(
        config.RETURNWEB_API +
          "/Customer/RemoveImage?foldername=" +
          dirName +
          "&imageName=" +
          imageName,
        { credentials: "include" }
      )
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          console.log("Error removing file.");
          return resolve();
        });
    });
  };
}

export default new CustomerApiClient();
