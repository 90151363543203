import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Table, Button, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import CreditManagerApiClient from "../ApiClients/CreditManagerApiClient";
import {
  SkruvatCheckbox,
  SkruvatPagination,
} from "../Components/SkruvatComponents";

export default function ListReturnOrders({
  ReturnStatusList,
  LoaderContext,
  Filter,
  UpdateFilter,
  ParentReference,
}) {
  const { t } = useTranslation("common");
  const [orderList, setOrderList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [errorMsg, setErrorMsg] = useState("");
  const [sortDirection, setSortDirection] = useState(Filter.sortDirection);
  const history = useHistory();

  useEffect(() => {
    GetAllOrders();
    setSortDirection(Filter.sortDirection)
  }, [Filter]);

  ParentReference.current = {
    RefundSelected: () => {
      ChangeStatusAndRefundTypeForSelectedOrders(3);
    },
    RevisionSelected: () => {
      ChangeStatusAndRefundTypeForSelectedOrders(9);
    },
  };

  return (
    <>
      <Alert
        hidden={errorMsg.length === 0}
        variant="danger"
        onClose={() => setErrorMsg("")}
        dismissible
      >
        {errorMsg}
      </Alert>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>
              {/* This is comment out to not be able to select all rows. See SB-6464 in Jira */}
              {/* <SkruvatCheckbox
                onChange={(e) => {
                  const target = e.target;
                  orderList.forEach((element) => {
                    element.Selected = target.value;
                  });
                  setOrderList([...orderList]);
                }}
                checked={orderList.every((order) => order.Selected)}
              /> */}
            </th>
            <th className="pointer" onClick={CreateDateHeaderClick}>{t("ReturnOrders.RO_Created")} {GetSortArrow()}</th>
            <th>{t("ReturnManager.OrderId")}</th>
            <th>{t("ReturnOrders.RetOrderTag")}</th>
            <th>{t("ReturnManager.RMOwner")}</th>
            <th>{t("ReturnManager.Country")}</th>
            <th>{t("Order.TotalCharges")}</th>
            <th>{t("ReturnOrders.ReturnOrderStatus")}</th>
            <th>{t("ReturnManager.RMReturnReasonAction")}</th>
          </tr>
        </thead>
        <tbody>
          <ReturnOrderList />
          <tr>
            <td colSpan="8">
              <SkruvatPagination
                onPageChanged={OnPageChanged}
                totalPages={totalPages}
              />
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );

  function OnPageChanged(pageNo) {
    setCurrentPage(pageNo - 1);
  }

  function ReturnOrderList() {
    const partOrderList = orderList.skip(currentPage * 15).take(15);
    if (partOrderList.length === 0 && !LoaderContext.showLoader)
      return (
        <tr>
          <td colSpan="9" className="font-size14 alignCenter bold">
            {t("General.NoRecordsFound")}
          </td>
        </tr>
      );

    return partOrderList.map((order, index) => {
      return <ReturnOrder key={index} order={order} />;
    });
  }

  function ReturnOrder({ order }) {
    return (
      <tr>
        <td>
          <SkruvatCheckbox
            name={order.ReturnOrder.OrderId}
            onChange={(e) => {
              CheckboxClick(e);
            }}
            checked={order.Selected}
          />
        </td>
        <td>{moment(order.ReturnOrder.Created).format("YYYY-MM-DD")}</td>
        <td>
          <a href={order.OrderLink} target="_blank" rel="noopener noreferrer">
            {order.ReturnOrder.OrderId}
          </a>
        </td>
        <td>{order.ReturnOrder.RetOrderTag}</td>
        <td>{order.ReturnOrder.OrderOwnerNameAndEmail.split("|")[0]}</td>
        <td>{order.ReturnOrder.SWIFT}</td>
        <td>{order.CalculatorReturnAmount}</td>
        <td>{LookupReturnState(order.ReturnOrder.RetOrderStateId)}</td>
        <td>
          <Button
            size="sm"
            onClick={() =>
              history.push("/CM/ReturnDetails/" + order.ReturnOrder.RetOrderId)
            }
          >
            View
          </Button>
        </td>
      </tr>
    );
  }

  function GetSortArrow(){
    if(sortDirection === true){
      return <FontAwesomeIcon icon={faSortUp} />;
    } else {
      return <FontAwesomeIcon icon={faSortDown} />;
    }
  }

  function CreateDateHeaderClick(){
    setSortDirection(!sortDirection)
    UpdateFilter({ ...Filter, sortDirection: !sortDirection });
  }

  function CheckboxClick(event) {
    var findObj = orderList.findIndex(
      (o) => o.ReturnOrder.OrderId === event.target.name
    );

    orderList[findObj].Selected = !orderList[findObj].Selected;

    setOrderList([...orderList]);
  }

  function LookupReturnState(id) {
    var returnOrderState = ReturnStatusList.filter((e) => e.Id === id);
    if (returnOrderState.length === 0) return null;

    return t(returnOrderState[0].Text);
  }

  function ChangeStatusAndRefundTypeForSelectedOrders(state) {
    setErrorMsg("");
    CreditManagerApiClient.AllChangeStatusAndRefundType(
      orderList
        .filter((order) => order.Selected)
        .map((o) => {
          return o.ReturnOrder.RetOrderId;
        }),
      state
    ).then(
      (res) => {
        if (res.success) GetAllOrders();
      },
      (error) => {
        setErrorMsg("Errors changing status, " + error.join(", "));
      }
    );
  }

  function GetAllOrders() {
    setErrorMsg("");
    async function GetAllOrders() {
      return await CreditManagerApiClient.GetAllOrders(Filter);
    }

    LoaderContext.updateShowLoader(true);

    GetAllOrders().then((result) => {
      setOrderList(result);
      setTotalPages(Math.ceil(result.length / 15));
      LoaderContext.updateShowLoader(false);
    });
  }
}
