import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Popover,
  OverlayTrigger,
  Modal,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopy,
  faLink,
  faCalculator,
  faPencilAlt,
  faExclamationCircle,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { CopyTextToClipboard } from "../SkruvatHelperFunctions";
import { SkruvatCheckbox } from "../Components/SkruvatComponents";

export function ReturnDetailsHeader({
  ReturnOrderDetails,
  ReturnStateTranslated,
  IsRM,
  OnResendToAc,
}) {
  const { t } = useTranslation("common");
  const history = useHistory();

  return (
    <Row className="nowrap">
      <Col xs="2">
        <Button size="sm" onClick={() => history.push(IsRM ? "/RM/" : "/CM/")}>
          &lt; {t("General.BackPage")}
        </Button>
      </Col>
      <Col xs="4" className="font-size18">
        Order No:{" "}
        <Button
          size="sm"
          variant="info"
          onClick={() => CopyTextToClipboard(ReturnOrderDetails.OrderId)}
        >
          {ReturnOrderDetails.OrderId} <FontAwesomeIcon icon={faCopy} />
        </Button>{" "}
        <Button
          size="sm"
          variant="info"
          onClick={() => (window.open(ReturnOrderDetails.OrderLink, '_blank'))}
        >
          AbleCommerce <FontAwesomeIcon icon={faLink} />
        </Button>
      </Col>
      <Col xs="3" className="font-size18">
        Return No:{" "}
        <Button
          size="sm"
          variant="info"
          onClick={() => CopyTextToClipboard(ReturnOrderDetails.RetOrderTag)}
        >
          {ReturnOrderDetails.RetOrderTag} <FontAwesomeIcon icon={faCopy} />
        </Button>{" "}
        <ResendToAcComponent OnResendToAc={OnResendToAc} />
      </Col>
      <Col xs="3" className="font-size18">
        Return status: <span className="bold">{ReturnStateTranslated}</span>
      </Col>
    </Row>
  );

  function ResendToAcComponent({ OnResendToAc }) {
    var [modalShow, setModalShow] = useState(false);

    if (ReturnOrderDetails.RetOrderStateId !== 6) return null;

    return (
      <>
        <Button
          title="Resend ReturnOrder to AC"
          size="sm"
          variant="info"
          onClick={() => setModalShow(true)}
        >
          AC <FontAwesomeIcon icon={faUpload} />
        </Button>

        <Modal show={modalShow} onHide={() => setModalShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Resend ReturnOrder to AC</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            This will trigger a resend of the ReturnOrder to AC.
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => {
                OnResendToAc();
                setModalShow(false);
              }}
            >
              Continue
            </Button>
            <Button variant="secondary" onClick={() => setModalShow(false)}>
              {t("General.Close")}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export function CustomerInformation({ CustomerDetails }) {
  return (
    <Row>
      <Col sm="3">
        <span className="font-size14 bold">Name</span>
        <p className="font-size14">
          {CustomerDetails?.FullName ? CustomerDetails.FullName : "-"}
        </p>
      </Col>
      <Col sm="3">
        <span className="font-size14 bold">Email</span>
        <p className="font-size14">
          {CustomerDetails?.Email ? CustomerDetails.Email : "-"}
        </p>
      </Col>
      <Col sm="3">
        <span className="font-size14 bold">Phone number</span>'
        <p className="font-size14">
          {CustomerDetails?.UserPrimaryAddressInfo?.Mobile
            ? CustomerDetails.UserPrimaryAddressInfo.Mobile
            : "-"}
        </p>
      </Col>
      <Col sm="3">
        <span className="font-size14 bold">Country</span>
        <p className="font-size14">
          {CustomerDetails?.UserPrimaryAddressInfo?.Country
            ? CustomerDetails.UserPrimaryAddressInfo.Country
            : "-"}
        </p>
      </Col>
    </Row>
  );
}

export function ProductInformation({
  ReturnOrderDetails,
  AllOrderItems,
  ReturnPolicies,
  OnHideCalculatorButtonClick,
  OnUpdateReturnOrderItems,
}) {
  const { t } = useTranslation("common");
  const [editOrderItems, setEditOrderItems] = useState([]);

  return (
    <Col xs="10">
      <table>
        <thead>
          <tr className="font-size14">
            <th hidden={editOrderItems.length === 0}></th>
            <th>{t("Product.ProductId")}</th>
            <th>SKU</th>
            <th>{t("Product.ProductName")}</th>
            <th>Customer</th>
            <th>Shipment supplier</th>
            <th>Quantity</th>
            <th>Price</th>
            <th>Return policy</th>
          </tr>
        </thead>
        <tbody className="font-size14">
          <ReturnIssues
            hidden={editOrderItems.length > 0}
            ReturnOrderMoreDetail={ReturnOrderDetails}
          />
          <EditOrderItems
            hidden={editOrderItems.length === 0}
            OrderItems={editOrderItems}
            CurrentReturnIssues={ReturnOrderDetails.ReturnIssues}
            Currency={ReturnOrderDetails.Currency}
            OrderOwnerNameAndEmail={ReturnOrderDetails.OrderOwnerNameAndEmail}
          />
        </tbody>
        <tfoot>
          <tr
            hidden={!ReturnOrderDetails.IsCalculationEnabled}
            className="font-size14"
          >
            <td colSpan="6"></td>
            <td colSpan="3" className="bold">
              <Button
                size="sm"
                variant="outline-primary"
                onClick={OnHideCalculatorButtonClick}
              >
                <FontAwesomeIcon icon={faCalculator} />
              </Button>
              <span> Total </span>
              <span>
                {editOrderItems.length === 0
                  ? ReturnOrderDetails.OrderTotalAmount
                  : "-"}
                {ReturnOrderDetails.Currency === "SEK"
                  ? " kr"
                  : " " + ReturnOrderDetails.Currency}
              </span>
            </td>
          </tr>
        </tfoot>
      </table>
      <Button
        hidden={
          !ReturnOrderDetails.IsCalculationEnabled || editOrderItems.length > 0
        }
        size="sm"
        variant="outline-primary"
        onClick={BeginEdit}
      >
        <FontAwesomeIcon icon={faPencilAlt} />
      </Button>
      <Button
        hidden={editOrderItems.length === 0}
        disabled={
          !editOrderItems.some((eoi) => eoi.selected) ||
          editOrderItems.some((eoi) => eoi.selected && eoi.RetPolicyId < 1)
        }
        size="sm"
        variant="success"
        onClick={() =>
          OnUpdateReturnOrderItems(
            editOrderItems.filter((ri) => ri.ReturnQuantity > 0)
          )
        }
      >
        Save
      </Button>{" "}
      <Button
        hidden={editOrderItems.length === 0}
        size="sm"
        variant="success"
        onClick={CancelEdit}
      >
        Cancel
      </Button>
    </Col>
  );

  function BeginEdit() {
    let tempAllOrderItems = JSON.parse(JSON.stringify(AllOrderItems)); // Deep copy

    setEditOrderItems(
      tempAllOrderItems.map((orderItem) => {
        let currentReturnItem = ReturnOrderDetails.ReturnIssues.find(
          (ri) => ri.OrderItemId === orderItem.OrderItemId
        );
        orderItem.selected = currentReturnItem !== undefined;
        orderItem.ReturnQuantity = currentReturnItem?.Quantity ?? 0;
        orderItem.Quantity += currentReturnItem?.Quantity ?? 0;
        orderItem.RetPolicyId = currentReturnItem?.RetPolicyId ?? 0;
        return orderItem;
      })
    );
  }

  function CancelEdit() {
    setEditOrderItems([]);
  }

  function ReturnIssues({ ReturnOrderMoreDetail, hidden }) {
    if (!ReturnOrderMoreDetail || !ReturnOrderMoreDetail.ReturnIssues || hidden)
      return null;

    let listOfRows = ReturnOrderMoreDetail.ReturnIssues.map((ri, index) => {
      return (
        <ReturnIssue
          key={index}
          Issue={ri}
          OrderOwnerNameAndEmail={ReturnOrderMoreDetail.OrderOwnerNameAndEmail}
          Currency={ReturnOrderMoreDetail.Currency}
        />
      );
    });

    return listOfRows;
  }

  function ReturnIssue({ Issue, OrderOwnerNameAndEmail, Currency }) {
    return (
      <tr>
        <td>
          <a
            href={Issue.ReturnIssueProductURL}
            target="_blank"
            rel="noopener noreferrer"
          >
            {Issue.ReturnIssueProductId}
          </a>
        </td>
        <td>{Issue.ReturnIssueProductSku}</td>
        <td>{Issue.ReturnIssueProductName}</td>
        <td>{OrderOwnerNameAndEmail.split("|")[0]}</td>
        <td>
          <div hidden={!Issue.ShipmentUrl}>
            <a
              href={Issue.ShipmentUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {Issue.Name}
            </a>
          </div>
          <div hidden={Issue.ShipmentUrl}>{Issue.Name}</div>
        </td>
        <td>{Issue.Quantity}</td>
        <td>
          {Issue.ReturnIssueProductPrice}
          {Currency === "SEK" ? " kr" : " " + Currency}
        </td>
        <td>{TranslateReturnPolicyById(Issue.RetPolicyId)}</td>
      </tr>
    );
  }

  function TranslateReturnPolicyById(id) {
    var policy = ReturnPolicies.find((policy) => policy.Id === id);
    return policy ? t(policy.Text) : null;
  }

  function EditOrderItems({
    OrderItems,
    Currency,
    OrderOwnerNameAndEmail,
    hidden,
  }) {
    if (!OrderItems || hidden) return null;

    return (
      <>
        {OrderItems.map((orderItem, index) => {
          return (
            <EditOrderItem
              key={index}
              Item={orderItem}
              OrderOwnerNameAndEmail={OrderOwnerNameAndEmail}
              Currency={Currency}
            />
          );
        })}
      </>
    );
  }

  function EditOrderItem({ Item, OrderOwnerNameAndEmail }) {
    const options = [];
    for (let index = 0; index < Item.Quantity + 1; index++) {
      options.push(<option key={index}>{index}</option>);
    }

    var itemAlreadyFullyReturned = Item.Quantity + Item.ReturnQuantity <= 0;

    return (
      <tr className={itemAlreadyFullyReturned ? "table-row-disable" : ""}>
        <td hidden={itemAlreadyFullyReturned}>
          <SkruvatCheckbox
            checked={Item.selected}
            name={Item.OrderItemId}
            onChange={CheckboxClick}
          />
        </td>
        <td hidden={!itemAlreadyFullyReturned}>
          <OverlayTrigger
            placement="auto"
            delay={{ hide: 400 }}
            overlay={
              <Popover>
                <Popover.Content>
                  This order item is already fully returned
                </Popover.Content>
              </Popover>
            }
          >
            <FontAwesomeIcon icon={faExclamationCircle} />
          </OverlayTrigger>
        </td>
        <td>{Item.ProductId}</td>
        <td>{Item.Product.Sku}</td>
        <td>{Item.Product.Name}</td>
        <td>{OrderOwnerNameAndEmail.split("|")[0]}</td>
        <td>
          <div hidden={!Item.ShipmentUrl}>
            <a
              href={Item.ShipmentUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {Item.Name}
            </a>
          </div>
          <div hidden={Item.ShipmentUrl}>{Item.Name}</div>
        </td>
        <td>
          <select
            id={Item.OrderItemId}
            value={Item.ReturnQuantity}
            onChange={ReturnQuantityChange}
          >
            {options}
          </select>
        </td>
        <td></td>
        <td>
          <select
            disabled={!Item.selected}
            id={Item.OrderItemId}
            value={Item.RetPolicyId}
            onChange={ReturnPolicyChange}
          >
            <option value="0">-</option>
            {ReturnPolicies.map((policy, index) => {
              if (policy.Id > 2 && ReturnOrderDetails.RetOrderStateId !== 2)
                return null;
              return (
                <option key={index} value={policy.Id}>
                  {t(policy.Text)}
                </option>
              );
            })}
          </select>
        </td>
      </tr>
    );
  }

  function CheckboxClick(event) {
    var findObj = editOrderItems.findIndex(
      (o) => o.OrderItemId === event.target.name
    );

    if (editOrderItems[findObj].Quantity <= 0) return;

    editOrderItems[findObj].selected = !editOrderItems[findObj].selected;
    editOrderItems[findObj].ReturnQuantity = editOrderItems[findObj].selected
      ? editOrderItems[findObj].Quantity
      : 0;

    setEditOrderItems([...editOrderItems]);
  }

  function ReturnQuantityChange(event) {
    var findObj = editOrderItems.findIndex(
      (o) => o.OrderItemId === parseInt(event.target.id, 10)
    );

    editOrderItems[findObj].ReturnQuantity = parseInt(event.target.value, 10);
    editOrderItems[findObj].selected = parseInt(event.target.value, 10) > 0;

    setEditOrderItems([...editOrderItems]);
  }

  function ReturnPolicyChange(event) {
    var findObj = editOrderItems.findIndex(
      (o) => o.OrderItemId === parseInt(event.target.id, 10)
    );

    var returnPolicyId = parseInt(event.target.value, 10);

    if (returnPolicyId === 2) {
      // Handle by skruvat, set ALL rows to this value
      editOrderItems.forEach((orderItem) => {
        orderItem.RetPolicyId = returnPolicyId;
      });
    } else {
      editOrderItems[findObj].RetPolicyId = returnPolicyId;
    }
    setEditOrderItems([...editOrderItems]);
  }
}
