import React, { useState, useEffect, useContext } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import { SessionContext } from "../Context";

import {
  SkruvatCheckbox,
  SkruvatRadioButton,
} from "../Components/SkruvatComponents";
import { ImageUploadComponent } from "../Components/ImageUploadComponent";

export default function ClaimComponent(
  ApiClient,
  Order,
  OnClaimHandled,
  TimeLimitsHelper,
  IsAdmin = false
) {
  const { t, i18n } = useTranslation("common");
  const sessionInfo = useContext(SessionContext);

  const [form, setForm] = useState({ ReturnReason: "N/A" });
  const [formIsValid, setFormIsValid] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [returnDescription, setReturnDescription] = useState("");

  const HandleReturnDescriptionChange = (e) => {
    setReturnDescription(e.target.value);
  };

  useEffect(() => {
    function ValidateForm() {
      if (!["WRONG_PRODUCT", "DAMAGED_PRODUCT"].includes(form.ReturnReason))
        return false;

      if (form.ReturnReason === "WRONG_PRODUCT") {
        if (!form.ProblemDate) return false;

        if (!form.KMDriven) return false;

        if (!form.WhoMounted) return false;

        if (!returnDescription.length > 0) return false;
      } else if (form.ReturnReason === "DAMAGED_PRODUCT") {
        if (!imageList.length > 0) return false;
      }

      if (form.ReplacementSendChoice) {
        if (!form.SocialSecurityNo) return false;
        if (!form.AcceptReplacementText) return false;
      }
      return true;
    }

    setFormIsValid(ValidateForm());
  }, [form, imageList, returnDescription]);

  const HandleInputChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  return (
    <Row>
      <Col xs="12" md="6">
        <div className="font-size14 bold">
          {tryTranslate("Step2.Step2ReturnHeader")}
          <span className="asterisk-red">*</span>
        </div>

        <div>
          <SkruvatRadioButton
            value="WRONG_PRODUCT"
            checked={form.ReturnReason === "WRONG_PRODUCT"}
            name="ReturnReason"
            onChange={HandleInputChange}
            label={tryTranslate("ReturnReasonIdentities.Complaint")}
            disabled={
              Order.OrderItems.length < 1 ||
              TimeLimitsHelper.IsTooLateForWrongProduct(
                Order.OrderItems[0].OrderDate
              )
            }
          ></SkruvatRadioButton>
          <span> </span>
          <SkruvatRadioButton
            value="DAMAGED_PRODUCT"
            checked={form.ReturnReason === "DAMAGED_PRODUCT"}
            name="ReturnReason"
            onChange={HandleInputChange}
            label={tryTranslate("ReturnReasonIdentities.DAMAGED_PRODUCT")}
            disabled={
              Order.OrderItems.length < 1 ||
              TimeLimitsHelper.IsTooLateForDamagedProduct(
                Order.OrderItems[0].OrderDate
              )
            }
          ></SkruvatRadioButton>
        </div>

        {WrongProductForm(form)}

        {DamagedProductForm(form)}

        <br />
        <Button
          className="form-control"
          disabled={!formIsValid}
          variant="success"
          onClick={HandleButtonClick}
        >
          {tryTranslate("General.Confirm")}
        </Button>
      </Col>
      <Col xs="12" md="6" hidden={IsAdmin}>
        <div>
          <span className="asterisk-red">*</span> ={" "}
          {tryTranslate("General.GeneralText2")}
        </div>
        <div className="return-description">
          <div hidden={form.ReturnReason === "DAMAGED_PRODUCT"}>
            <div className="font-size18 bold">
              {tryTranslate("ReturnReasonIdentities.Complaint")}
            </div>
            <div
              hidden={
                !TimeLimitsHelper.IsTooLateForWrongProduct(
                  Order.OrderItems.length < 1 || Order.OrderItems[0].OrderDate
                )
              }
            >
              {tryTranslate("Order.TimeLimitNoteForManufacturingDefect")}
            </div>
            <div>{tryTranslate("Step3.Rek_Side_text")}</div>
          </div>
          <div hidden={form.ReturnReason === "WRONG_PRODUCT"}>
            <div className="font-size18 bold">
              {tryTranslate("ReturnReasonIdentities.DAMAGED_PRODUCT")}
            </div>
            <div
              hidden={
                !TimeLimitsHelper.IsTooLateForDamagedProduct(
                  Order.OrderItems.length < 1 || Order.OrderItems[0].OrderDate
                )
              }
            >
              {tryTranslate("Order.TimeLimitNoteForDamageProduct")}
            </div>
            <div hidden={form.ReturnReason === "DAMAGED_PRODUCT"}>
              {tryTranslate("Step3.Frak_Side_text1")}
            </div>
            <div>{tryTranslate("Step3.Frak_Side_text6")}</div>
            <div>{tryTranslate("Step3.Frak_Side_text7a")}</div>
            <div>{tryTranslate("Step3.Frak_Side_text7b")}</div>
            <div>{tryTranslate("Step3.Frak_Side_text7c")}</div>
          </div>
          <div>
            {tryTranslate("Step3.Frak_Side_text3")}
            <a
              href={tryTranslate("Step3.Frak_Side_text4")}
              target="_blank"
              rel="noopener noreferrer"
            >
              {tryTranslate("Step3.Frak_Side_text5")}
            </a>
          </div>
        </div>
      </Col>
    </Row>
  );

  function WrongProductForm(form) {
    if (form.ReturnReason !== "WRONG_PRODUCT") return;
    return (
      <div>
        <div className="font-size14 bold paddingBefore10">
          {tryTranslate("Step3.Photo_text")}
        </div>
        <div>
          {tryTranslate("Step3.Photo_text_info")}
          <br />
          <br />
        </div>
        <div>
          <ImageUploadComponent
            ImageList={imageList}
            UpdateImageList={setImageList}
            SaveFileApiEndpoint={ApiClient.SaveFile}
            RemoveFileApiEndpoint={ApiClient.RemoveFile}
            FolderName=""
          />
        </div>

        <div className="font-size14 bold paddingBefore10">
          {tryTranslate("Step3.ComplaintsSpecification")}
        </div>
        <Row>
          <Col className="paddingBefore10">
            <div className="font-size14">
              {tryTranslate("Step3.When_the_problem_was_encountered")}
              <span className="asterisk-red">*</span>

              <DatePicker
                selected={form.ProblemDate}
                onChange={(date) =>
                  HandleInputChange({
                    target: { name: "ProblemDate", value: date },
                  })
                }
              />
            </div>
          </Col>
          <Col className="paddingBefore10">
            <div className="font-size14">
              {tryTranslate("Step3.how_many_kilometers_have_you_used_product")}
              <span className="asterisk-red">*</span>
              <input
                id="txtKM"
                name="KMDriven"
                defaultValue={form.KMDriven}
                type="number"
                placeholder="KM"
                maxLength="10"
                onChange={HandleInputChange}
                required
              />
            </div>
          </Col>
        </Row>
        <div className="font-size14 paddingBefore10">
          {tryTranslate("Step3.Who_mounted_the_product")}
          <span className="asterisk-red">*</span>
        </div>
        <div>
          <SkruvatRadioButton
            checked={form.WhoMounted === "Private"}
            name="WhoMounted"
            onChange={HandleInputChange}
            value="Private"
            label={tryTranslate("Step3.Private")}
          ></SkruvatRadioButton>
          <span> </span>
          <SkruvatRadioButton
            checked={form.WhoMounted === "Workshop"}
            name="WhoMounted"
            onChange={HandleInputChange}
            value="Workshop"
            label={tryTranslate("Step3.Workshop")}
          ></SkruvatRadioButton>
          <span> </span>
          <SkruvatRadioButton
            checked={form.WhoMounted === "NotMounted"}
            name="WhoMounted"
            onChange={HandleInputChange}
            value="NotMounted"
            label={tryTranslate("Step3.NotMounted")}
          ></SkruvatRadioButton>
        </div>
        <div className="font-size14 bold paddingBefore10">
          {tryTranslate("Step3.DescriptionReq")}
          <span className="asterisk-red">*</span>
        </div>
        <textarea
          className="form-control"
          placeholder={tryTranslate("Step3.DescriptionReqText1")}
          name="ClaimDescription"
          maxLength="1000"
          rows="5"
          onChange={HandleReturnDescriptionChange}
          value={returnDescription}
        />
        {/* The following two readio buttons is hidden and the defalult is Money back */}
        <div hidden={true} className="paddingBefore20"> 
          <SkruvatRadioButton
            className="choice-status replacement"
            value={false}
            checked={!form.ReplacementSendChoice}
            name="ReplacementSendChoice"
            onChange={HandleInputChange}
            label={tryTranslate("Step4.RefundLabel")}
          ></SkruvatRadioButton>
        </div>
        <div hidden={true} className="paddingBefore10">
          <SkruvatRadioButton
            className="choice-status replacement"
            value={true}
            checked={form.ReplacementSendChoice}
            name="ReplacementSendChoice"
            onChange={HandleInputChange}
            label={tryTranslate("Step4.ReplacementLabel")}
          ></SkruvatRadioButton>
        </div>
        <div hidden={!form.ReplacementSendChoice}>
          <div className="font-size14 bold paddingBefore10">
            {tryTranslate("Step4.SocialSecurityNo")}
            <span className="asterisk-red">*</span>
          </div>
          <input
            name="SocialSecurityNo"
            type="text"
            onChange={HandleInputChange}
            defaultValue={form.SocialSecurityNo}
            placeholder="XXXXXXXXXXXX"
            maxLength="12"
            required
          />
          <div className="font-size14 bold">
            {tryTranslate("Step4.ReplacementLabelBelowText1")}
          </div>
          <div>
            <span className="text-red">
              {tryTranslate("Step4.ReplacementLabelBelowText5")}
            </span>{" "}
            <span className="font-size14 bold">
              {tryTranslate("Step4.ReplacementLabelBelowText2")}
            </span>
          </div>
          <div>
            <br />
            <SkruvatCheckbox
              name="AcceptReplacementText"
              checked={form.AcceptReplacementText}
              value={form.AcceptReplacementText ? false : true}
              onChange={HandleInputChange}
            ></SkruvatCheckbox>{" "}
            <span className="font-size14 bold">
              {tryTranslate("Step4.ReplacementLabelBelowText4")}
            </span>
            <span className="asterisk-red font-size14">*</span>
          </div>
        </div>
      </div>
    );
  }

  function DamagedProductForm(form) {
    if (form.ReturnReason !== "DAMAGED_PRODUCT") return;
    return (
      <div>
        <div className="font-size14 bold paddingBefore10">
          {tryTranslate("Step3.Photo_text")}
          <span className="asterisk-red">*</span>
        </div>

        <div>{tryTranslate("Step3.Photo_text_info")}</div>
        <div>
          <ImageUploadComponent
            ImageList={imageList}
            UpdateImageList={setImageList}
            SaveFileApiEndpoint={ApiClient.SaveFile}
            RemoveFileApiEndpoint={ApiClient.RemoveFile}
          />
        </div>

        <div className="font-size14 bold paddingBefore10">
          {tryTranslate("Step3.Description")}
        </div>

        <textarea
          className="form-control"
          placeholder={tryTranslate("Step3.DescriptionReqText1")}
          name="ClaimDescription"
          maxLength="1000"
          rows="5"
          onChange={HandleReturnDescriptionChange}
          value={returnDescription}
        />
        {/* The following two readio buttons is hidden and the defalult is Money back */}
        <div hidden={true} className="paddingBefore20">
          <SkruvatRadioButton
            className="choice-status replacement"
            value={false}
            checked={!form.ReplacementSendChoice}
            name="ReplacementSendChoice"
            onChange={HandleInputChange}
            label={tryTranslate("Step4.RefundLabel")}
          ></SkruvatRadioButton>
        </div>
        <div hidden={true} className="paddingBefore10">
          <SkruvatRadioButton
            className="choice-status replacement"
            value={true}
            checked={form.ReplacementSendChoice}
            name="ReplacementSendChoice"
            onChange={HandleInputChange}
            label={tryTranslate("Step4.ReplacementLabel")}
          ></SkruvatRadioButton>
        </div>
        <div hidden={!form.ReplacementSendChoice}>
          <div className="font-size14 bold paddingBefore10">
            {tryTranslate("Step4.SocialSecurityNo")}
            <span className="asterisk-red">*</span>
          </div>
          <input
            name="SocialSecurityNo"
            type="text"
            onChange={HandleInputChange}
            defaultValue={form.SocialSecurityNo}
            placeholder="XXXXXXXXXXXX"
            maxLength="12"
            required
          />
          <div className="font-size14 bold">
            {tryTranslate("Step4.ReplacementLabelBelowText1")}
          </div>
          <div>
            <span className="text-red">
              {tryTranslate("Step4.ReplacementLabelBelowText5")}
            </span>{" "}
            <span className="font-size14 bold">
              {tryTranslate("Step4.ReplacementLabelBelowText2")}
            </span>
          </div>
          <div>
            <br />
            <SkruvatCheckbox
              name="AcceptReplacementText"
              checked={form.AcceptReplacementText}
              value={form.AcceptReplacementText ? false : true}
              onChange={HandleInputChange}
            ></SkruvatCheckbox>{" "}
            <span className="font-size14 bold">
              {tryTranslate("Step4.ReplacementLabelBelowText4")}
            </span>
            <span className="asterisk-red font-size14">*</span>
          </div>
        </div>
      </div>
    );
  }

  function tryTranslate(string) {
    if (i18n.exists("common:" + string)) return t(string);
  }

  function BuildSetValueString(form) {
    var retStr = "SET * GARANT";
    if (form.ReturnReason === "WRONG_PRODUCT") {
      retStr +=
        "|" +
        moment(form.ProblemDate).format("L") +
        "|" +
        form.KMDriven +
        "km|" +
        form.WhoMounted;
    }

    if (form.ReplacementSendChoice) {
      retStr += "|" + form.SocialSecurityNo;
    }

    return retStr;
  }

  async function HandleButtonClick() {
    if (formIsValid) {
      var result = await ApiClient.SaveReturnOrder(
        Order,
        form.ReturnReason,
        returnDescription,
        sessionInfo?.Language,
        form.ReplacementSendChoice ? "REPLACEMENT" : "REFUND_ONLY",
        BuildSetValueString(form),
        imageList.length > 0 ? imageList[0].FolderName : ""
      );

      OnClaimHandled(result);
    }
  }
}
