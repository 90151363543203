import React, { useState } from "react";
import { Button, Row, Col, Alert, ProgressBar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-regular-svg-icons";
import { useTranslation } from "react-i18next";

import { ListImagesComponent } from "./ListImagesComponent";

export function ImageUploadComponent({
  ImageList,
  UpdateImageList,
  SaveFileApiEndpoint,
  RemoveFileApiEndpoint,
  FolderName,
}) {
  const [folderName, setFolderName] = useState(FolderName ?? "");
  const [errorString, setErrorString] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [progressBar, setProgressBar] = useState(30);
  const { t } = useTranslation("common");

  return (
    <React.Fragment>
      <div hidden={!isLoading}>
        <ProgressBar
          animated
          now={progressBar}
          label={`${progressBar}%`}
          variant="success"
        />
        <br />
      </div>
      <ListImagesComponent
        ImageList={ImageList}
        RemoveImageHandler={RemoveImage}
      />
      <Row>
        <Col xs="1">
          <span
            className="product-thumbnail product-img-box"
            onClick={() => {
              document.getElementById("file-input").click();
            }}
          >
            <FontAwesomeIcon icon={faImage} className="fa-picture" />
          </span>
        </Col>
        <Col>
          <div className="vertical-middle paddingLeft75 width250">
            {t("General.GeneralText1")}
            <br />
            <Button
              variant="primary"
              onClick={() => {
                document.getElementById("file-input").click();
              }}
              size="sm"
            >
              {t("Step4.Uploadfile")}
            </Button>
            <input
              id="file-input"
              type="file"
              onChange={upload}
              accept=".xlsx,.xls,.doc,.docx,.pdf,.txt,.jpeg,.jpg,.png,.gif,.bmp"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <br />
          <Alert
            variant="danger"
            hidden={errorString.length === 0}
            onClose={() => setErrorString("")}
            dismissible
          >
            {errorString}
          </Alert>
        </Col>
      </Row>
    </React.Fragment>
  );

  async function upload(file) {
    if (
      !file ||
      !file.target ||
      !file.target.files ||
      file.target.files.length === 0
    )
      return false;

    // ReturnWebBackend cannot handle multiple files
    let fileToUpload = file.target.files[0];
    file.target.value = null;

    if (!FileIsValid(fileToUpload)) return false;

    setIsLoading(true);

    var saveFileResult = await SaveFileApiEndpoint(
      fileToUpload,
      folderName,
      setProgressBar
    );

    if (saveFileResult.Success) {
      UpdateImageList([...ImageList, saveFileResult]);
      setFolderName(saveFileResult.FolderName);
    } else {
      // There was an error saving the file,
      var errStr = "";
      saveFileResult.Errors.forEach((error) => {
        errStr += t(error) + "\r\n";
      });

      setErrorString(errStr);
    }

    setIsLoading(false);
    setProgressBar(0);
  }

  async function RemoveImage(image) {
    setIsLoading(true);
    var successful = await RemoveFileApiEndpoint(image.Url);

    if (successful) {
      var newBilderList = ImageList.filter((x) => x.Url !== image.Url);
      UpdateImageList(newBilderList);
    } else {
      setErrorString("Failed to remove image");
    }

    setIsLoading(false);
  }

  function FileIsValid(file) {
    const ext = file.name.substring(file.name.lastIndexOf("."));
    if (
      ![
        ".jpeg",
        ".jpg",
        ".png",
        ".gif",
        ".bmp",
        ".xlsx",
        ".xls",
        ".doc",
        ".docx",
        ".pdf",
        ".txt",
      ].includes(ext.toLowerCase())
    ) {
      setErrorString(t("Step4.UploadImagesRestriction"));
      return false;
    }

    if (file.size > 10 * 1024 * 1024) {
      // Max 10 MB
      setErrorString(t("Error.ValidateFileSize"));
      return false;
    }

    return true;
  }
}
