import config from "../config.json";

class CommentApiClient {
  GetComments = (ReturnOrderid) => {
    return new Promise(function (resolve) {
      fetch(
        config.RETURNWEB_API +
          `/Comment/BrowseComments?retOrderId=${ReturnOrderid}&displayMode=1&channel=4&markedAsRead=${null}`,
        {
          credentials: "include",
        }
      )
        .then((res) => res.json())
        .then((result) => {
          return resolve(result);
        })
        .catch(() => {
          console.log("Error fetching data.");
          return resolve();
        });
    });
  };

  GetApproveComments = (ReturnOrderid) => {
    return new Promise(function (resolve) {
      fetch(
        config.RETURNWEB_API +
          `/Comment/BrowseApproveComments?retOrderId=${ReturnOrderid}&displayMode=1&channel=4&markedAsRead=${null}`,
        {
          credentials: "include",
        }
      )
        .then((res) => res.json())
        .then((result) => {
          return resolve(result);
        })
        .catch(() => {
          console.log("Error fetching data.");
          return resolve();
        });
    });
  };

  DeleteComment = (ReturnOrderid, CommentId) => {
    return new Promise(function (resolve) {
      fetch(
        config.RETURNWEB_API +
          `/Comment/DeleteComment?commentId=${CommentId}&returnOrderId=${ReturnOrderid}`,
        {
          credentials: "include",
        }
      )
        .then((res) => res.json())
        .then((result) => {
          return resolve(result);
        })
        .catch(() => {
          console.log("Error removing data.");
          return resolve();
        });
    });
  };

  AddComment = (ReturnOrderid, Text) => {
    return new Promise(function (resolve) {
      fetch(
        config.RETURNWEB_API +
          `/Comment/AddComment?text=${Text}&returnOrderId=${ReturnOrderid}&channel=4`,
        {
          credentials: "include",
        }
      )
        .then((res) => res.json())
        .then((result) => {
          return resolve(result);
        })
        .catch(() => {
          console.log("Error adding data.");
          return resolve();
        });
    });
  };
}

export default new CommentApiClient();
