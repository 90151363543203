import moment from 'moment';

const TIMELIMIT_WITHDRAWN = 50;
const TIMELIMIT_WRONGPRODUCT = 1095;
const TIMELIMIT_DAMAGEDPRODUCT = 21;
const TIMELIMIT_ADMIN = 1095;

class SkruvatTimelimits {
    IsTooLateForWithdraw =
        (
            orderDate
        ) => {
            return (
                GetDaysSinceOrderCreated(
                    orderDate
                ) >
                TIMELIMIT_WITHDRAWN
            );
        };

    IsTooLateForWrongProduct =
        (
            orderDate
        ) => {
            return (
                GetDaysSinceOrderCreated(
                    orderDate
                ) >
                TIMELIMIT_WRONGPRODUCT
            );
        };

    IsTooLateForDamagedProduct =
        (
            orderDate
        ) => {
            return (
                GetDaysSinceOrderCreated(
                    orderDate
                ) >
                TIMELIMIT_DAMAGEDPRODUCT
            );
        };
}

export default new SkruvatTimelimits();

export class SkruvatAdminTimelimits {
    IsTooLateForWithdraw =
        (
            orderDate
        ) => {
            return (
                GetDaysSinceOrderCreated(
                    orderDate
                ) >
                TIMELIMIT_ADMIN
            );
        };

    IsTooLateForWrongProduct =
        (
            orderDate
        ) => {
            return (
                GetDaysSinceOrderCreated(
                    orderDate
                ) >
                TIMELIMIT_ADMIN
            );
        };

    IsTooLateForDamagedProduct =
        (
            orderDate
        ) => {
            return (
                GetDaysSinceOrderCreated(
                    orderDate
                ) >
                TIMELIMIT_ADMIN
            );
        };
}

function GetDaysSinceOrderCreated(
    orderDate
) {
    return moment(
        new Date()
    ).diff(
        moment(
            orderDate
        ),
        'days'
    );
}

export function CopyTextToClipboard(
    text
) {
    var textField =
        document.createElement(
            'textarea'
        );
    textField.innerText =
        text;
    document.body.appendChild(
        textField
    );
    textField.select();
    document.execCommand(
        'copy'
    );
    textField.remove();
}
export function IsStringDecimal(
    stringToCheck
) {
    return /^\d*(\.|,)?\d*$/.test(
        stringToCheck
    );
}

export function IsStringNumber(
    stringToCheck
) {
    return /^\d*$/.test(
        stringToCheck
    );
}

// Globals
Array.prototype.skip =
    function (
        count
    ) {
        return this.filter(
            (
                _,
                i
            ) =>
                i >=
                count
        );
    };

Array.prototype.take =
    function (
        count
    ) {
        return this.filter(
            (
                _,
                i
            ) =>
                i <
                count
        );
    };
