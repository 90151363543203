import React, { useEffect, useState, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Card, Container, Button, Row, Col, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import {
  SkruvatCollapsableCard,
  ActivityAndEmailLogs,
} from "../Components/SkruvatComponents";
import CreditManagerApiClient from "../ApiClients/CreditManagerApiClient";
import Calculator from "../Components/CalculatorComponent";
import Comments from "../Components/CommentComponent";
import ReturnInformationComponent from "../Components/ReturnInformationComponent";
import {
  ReturnDetailsHeader,
  ProductInformation,
  CustomerInformation,
} from "../Components/ReturnDetailsComponents";

import { LoadingContext } from "../Context";

export default function ReturnDetails() {
  const { t } = useTranslation("common");
  const { ReturnOrderId } = useParams();
  const [returnOrderDetails, setReturnOrderDetails] = useState({});
  const [originalOrderItems, setOriginalOrderItems] = useState([]);
  const [customerDetails, setCustomerDetails] = useState({});
  const [activities, setActivities] = useState([]);
  const [returnStatusList, setReturnStatusList] = useState([]);
  const [activityAndEmailLogs, setActivityAndEmailLogs] = useState([]);
  const [returnPolicies, setReturnPolicies] = useState([]);
  const [numberOfComments, setNumberOfComments] = useState(0);
  const [hideCalculator, setHideCalculator] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const loader = useContext(LoadingContext);
  const history = useHistory();

  useEffect(() => {
    loader.updateShowLoader(true);

    async function GetOrderItems(orderId) {
      return await CreditManagerApiClient.GetOrderItems(orderId);
    }

    async function GetReturnOrderMoreDetail() {
      return await CreditManagerApiClient.GetReturnOrderMoreDetail(
        ReturnOrderId
      );
    }

    async function GetActivities() {
      return await CreditManagerApiClient.GetActivitiesforCreditManager(
        ReturnOrderId
      );
    }

    async function GetReturnStatuses() {
      return await CreditManagerApiClient.GetAllReturnStatus();
    }

    async function GetActivityAndEmailLogs() {
      return await CreditManagerApiClient.GetActivityAndEmailLogs(
        ReturnOrderId
      );
    }

    async function GetReturnPolicies() {
      return await CreditManagerApiClient.GetReturnPolicies();
    }

    var promiseActivities = GetActivities();
    var promiseReturnStatuses = GetReturnStatuses();
    var promiseActivityAndEmailLogs = GetActivityAndEmailLogs();
    var promiseReturnOrderMoreDetail = GetReturnOrderMoreDetail();
    var promiseReturnPolicies = GetReturnPolicies();

    Promise.all([
      promiseActivities,
      promiseReturnStatuses,
      promiseActivityAndEmailLogs,
      promiseReturnOrderMoreDetail,
      promiseReturnPolicies,
    ]).then((values) => {
      setActivities(values[0]);
      setReturnStatusList(values[1]);
      setActivityAndEmailLogs(values[2]);
      setReturnOrderDetails(values[3].ReturnOrder);
      setCustomerDetails(values[3].user);
      setReturnPolicies(values[4]);

      if (values[3].ReturnOrder) {
        GetOrderItems(values[3].ReturnOrder.OrderId).then((result) => {
          setOriginalOrderItems(result.orderItems);
          loader.updateShowLoader(false);
        });
      } else {
        setErrorMsg("Failed to get return order details");
        loader.updateShowLoader(false);
      }
    });
  }, []);

  if (!returnOrderDetails?.RetOrderId)
    return (
      <Alert hidden={errorMsg.length === 0} variant="danger">
        {errorMsg}
      </Alert>
    );

  return (
    <Container className="paddingBefore20">
      <Alert
        hidden={errorMsg.length === 0}
        variant="danger"
        onClose={() => setErrorMsg("")}
        dismissible
      >
        {errorMsg}
      </Alert>
      <Card>
        <Card.Header>
          <ReturnDetailsHeader
            ReturnOrderDetails={returnOrderDetails}
            ReturnStateTranslated={LookupReturnState(
              returnOrderDetails.RetOrderStateId
            )}
            IsRM={false}
          />
        </Card.Header>
        <Card.Body>
          <Row>
            <Col className="font-size18 bold">Product information</Col>
          </Row>
          <Row>
            <ProductInformation
              ReturnOrderDetails={returnOrderDetails}
              AllOrderItems={originalOrderItems}
              ReturnPolicies={returnPolicies}
              OnHideCalculatorButtonClick={() => {
                setHideCalculator((current) => !current);
              }}
              OnUpdateReturnOrderItems={UpdateReturnOrderIssues}
            />
            <Col>
              <Card hidden={returnOrderDetails.RetOrderStateId !== 8}>
                <Card.Header className="font-size14 bold nowrap">
                  Select activity
                </Card.Header>
                <Card.Body>
                  {activities.map((activity, index) => {
                    return (
                      <Button
                        key={index}
                        size="sm"
                        variant="secondary"
                        className={
                          "activity-btn-" +
                          activity.Id +
                          " activityButton nowrap"
                        }
                        onClick={() => ActivityClick(activity.Id)}
                      >
                        {activity.Text}
                      </Button>
                    );
                  })}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <br />
      <SkruvatCollapsableCard
        Hidden={!returnOrderDetails.IsCalculationEnabled}
        Title="Calculator"
        ExternalControl={hideCalculator}
      >
        <Calculator
          ReturnOrderId={returnOrderDetails.RetOrderId}
          OnTotalOrderAmountChanged={HandleCalculatorChangedOrderTotalAmount}
        />
      </SkruvatCollapsableCard>
      <br />
      <SkruvatCollapsableCard Title="Return Information" ShowByDefault={true}>
        <ReturnInformationComponent
          ApiClient={CreditManagerApiClient}
          showRefund={true}
          ReturnOrderDetails={returnOrderDetails}
          OnReturnOrderUpdated={(updatedReturnOrder) => {
            setReturnOrderDetails(updatedReturnOrder);
          }}
          OnError={setErrorMsg}
        />
      </SkruvatCollapsableCard>
      <br />
      <SkruvatCollapsableCard Title="Customer Information">
        <CustomerInformation CustomerDetails={customerDetails} />
      </SkruvatCollapsableCard>
      <br />
      <SkruvatCollapsableCard Title={`Comments - ${numberOfComments}`}>
        <Comments
          ReturnOrderId={returnOrderDetails.RetOrderId}
          SetNumberOfComments={setNumberOfComments}
        />
      </SkruvatCollapsableCard>
      <br />
      <SkruvatCollapsableCard Title="Logs">
        <ActivityAndEmailLogs
          ActivityLogs={activityAndEmailLogs?.activityLogs}
          EmailLogs={activityAndEmailLogs?.emailLogs}
          Translate={t}
        />
      </SkruvatCollapsableCard>
    </Container>
  );

  function LookupReturnState(id) {
    var returnOrderState = returnStatusList.filter((e) => e.Id === id);
    if (returnOrderState.length === 0) return null;

    return t(returnOrderState[0].Text);
  }

  function UpdateReturnOrderIssues(newReturnIssues) {
    returnOrderDetails.ReturnIssues = newReturnIssues;
    CreditManagerApiClient.UpdateReturnOrderIssues(returnOrderDetails).then(
      (res) => {
        history.go(0);
      },
      (err) => {
        setErrorMsg(err);
      }
    );
  }

  function HandleCalculatorChangedOrderTotalAmount(TotalAmount) {
    setReturnOrderDetails((prevValue) => {
      return { ...prevValue, OrderTotalAmount: TotalAmount };
    });
  }

  function ActivityClick(Id) {
    setErrorMsg("");

    CreditManagerApiClient.ChangeStatusAndRefundType(
      returnOrderDetails.RetOrderId,
      Id,
      returnOrderDetails.ReturnReasonRefundTypeId
    ).then(
      (res) => {
        if (res.success) history.push("/CM/");
      },
      (error) => {
        setErrorMsg(error);
      }
    );
  }
}
