import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Button, Row, Col, Container, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { UserContext } from "../Context";

function ReturnManagerHeader() {
  const { t } = useTranslation("common");
  const history = useHistory();
  const userInfo = useContext(UserContext);

  return (
    <div>
      <Container fluid className="whiteContainer">
        <Row className="paddingBefore20">
          <Col>
            <Image
              id="imgLogo"
              src="/return/Images/logo.png"
              alt=""
              className="img-responsive"
            />
          </Col>
          <Col xs="6">
            <Button
              variant={
                EndsWith(history.location.pathname, "RM")
                  ? "success"
                  : "outline-success"
              }
              onClick={() => history.push("/RM")}
            >
              {t("Menu.Returns")}
            </Button>{" "}
            <Button
              variant={
                EndsWith(history.location.pathname, "Statistics")
                  ? "success"
                  : "outline-success"
              }
              onClick={() => history.push("/RM/Statistics")}
            >
              {t("Menu.Statistics")}
            </Button>{" "}
            <Button
              variant={
                EndsWith(history.location.pathname, "Create")
                  ? "success"
                  : "outline-success"
              }
              onClick={() => history.push("/RM/Create")}
            >
              {t("Menu.CreateReturnOrder")}
            </Button>
          </Col>
          <Col xs="2">
            <span className="font-size18">
              {userInfo.FirstName} {userInfo.LastName}
            </span>
            <br />
            Admin- {userInfo.Roles.replace("+", " ")}
            <br />
            <Button
              variant="danger"
              size="sm"
              onClick={() => history.replace("/Logout")}
            >
              {t("General.Logout")}
            </Button>
          </Col>
        </Row>
        <Row className="headerBar">
          <Col xs="12">
            <Button
              variant="link"
              className="headerBar contactButton float-right"
            >
              <FontAwesomeIcon icon={faPhoneAlt} />
              &nbsp; 08-120 20 420
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );

  function EndsWith(path, match) {
    var arr = path.split("/");
    return (arr[arr.length - 1] || arr[arr.length - 2]) === match;
  }
}

export default ReturnManagerHeader;
