import React, { useState, useEffect, useContext } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";

import { SessionContext } from "../Context";

export default function ReturnComponent(
  Order,
  ApiClient,
  OnReturnHandled,
  IsAdmin = false
) {
  const { t, i18n } = useTranslation("common");
  const sessionInfo = useContext(SessionContext);
  const [returnReason, setReturnReason] = useState("N/A");
  const [returnDescription, setReturnDescription] = useState("");
  const [formIsValid, setFormIsValid] = useState(false);

  useEffect(() => {
    function ValidateForm() {
      if (returnReason === "DELIVERED_WRONG_PRODUCT") {
        if (!returnDescription.length > 0) return false;
      } 
      return true;
    }

    setFormIsValid(ValidateForm());
  }, [returnDescription, returnReason]);

  const HandleReturnDescriptionChange = (e) => {
    setReturnDescription(e.target.value);
  };

  return (
    <Row>
      <Col xs="12" md="6">
        <div className="font-size14 bold">
          {tryTranslate("Step2.Step2Header")}
          <span className="asterisk-red">*</span>
        </div>
        <p>
          <DropDown />
        </p>
        <p hidden={["N/A", "WITHDRAWN"].includes(returnReason)}>
        <div hidden={returnReason !== "DELIVERED_WRONG_PRODUCT"} className="font-size14 bold paddingBefore10">
          {tryTranslate("Step3.DescriptionReq")}
          <span className="asterisk-red">*</span>
        </div>
          <textarea
            className="form-control"
            placeholder={
              returnReason === "UNFIT"
                ? tryTranslate("Step3.DescriptionText")
                : tryTranslate("Step3.DescriptionText1")
            }
            maxLength="1000"
            rows="5"
            onChange={HandleReturnDescriptionChange}
            value={returnDescription}
          />
        </p>
        <Button
          className="form-control"
          disabled={returnReason === "N/A" || !formIsValid}
          variant="success"
          onClick={HandleButtonClick}
        >
          {tryTranslate("General.Confirm")}
        </Button>
      </Col>
      <Col xs="12" md="6" hidden={IsAdmin}>
        <div>
          <span className="asterisk-red">*</span> ={" "}
          {tryTranslate("General.GeneralText2")}
        </div>
        <div className="return-description">
          <div className="font-size18 bold">
            {tryTranslate("Step3.Side_text1")}
          </div>
          <div>{tryTranslate("Step3.Side_text2a")}</div>
          <div className="font-size18 bold">
            {tryTranslate("Step3.Side_text3")}
          </div>
          <div>{tryTranslate("Step3.Side_text3a")}</div>
          <div className="font-size18 bold">
            {tryTranslate("Step3.Side_text4")}
          </div>
          <div>{tryTranslate("Step3.Side_text4d")}</div>
          <div>{tryTranslate("Step3.Side_text4a")}</div>
          <div>{tryTranslate("Step3.Side_text4b")}</div>
          <div>{tryTranslate("Step3.Side_text4c")}</div>
        </div>
      </Col>
    </Row>
  );

  function tryTranslate(string) {
    if (i18n.exists("common:" + string)) return t(string);
  }

  function DropDown() {
    let dropDownValues = [
      { id: 0, code: "N/A", displayName: "ReturnReasonIdentities.Selectif" },
      {
        id: 1,
        code: "WITHDRAWN",
        displayName: "ReturnReasonIdentities.WITHDRAWN",
      },
      {
        id: 6,
        code: "DELIVERED_WRONG_PRODUCT",
        displayName: "ReturnReasonIdentities.DELIVERED_WRONG_PRODUCT",
      },
      {
        id: 7,
        code: "DELAYED_DELIVERY",
        displayName: "ReturnReasonIdentities.DELAYED_DELIVERY",
      },
      {
        id: 8,
        code: "INCOMPLETE_DELIVERY",
        displayName: "ReturnReasonIdentities.INCOMPLETE_DELIVERY",
      },
      {
        id: 9,
        code: "ORDERED_WRONG_PRODUCT",
        displayName: "ReturnReasonIdentities.ORDERED_WRONG_PRODUCT",
      },
      { id: 2, code: "UNFIT", displayName: "ReturnReasonIdentities.UNFIT" },
    ];

    return (
      <select
        className="form-control"
        value={returnReason}
        onChange={ReturnReasonChanged}
      >
        {dropDownValues.map((v) => {
          return (
            <option key={v.id} value={v.code}>
              {tryTranslate(v.displayName)}
            </option>
          );
        })}
      </select>
    );
  }

  function ReturnReasonChanged(event) {
    setReturnReason(event.target.value);
  }

  async function HandleButtonClick() {
    if (returnReason !== "N/A") {
      var result = await ApiClient.SaveReturnOrder(
        Order,
        returnReason,
        returnDescription,
        sessionInfo?.Language
      );

      OnReturnHandled(result);
    }
  }
}
