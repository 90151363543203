import React, { useState, useEffect, useContext } from "react";
import { Table, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import ReturnManagerApiClient from "../ApiClients/ReturnManagerApiClient";
import { SkruvatPagination } from "../Components/SkruvatComponents";

import { LoadingContext } from "../Context";

export default function ListReturnOrders({
  ReturnStatusList,
  Filter,
  UpdateFilter,
}) {
  const { t } = useTranslation("common");
  const [orderList, setOrderList] = useState([]);
  const [returnReasonIdentities, setReturnReasonIdentities] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const history = useHistory();
  const loader = useContext(LoadingContext);
  const [sortDirection, setSortDirection] = useState(Filter.sortDirection);

  useEffect(() => {
    async function GetAllOrders() {
      return await ReturnManagerApiClient.GetAllOrders(Filter);
    }
    async function GetReturnReasonIdentities() {
      return await ReturnManagerApiClient.GetReturnReasonIdentities();
    }

    async function updateShowLoader(val) {
      return await loader.updateShowLoader(val);
    }

    updateShowLoader(true);

    GetReturnReasonIdentities().then((result) => {
      setReturnReasonIdentities(result);
    });

    GetAllOrders().then((result) => {
      setOrderList(result);
      if (result.length > 0) {
        setTotalPages(Math.ceil(result[0].TotalCount / 25));
      }
      updateShowLoader(false);
    });

    setSortDirection(Filter.sortDirection)

    window.scrollTo(0, 0);
  }, [Filter]);

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
        <th className="pointer" onClick={CreateDateHeaderClick}>{t("ReturnOrders.RO_Created")} {GetSortArrow()}</th>
          <th>{t("ReturnManager.RMOrderId")}</th>
          <th>{t("ReturnOrders.RetOrderTag")}</th>
          <th>{t("ReturnManager.RMOwner")}</th>
          <th>{t("ReturnManager.Country")}</th>
          <th>{t("ReturnOrders.ReturnOrderStatus")}</th>
          <th>{t("ReturnManager.RMReturnReason")}</th>
          <th>{t("ReturnManager.RMReturnReasonAction")}</th>
        </tr>
      </thead>
      <tbody>
        <ReturnOrderList />
        <tr>
          <td colSpan="8">
            <SkruvatPagination
              onPageChanged={OnPageChanged}
              totalPages={totalPages}
            />
          </td>
        </tr>
      </tbody>
    </Table>
  );

  function OnPageChanged(pageNo) {
    UpdateFilter({ ...Filter, pageNumber: pageNo });
  }

  function ReturnOrderList() {
    if (orderList.length === 0 && !loader.showLoader)
      return (
        <tr>
          <td colSpan="8" className="font-size14 alignCenter bold">
            {t("General.NoRecordsFound")}
          </td>
        </tr>
      );

    return orderList.map((order, index) => {
      return <ReturnOrder key={index} order={order} />;
    });
  }

  function ReturnOrder({ order }) {
    return (
      <tr>
        <td>{moment(order.Created).format("YYYY-MM-DD")}</td>
        <td>
          <a href={order.OrderLink} target="_blank" rel="noopener noreferrer">
            {order.OrderId}
          </a>
        </td>
        <td>{order.RetOrderTag}</td>
        <td>{order.OrderOwnerNameAndEmail.split("|")[0]}</td>
        <td>{order.SWIFT}</td>
        <td>{LookupReturnState(order.RetOrderStateId)}</td>
        <td>{LookupReturnReason(order.RetReasonIdentityId)}</td>
        <td>
          <Button
            size="sm"
            onClick={() =>
              history.push("/RM/ReturnDetails/" + order.RetOrderId)
            }
          >
            View
          </Button>
        </td>
      </tr>
    );
  }

  function GetSortArrow(){
    if(sortDirection === true){
      return <FontAwesomeIcon icon={faSortUp} />;
    } else {
      return <FontAwesomeIcon icon={faSortDown} />;
    }
  }

  function CreateDateHeaderClick(){
    setSortDirection(!sortDirection)
    UpdateFilter({ ...Filter, sortDirection: !sortDirection });
  }

  function LookupReturnState(id) {
    var returnOrderState = ReturnStatusList.filter((e) => e.Id === id);
    if (returnOrderState.length === 0) return null;

    return t(returnOrderState[0].Text);
  }

  function LookupReturnReason(id) {
    var returnReason = returnReasonIdentities.filter((e) => e.Id === id);
    if (returnReason.length === 0) return null;

    return t(returnReason[0].Text);
  }
}
