import config from "../config.json";

class ReturnWebClient {
  Logout = () => {
    return new Promise(function (resolve, reject) {
      fetch(config.RETURNWEB_API + "/Login/Logout", {
        credentials: "include",
      })
        .then((res) => res.json())
        .finally(() => {
          resolve();
        });
    });
  };

  GetLoginToken = () => {
    return new Promise(function (resolve, reject) {
      fetch(config.RETURNWEB_API + "/Login/GetLoginToken", {})
        .then((res) => {
          if (res.status === 200) {
            res.text().then((res) => {
              if (
                res.includes("__RequestVerificationToken") &&
                res.includes("value")
              ) {
                var arr = res.match(/value="(.*)" \/>$/g) || [""];
                if (arr.length > 0 && arr[0].length > 12) {
                  var token = arr[0].slice(7, arr[0].length - 4);
                  resolve(token);
                }
              }
            });
          } else {
            reject("Error authenticating1");
          }
        })
        .catch(() => {
          reject("Error authenticating");
        });
    });
  };

  Login = (email, password, token) => {
    const formData = new FormData();
    formData.append("Email", email);
    formData.append("Password", password);
    formData.append("__RequestVerificationToken", token);

    return new Promise(function (resolve, reject) {
      fetch(config.RETURNWEB_API + "/Login/Auth", {
        method: "POST",
        body: formData,
      })
        .then((res) => {
          if (res.status === 200) {
            res.json().then(resolve);
          } else {
            reject("Error authenticating");
          }
        })

        .catch(() => {
          reject("Error authenticating");
        });
    });
  };
}

export default new ReturnWebClient();
