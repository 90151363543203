import config from "../config.json";

class CalculatorApiClient {
  GetCalculatorDetails = (ReturnOrderid) => {
    return new Promise(function (resolve, reject) {
      fetch(
        config.RETURNWEB_API +
          `/Calculator/GetCalculatorDetail?retOrderId=${ReturnOrderid}&giftCard=${null}`,
        {
          credentials: "include",
        }
      )
        .then((res) => res.json())
        .then((result) => {
          return resolve(result);
        })
        .catch(() => {
          console.log("Error fetching data.");
          reject();
        });
    });
  };

  SaveCalculatorData = (ReturnOrderid, CalculatorRows) => {
    // Replace . with , in decimals when communicating with API
    let calcRows = JSON.parse(JSON.stringify(CalculatorRows)); // Deep copy
    calcRows.forEach((row) => {
      if (row.AmountPerItem && row.AmountPerItem.toString().indexOf(".") !== -1)
        row.AmountPerItem = row.AmountPerItem.toString().replace(".", ",");
      if (
        row.AmountPerItem &&
        row.TotalRowAmount.toString().indexOf(".") !== -1
      )
        row.TotalRowAmount = row.TotalRowAmount.toString().replace(".", ",");
    });

    var UserDataDataString = JSON.stringify({
      retOrderId: ReturnOrderid,
      rows: calcRows,
    });

    return new Promise(function (resolve, reject) {
      fetch(config.RETURNWEB_API + `/Calculator/SaveCalculatorData`, {
        credentials: "include",
        method: "POST",
        body: UserDataDataString,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((result) => {
          return resolve(result);
        })
        .catch(() => {
          console.log("Error sending data.");
          reject();
        });
    });
  };

  AuthorizeByChangeStatus = (ReturnOrderid) => {
    var UserDataDataString = JSON.stringify({
      returnOrderId: ReturnOrderid,
      state: 8,
    });

    return new Promise(function (resolve, reject) {
      fetch(config.RETURNWEB_API + `/Calculator/ChangeStatus`, {
        credentials: "include",
        method: "POST",
        body: UserDataDataString,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((result) => {
          return resolve(result);
        })
        .catch(() => {
          console.log("Error sending data.");
          reject();
        });
    });
  };
}

export default new CalculatorApiClient();
