import React, { useState } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import Cookies from "universal-cookie";

import Login from "./Login";
import Header from "./Header";
import ReturnManagerHeader from "./RM/ReturnManagerHeader";
import CreditManagerHeader from "./CM/CreditManagerHeader";
import Footer from "./Footer";
import ReturnManagerFooter from "./RM/ReturnManagerFooter";
import CreditManagerFooter from "./CM/CreditManagerFooter";
import { UserContext, LoadingContext, SessionContext } from "./Context";
import ReturnProcess from "./Return/ReturnProcess";
import ReturnManager from "./RM/ReturnManager";
import CreditManager from "./CM/CreditManager";
import ReturnDetails from "./RM/ReturnDetails";
import Statistics from "./RM/Statistics";
import Create from "./RM/Create";
import { default as CMReturnDetails } from "./CM/ReturnDetails";

function ReturnWebApp() {
  const cookies = new Cookies();
  var sessionInfo = cookies.get("sessionInfo");
  var userInfo = cookies.get("userInfo");

  const [showLoader, setShowLoader] = useState(0);
  const updateShowLoader = (val) => {
    setShowLoader((prevVal) => (prevVal += val ? 1 : -1));
  };

  return (
    <BrowserRouter basename="/return">
      <LoadingContext.Provider value={{ showLoader, updateShowLoader }}>
        <UserContext.Provider value={userInfo}>
          <SessionContext.Provider value={sessionInfo}>
            <LoadingContext.Consumer>
              {({ showLoader }) => {
                return (
                  <div className="loading" hidden={showLoader <= 0}>
                    <Spinner animation="border" />
                  </div>
                );
              }}
            </LoadingContext.Consumer>

            <div>
              <Switch>
                <Route path="/RM/ReturnDetails/:ReturnOrderId">
                  {() => {
                    if (userInfo?.Roles !== "Return+Manager") {
                      return <Redirect to="/" />;
                    } else {
                      return (
                        <>
                          <ReturnManagerHeader />
                          <ReturnDetails />
                          <ReturnManagerFooter />
                        </>
                      );
                    }
                  }}
                </Route>

                <Route path="/RM/Create">
                  {() => {
                    if (userInfo?.Roles !== "Return+Manager") {
                      return <Redirect to="/" />;
                    } else {
                      return (
                        <>
                          <ReturnManagerHeader />
                          <Create />
                          <ReturnManagerFooter />
                        </>
                      );
                    }
                  }}
                </Route>

                <Route path="/RM/Statistics">
                  {() => {
                    if (userInfo?.Roles !== "Return+Manager") {
                      return <Redirect to="/" />;
                    } else {
                      return (
                        <>
                          <ReturnManagerHeader />
                          <Statistics />
                          <ReturnManagerFooter />
                        </>
                      );
                    }
                  }}
                </Route>

                <Route path="/RM">
                  {() => {
                    if (userInfo?.Roles !== "Return+Manager") {
                      return <Redirect to="/" />;
                    } else {
                      return (
                        <>
                          <ReturnManagerHeader />
                          <ReturnManager />
                          <ReturnManagerFooter noflow={true} />
                        </>
                      );
                    }
                  }}
                </Route>

                <Route path="/CM/ReturnDetails/:ReturnOrderId">
                  {() => {
                    if (userInfo?.Roles !== "Credit+Manager") {
                      return <Redirect to="/" />;
                    } else {
                      return (
                        <>
                          <CreditManagerHeader />
                          <CMReturnDetails />
                          <CreditManagerFooter />
                        </>
                      );
                    }
                  }}
                </Route>

                <Route path="/CM">
                  {() => {
                    if (userInfo?.Roles !== "Credit+Manager") {
                      return <Redirect to="/" />;
                    } else {
                      return (
                        <>
                          <CreditManagerHeader />
                          <CreditManager />
                          <CreditManagerFooter />
                        </>
                      );
                    }
                  }}
                </Route>

                <Route path="/ReturnProcess">
                  {() => {
                    if (userInfo === undefined) {
                      return <Redirect to="/" />;
                    } else {
                      return (
                        <>
                          <Header />
                          <ReturnProcess />
                          <Footer />
                        </>
                      );
                    }
                  }}
                </Route>

                <Route path="/">
                  <Login />
                </Route>
              </Switch>
            </div>
          </SessionContext.Provider>
        </UserContext.Provider>
      </LoadingContext.Provider>
    </BrowserRouter>
  );
}

export default ReturnWebApp;
