import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Button, Form, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";

import CalculatorApiClient from "../ApiClients/CalculatorApi";
import { SkruvatCheckbox } from "./SkruvatComponents";
import { LoadingContext } from "../Context";
import { IsStringDecimal } from "../SkruvatHelperFunctions";

export default function Calculator({
  ReturnOrderId,
  OnTotalOrderAmountChanged,
}) {
  const { t, i18n } = useTranslation("common");
  const [calculatorDetails, setCalculatorDetails] = useState({});
  const [calculatorRows, setCalculatorRows] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const loader = useContext(LoadingContext);
  var history = useHistory();

  useEffect(() => {
    GetCalculatorRecords();
  }, []);

  useEffect(() => {
    CalculateTotals();
  }, [calculatorRows]);

  return (
    <>
      <Alert variant="success" hidden={successMessage.length === 0}>
        {successMessage}
      </Alert>
      <Alert variant="danger" hidden={errorMessage.length === 0}>
        {errorMessage}
      </Alert>
      <table>
        <thead>
          <tr className="font-size14">
            <th>S.No</th>
            <th>{t("CalculatorColumns.CC_STATUS")}</th>
            <th>{t("CalculatorColumns.CC_ISREDUCTION")}</th>
            <th>{t("CalculatorColumns.CC_DESCRIPTION")}</th>
            <th>{t("CalculatorColumns.CC_AMOUNT")}</th>
            <th>{t("CalculatorColumns.CC_QUANTITY")}</th>
            <th>{t("CalculatorColumns.CC_TOTALROW")}</th>
          </tr>
        </thead>
        <tbody className="font-size14">
          {CalculatorRows(calculatorDetails, calculatorRows)}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="5">
              <div hidden={!IsCalculatorActionAllowed()}>
                <Button
                  size="sm"
                  variant="success"
                  onClick={SaveCalculatorData}
                >
                  {t("Calculator.SaveAndCalculate")}{" "}
                  <FontAwesomeIcon icon={faSave} />
                </Button>{" "}
                <Button
                  size="sm"
                  variant="success"
                  hidden={
                    calculatorDetails.CalcId == null ||
                    calculatorDetails.CalcId === "" ||
                    calculatorDetails.UserRole !== "ReturnManager" ||
                    calculatorDetails.ReturnOrderStateId === 0 ||
                    calculatorDetails.ReturnOrderStateId === 8
                  }
                  onClick={AuthorizeByChangeStatus}
                >
                  {t("Calculator.Authorize")}
                </Button>{" "}
                <Button
                  size="sm"
                  variant="outline-secondary"
                  onClick={GetCalculatorRecords}
                >
                  Cancel
                </Button>
              </div>
            </td>
            <td colSpan="1">
              <strong>TOTAL :</strong>
            </td>
            <td colSpan="1" align="right">
              <strong>
                {calculatorDetails.TotalAmount}
                {calculatorDetails.Currency === "SEK"
                  ? " kr"
                  : " " + calculatorDetails.Currency}
              </strong>
            </td>
          </tr>
        </tfoot>
      </table>
    </>
  );

  function CalculatorRows(Details, Rows) {
    if (Details === undefined || Rows === undefined) return null;

    return Rows.map((row, index) => {
      return (
        <tr key={index}>
          <td className="alignCenter">{row.RowIndex + 1}</td>
          <td className="alignCenter">{row.Status}</td>
          <td className="alignCenter">
            <SkruvatCheckbox
              checked={row.IsReduction}
              name="IsReduction"
              disabled={row.IsReductionHidden}
              onChange={(e) => {
                HandleChange(index, e);
              }}
            />
          </td>
          <td>
            {row.Status === 0 ? (
              <div dangerouslySetInnerHTML={{ __html: row.Description }} />
            ) : (
              <>{row.Description}</>
            )}
          </td>
          <td>
            {row.Status === 0 ? (
              row.AmountPerItem +
              (Details.Currency === "SEK" ? " kr" : " " + Details.Currency)
            ) : (
              <Form.Control
                size="sm"
                type="text"
                name="AmountPerItem"
                value={row.AmountPerItem ?? 0}
                onFocus={(e) => e.target.select()}
                onChange={(e) => {
                  HandleChange(index, e);
                }}
              />
            )}
          </td>
          <td>{row.Quantity}</td>
          <td className="alignRight">
            {row.TotalRowAmount}{" "}
            {Details.Currency === "SEK" ? " kr" : " " + Details.Currency}
          </td>
        </tr>
      );
    });
  }

  function HandleChange(index, e) {
    const target = e.target; // Need to persist the event since setState is async.
    var shouldUpdate = true;

    if (target.name === "AmountPerItem") {
      // Only allow decimal
      target.value = target.value.replace(",", ".");
      shouldUpdate = IsStringDecimal(target.value);
    }

    setCalculatorRows((prevRows) => {
      let newRows = [...prevRows];
      if (shouldUpdate) newRows[index][target.name] = target.value;
      return newRows;
    });
  }

  function IsCalculatorActionAllowed() {
    return (
      (calculatorDetails.UserRole === "ReturnManager" ||
        calculatorDetails.UserRole === "RefundConfirmation") &&
      calculatorDetails.ReturnOrderStateId !== 3
    );
  }

  function CalculateTotals() {
    var updated = false;
    var totalAmount = 0;

    calculatorRows.forEach((row) => {
      if (row.AmountPerItem === "") {
        row.AmountPerItem = "0";
        updated = true;
      }
      if (row.Quantity === "") {
        row.Quantity = "0";
        updated = true;
      }
      if (
        row.TotalRowAmount !== (row.AmountPerItem * row.Quantity).toFixed(2)
      ) {
        row.TotalRowAmount = (row.AmountPerItem * row.Quantity).toFixed(2);
        updated = true;
      }

      totalAmount += (row.IsReduction ? -1 : 1) * row.TotalRowAmount;
    });

    if (updated) setCalculatorRows([...calculatorRows]);
    if (calculatorDetails.TotalAmount !== totalAmount)
      setCalculatorDetails((prevDetails) => {
        return { ...prevDetails, TotalAmount: totalAmount.toFixed(2) };
      });
  }

  function GetCalculatorRecords() {
    loader.updateShowLoader(true);
    ClearErrorAndSuccessMessages();

    CalculatorApiClient.GetCalculatorDetails(ReturnOrderId)
      .then(
        (result) => {
          // Translate descriptions if possible
          result.Rows.forEach((element) => {
            if (i18n.exists("common:" + element.Description))
              element.Description = t(element.Description);
          });

          setCalculatorRows(result.Rows);
          delete result.Rows;

          setCalculatorDetails(result);
        },
        () => {
          setErrorMessage(t("Error.GeneralError"));
        }
      )
      .finally(() => {
        loader.updateShowLoader(false);
      });
  }

  function ClearErrorAndSuccessMessages() {
    setErrorMessage("");
    setSuccessMessage("");
  }

  function SaveCalculatorData() {
    loader.updateShowLoader(true);
    ClearErrorAndSuccessMessages();

    CalculatorApiClient.SaveCalculatorData(ReturnOrderId, calculatorRows)
      .then(
        (result) => {
          if (result.success) {
            setCalculatorDetails((prev) => {
              return { ...prev, CalcId: result.content.calc.CalcId };
            });
            setSuccessMessage(t("General.UpdateMessage"));
            OnTotalOrderAmountChanged(calculatorDetails.TotalAmount);
          }
        },
        () => {
          setErrorMessage(t("Error.GeneralError"));
        }
      )
      .finally(() => {
        loader.updateShowLoader(false);
      });
  }

  function AuthorizeByChangeStatus() {
    ClearErrorAndSuccessMessages();
    SaveCalculatorData();
    CalculatorApiClient.AuthorizeByChangeStatus(ReturnOrderId).then(
      (result) => {
        if (result.success) {
          history.goBack();
        } else {
          setErrorMessage(t("Error.GeneralError"));
        }
      },
      () => {
        setErrorMessage(t("Error.GeneralError"));
      }
    );
  }
}
