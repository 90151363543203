import React from "react";
import { Row, Col, Container, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation("common");

  return (
    <Container fluid className="footer paddingBefore10">
      <Row>
        <Col>
          <Container>
            <Row>
              <Col xs="7" md="8" lg="9">
                {t("FooterPublic.FooterPublicLeft1")} <br />
                {t("FooterPublic.FooterPublicLeft2")}
              </Col>
              <Col xs="5" md="4" lg="3" className="justify-content-md-center">
                <Image
                  src="https://az768999.vo.msecnd.net/images/content/logo/visa.png"
                  alt="Verified by Visa"
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Image
                  src="https://az768999.vo.msecnd.net/images/content/logo/mastercard.png"
                  alt="Mastercard. SecureCode."
                />
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
