import React, { useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";

import ReturnManagerApiClient from "../ApiClients/ReturnManagerApiClient";
import FilterComponent from "./FilterComponent";
import ListReturnOrders from "./ListReturnOrders";

function ReturnManager() {
  const [returnStatusList, setReturnStatusList] = useState([]);
  const [returnStatus, setReturnStatus] = useLocalStorage("returnStatusRM", "99");
  const [country, setCountry] = useLocalStorage("country", "");
  const [sortDirection, setSortDirection] = useLocalStorage("sortDirectionRM", false);
  const [filter, setFilter] = useState({
    pageNumber: "1",
    statusId: returnStatus,
    country: country,
    orderId: "",
    returnManagerId: "",
    sortKey: "Created",
    sortDirection: sortDirection,
    startsWith: "",
  });

  useEffect(() => {
    async function GetReturnStatuses() {
      return await ReturnManagerApiClient.GetAllReturnStatus();
    }

    GetReturnStatuses().then((result) => setReturnStatusList(result));
  }, []);

  useEffect(() => {
    
    setReturnStatus(filter.statusId);
    setCountry(filter.country);
    setSortDirection(filter.sortDirection);
  }, [filter.statusId, filter.country, filter.sortDirection]);

  return (
    <Container className="paddingBefore20">
      <Card>
        <Card.Header>
          <FilterComponent
            ReturnStatusList={returnStatusList}
            Filter={filter}
            UpdateFilter={setFilter}
          />
        </Card.Header>
        <Card.Body>
          <ListReturnOrders
            ReturnStatusList={returnStatusList}
            Filter={filter}
            UpdateFilter={setFilter}
          />
        </Card.Body>
      </Card>
    </Container>
  );

  function useLocalStorage(key, initialValue) {
    const [storedValue, setStoredValue] = useState(() => {
      try {
        const item = window.localStorage.getItem(key);
        return item ? JSON.parse(item) : initialValue;
      } catch (error) {
        console.log(error);
        return initialValue;
      }
    });
    const setValue = (value) => {
      try {
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;
        setStoredValue(valueToStore);
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      } catch (error) {
        console.log(error);
      }
    };
    return [storedValue, setValue];
  }
}

export default ReturnManager;
