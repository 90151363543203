import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Jumbotron,
  Button,
  Form,
  Alert,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";

import ReturnWebClient from "./ApiClients/ReturnWebClient";

function Login() {
  const { t } = useTranslation("common");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [loginError, setLoginError] = useState("");
  const [token, setToken] = useState("");

  useEffect(() => {
    ReturnWebClient.Logout().then(() => {
      ReturnWebClient.GetLoginToken().then((res) => setToken(res));
    });
  }, []);

  return (
    <div className="bodyimage">
      <Container>
        <Row className="mt-5">
          <Col></Col>
          <Col xs="auto" md="6">
            <h2 className="font-size48 text-white text-center">
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              {t("LogOn.LogOnHeading")}
            </h2>
            <br />
            <br />
            <h3 className="font-size22 text-white text-center">
              {t("LogOn.LogOnSubheading")}
              <br />
              <br />
              <a
                className="link-white"
                href={t("LogOn.Register")}
                style={{ overflowWrap: "break-word" }}
              >
                {t("LogOn.Register")}
              </a>
            </h3>
          </Col>
          <Col></Col>
        </Row>
        <Row className="mt-3">
          <Col></Col>
          <Col xs="auto" md="6">
            <Jumbotron>
              <Alert variant="danger" hidden={loginError === ""}>
                {loginError}
              </Alert>
              <Alert variant="danger" hidden={Object.keys(errors).length === 0}>
                <ul>
                  {Object.keys(errors).map((obj, i) => (
                    <li key={i}>{t(errors[obj])}</li>
                  ))}
                </ul>
              </Alert>
              <Form.Control
                type="email"
                placeholder="Email"
                value={email}
                onChange={HandleEmailControlChange}
                onBlur={ValidateEmail}
                onKeyUp={(e) => {
                  if (e.key === "Enter") HandleLoginRequest();
                }}
              />
              <br />
              <Form.Control
                type="password"
                placeholder="Password"
                value={password}
                onChange={HandlePasswordControlChange}
                onBlur={ValidatePassword}
                onKeyUp={(e) => {
                  if (e.key === "Enter") HandleLoginRequest();
                }}
              />

              <br />
              <Button
                variant="success"
                type="submit"
                block
                disabled={email.length === 0 || Object.keys(errors).length > 0}
                onClick={HandleLoginRequest}
              >
                Login
              </Button>
              <br />
              <a
                className="link-white"
                href={
                  "https://www." +
                  getDomain(t("LogOn.Register")) +
                  "/ForgotPassword.aspx"
                }
              >
                {t("LogOn.ForgottenPassword")}
              </a>
            </Jumbotron>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    </div>
  );

  function HandleEmailControlChange(event) {
    setEmail(event.target.value);
    ValidateEmail(false);
  }

  function HandlePasswordControlChange(event) {
    setPassword(event.target.value);
    ValidatePassword(false);
  }

  function HandleLoginRequest() {
    setLoginError("");
    ReturnWebClient.Login(email, password, token)
      .then((res) => {
        if (res.Success) {
          window.location.href = "/return" + res.RedirectPath;
        } else {
          setLoginError("Authentication failed");
        }
      })
      .catch((err) => {
        setLoginError("Authentication failed");
      });
  }

  function ValidateEmail(showError = true) {
    if (email.length < 1 && showError) {
      setErrors((err) => {
        return { ...err, email: "LogOn.reqEmail" };
      });
      return;
    }

    if (
      !/^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
        email
      ) &&
      showError
    ) {
      setErrors((err) => {
        return { ...err, email: "LogOn.invalidEmail" };
      });
      return;
    }

    setErrors((err) => {
      if (err.email) delete err.email;
      return { ...err };
    });
  }

  function ValidatePassword(showError = true) {
    if (password.length < 3 && showError) {
      setErrors((err) => {
        return { ...err, password: "LogOn.reqPwd" };
      });
      return;
    }

    setErrors((err) => {
      if (err.password) delete err.password;
      return { ...err };
    });
  }
}

function getDomain(url) {
  url = url.replace(/(https?:\/\/)?(www.)?/i, "");
  if (url.indexOf("/") !== -1) {
    return url.split("/")[0];
  }
  return url;
}

export default Login;
