import axios from "axios";
import config from "../config.json";

class ReturnManagerApiClient {
  GetAllOrders = (Filter) => {
    return new Promise(function (resolve) {
      fetch(
        config.RETURNWEB_API +
          `/ReturnManager/GetReturnOrders?pageNumber=${Filter.pageNumber}&statusid=${Filter.statusId}&country=${Filter.country}&orderId=${Filter.orderId}&returnManagerId=${Filter.returnManagerId}&sortKey=${Filter.sortKey}&sortDirection=${Filter.sortDirection}&startsWith=${Filter.startsWith}`,
        {
          credentials: "include",
        }
      )
        .then((res) => res.json())
        .then((result) => {
          return resolve(result);
        })
        .catch(() => {
          console.log("Error fetching order data.");
          return resolve([]);
        });
    });
  };

  GetReturnOrderMoreDetail = (ReturnOrderid) => {
    return new Promise(function (resolve) {
      fetch(
        config.RETURNWEB_API +
          `/ReturnManager/GetReturnOrderMoreDetail?id=${ReturnOrderid}`,
        {
          credentials: "include",
        }
      )
        .then((res) => res.json())
        .then((result) => {
          return resolve(result);
        })
        .catch(() => {
          console.log("Error fetching return order data.");
          return resolve([]);
        });
    });
  };

  GetActivitiesforReturnManager = (ReturnOrderid) => {
    return new Promise(function (resolve) {
      fetch(
        config.RETURNWEB_API +
          `/ReturnManager/GetActivitiesforReturnManager?returnOrderId=${ReturnOrderid}`,
        {
          credentials: "include",
        }
      )
        .then((res) => res.json())
        .then((result) => {
          return resolve(result);
        })
        .catch(() => {
          console.log("Error fetching return order data.");
          return resolve([]);
        });
    });
  };

  GetReturnManagers = () => {
    return new Promise(function (resolve) {
      fetch(config.RETURNWEB_API + "/ReturnManager/GetAllReturnManagers", {
        credentials: "include",
      })
        .then((res) => res.json())
        .then((result) => {
          return resolve(result);
        })
        .catch(() => {
          console.log("Error fetching data.");
          return resolve();
        });
    });
  };

  GetAllReturnStatus = () => {
    return new Promise(function (resolve) {
      fetch(
        config.RETURNWEB_API +
          "/ReturnManager/GetAllReturnStatusesforReturnManager",
        {
          credentials: "include",
        }
      )
        .then((res) => res.json())
        .then((result) => {
          return resolve(result);
        })
        .catch(() => {
          console.log("Error fetching data.");
          return resolve();
        });
    });
  };

  GetReturnReasonIdentities = () => {
    return new Promise(function (resolve) {
      fetch(
        config.RETURNWEB_API + "/ReturnManager/GetReturnReasonIdentitiesTextRM",
        {
          credentials: "include",
        }
      )
        .then((res) => res.json())
        .then((result) => {
          return resolve(result);
        })
        .catch(() => {
          console.log("Error fetching data.");
          return resolve();
        });
    });
  };

  GetReturnReasonSpecs = () => {
    return new Promise(function (resolve) {
      fetch(config.RETURNWEB_API + "/ReturnManager/GetReturnReasonSpecText", {
        credentials: "include",
      })
        .then((res) => res.json())
        .then((result) => {
          return resolve(result);
        })
        .catch(() => {
          console.log("Error fetching data.");
          return resolve();
        });
    });
  };

  GetReturnPolicies = () => {
    return new Promise(function (resolve) {
      fetch(config.RETURNWEB_API + "/ReturnManager/GetReturnPoliciesText", {
        credentials: "include",
      })
        .then((res) => res.json())
        .then((result) => {
          return resolve(result);
        })
        .catch(() => {
          console.log("Error fetching data.");
          return resolve();
        });
    });
  };

  GetActivityAndEmailLogs = (ReturnOrderid) => {
    return new Promise(function (resolve) {
      fetch(
        config.RETURNWEB_API +
          `/ReturnManager/GetRMActivityLogs?returnOrderId=${ReturnOrderid}`,
        {
          credentials: "include",
        }
      )
        .then((res) => res.json())
        .then((result) => {
          return resolve(result);
        })
        .catch(() => {
          console.log("Error fetching data.");
          return resolve();
        });
    });
  };

  SetActivityApproveDeclineText = (ReturnOrderId, Id, DeclineText, Images) => {
    var data = JSON.stringify({
      returnOrderId: ReturnOrderId,
      activityId: Id,
      text: DeclineText,
      DeclineReportUrl: Images.map((img) => {
        return img.Url;
      }).join(" "),
    });

    return new Promise(function (resolve, reject) {
      fetch(
        config.RETURNWEB_API + `/ReturnManager/SetActivityApproveDeclineText`,
        {
          credentials: "include",
          method: "POST",
          body: data,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((result) => {
          return resolve(result);
        })
        .catch(() => {
          reject("Error when declining order");
        });
    });
  };

  SetActivityCancelCase = (ReturnOrderId) => {
    var data = JSON.stringify({
      returnOrderId: ReturnOrderId,
    });

    return new Promise(function (resolve, reject) {
      fetch(config.RETURNWEB_API + `/ReturnManager/SetActivityCancelCase`, {
        credentials: "include",
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.success) return resolve(result);

          reject("Error when cancelling");
        })
        .catch(() => {
          reject("Error when cancelling");
        });
    });
  };

  UndoReturnHistoryByOrderTag = (ReturnOrderId) => {
    var data = JSON.stringify({
      returnOrderId: ReturnOrderId,
    });

    return new Promise(function (resolve, reject) {
      fetch(
        config.RETURNWEB_API + `/ReturnManager/UndoReturnHistoryByOrderTag`,
        {
          credentials: "include",
          method: "POST",
          body: data,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((result) => {
          if (result.success) return resolve(result);

          reject("Error undoing activity");
        })
        .catch(() => {
          reject("Error undoing activity");
        });
    });
  };

  ChangeStatus = (ReturnOrderId, state, manualTa, manualTrackingNo) => {
    var data = JSON.stringify({
      returnOrderId: ReturnOrderId,
      state: state,
      IsManualTa: manualTa,
      ManualTrackingNo: manualTrackingNo,
    });

    return new Promise(function (resolve, reject) {
      fetch(config.RETURNWEB_API + `/ReturnManager/ChangeStatus`, {
        credentials: "include",
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((result) => {
          return resolve(result);
        })
        .catch(() => {
          reject("Error changing status");
        });
    });
  };

  UpdateReturnOrderEditDetail = (ReturnOrder) => {
    var data = JSON.stringify(ReturnOrder);

    return new Promise(function (resolve, reject) {
      fetch(
        config.RETURNWEB_API + `/ReturnManager/UpdateReturnOrderEditDetail`,
        {
          credentials: "include",
          method: "POST",
          body: data,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((result) => {
          if (result.success) {
            return resolve(result);
          } else {
            reject(result.errors);
          }
        })
        .catch(() => {
          reject("Error updating data");
        });
    });
  };

  UpdateReturnOrderIssues = (ReturnOrder) => {
    ReturnOrder.ReturnIssues.forEach((issue) => {
      issue.Quantity = issue.ReturnQuantity;

      if (!issue.RetIssueStateId) issue.RetIssueStateId = 1;
      if (!issue.RetReasonIdentityId) issue.RetReasonIdentityId = 1;
      if (!issue.RetReasonSpecId) issue.RetReasonSpecId = 1;
      if (!issue.ReturnReasonOtherText) issue.ReturnReasonOtherText = "na"; // $scope.TitleDetail;
      if (!issue.ReturnIssueProductName)
        issue.ReturnIssueProductName = issue.PublicProduct.Name;
      if (!issue.ReturnIssueProductId)
        issue.ReturnIssueProductId = issue.PublicProduct.ProductId;
      if (!issue.ReturnIssueProductSku)
        issue.ReturnIssueProductSku = issue.PublicProduct.Sku;
      if (!issue.ReturnIssueProductTireRimSku)
        issue.ReturnIssueProductTireRimSku = issue.PublicProduct.TireSku
          ? issue.PublicProduct.RimSku
            ? issue.PublicProduct.TireSku + " / " + issue.PublicProduct.RimSku
            : issue.PublicProduct.TireSku
          : "";
    });

    var data = JSON.stringify(ReturnOrder);

    return new Promise(function (resolve, reject) {
      fetch(config.RETURNWEB_API + `/ReturnManager/UpdateReturnOrderIssues`, {
        credentials: "include",
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.success) {
            return resolve(result);
          } else {
            reject(result.errors);
          }
        })
        .catch(() => {
          reject("Error updating data");
        });
    });
  };

  SaveFileEdit = (file, folderName, updateProgress) => {
    return this.SaveFile(file, folderName, updateProgress, true);
  };

  SaveFile = (file, folderName, updateProgress, isEdit = false) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", "");
    formData.append("dirName", folderName);
    formData.append("IsEdit", isEdit);

    return axios
      .request({
        method: "post",
        url: config.RETURNWEB_API + "/CustomerService/SaveFiles",
        data: formData,
        onUploadProgress: (progress) => {
          updateProgress(Math.round((progress.loaded / progress.total) * 100));
        },
      })

      .then((response) => {
        return {
          Success: response.data.success,
          FileName: response.data.success
            ? response.data.content.imageFileName
            : null,
          FolderName: response.data.success
            ? response.data.content.imagefolderName
            : null,
          FileType: response.data.success
            ? response.data.content.FileType
            : null,
          Url: response.data.success
            ? response.data.content.imageFullPath
            : null,
          Errors: response.data.errors,
        };
      })
      .catch(() => {
        console.log("Error uploading file.");
      });
  };

  RemoveFile = (url) => {
    var fileNameArray = url.split("/");
    var imageName = fileNameArray[fileNameArray.length - 1];
    var dirName = fileNameArray[fileNameArray.length - 2];

    return new Promise(function (resolve) {
      fetch(
        config.RETURNWEB_API +
          "/CustomerService/RemoveImage?foldername=" +
          dirName +
          "&imageName=" +
          imageName,
        { credentials: "include" }
      )
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          console.log("Error removing file.");
          return resolve();
        });
    });
  };

  CSGetAllOrders = (UserId) => {
    return new Promise(function (resolve, reject) {
      fetch(
        config.RETURNWEB_API + "/CustomerService/GetAllOrders?userid=" + UserId,
        { credentials: "include" }
      )
        .then((res) => {
          if (res.status === 200) {
            res.json().then(resolve);
          } else {
            reject("Error fetching customer orders");
          }
        })

        .catch(() => {
          reject("Error fetching customer orders");
        });
    });
  };

  GetOrderItems = (OrderId) => {
    return new Promise(function (resolve, reject) {
      fetch(
        config.RETURNWEB_API +
          "/CustomerService/GetOrderItems?orderId=" +
          OrderId,
        { credentials: "include" }
      )
        .then((res) => {
          if (res.status === 200) {
            res.json().then(resolve);
          } else {
            reject("Error fetching customer order items");
          }
        })

        .catch(() => {
          reject("Error fetching customer order items");
        });
    });
  };

  SaveReturnOrder = (
    order,
    returnReason,
    returnDescription,
    language,
    specificReason = "REFUND_ONLY",
    setValuesString = "na",
    directoryName = ""
  ) => {
    return new Promise(function (resolve, reject) {
      let OrderId = order.OrderId;

      var retObj = {
        ReturnIssues: order.OrderItems.map((issue) => {
          return {
            RetOrderId: 0,
            OrderId: OrderId,
            RetIssueStateId: 1,
            OrderItemId: issue.OrderItemId,
            ProductId: [],
            Created: "na",
            LastModified: "na",
            UpdaterId: 0,
            Quantity: issue.ReturnQuantity, // Så här är det i returwebben idag (customer flödet), men ska kanske sättas till issue.Quantity egentligen?
            QuantityIncrementalStep: "",
            ReturnQuantity: issue.ReturnQuantity,
            RetReasonIdentityId: 1,
            RetReasonSpecId: 1,
            ReturnReasonOtherText: "na", // $scope.TitleDetail;
            ReturnIssueProductName: issue.PublicProduct.Name,
            ReturnIssueProductId: issue.PublicProduct.ProductId,
            ReturnIssueProductPrice: issue.PublicProduct.Price,
            ReturnIssueProductSku: issue.PublicProduct.Sku,
            ReturnIssueProductTireRimSku: issue.PublicProduct.TireSku
              ? issue.PublicProduct.RimSku
                ? issue.PublicProduct.TireSku +
                  " / " +
                  issue.PublicProduct.RimSku
                : issue.PublicProduct.TireSku
              : "",
          };
        }),

        RetOrderTag: "0",
        RetOrderStateId: 1,
        Created: "na",
        OwnerId: "0",
        LastModified: "na",
        BIC: "",
        IBAN: "",
        SWIFT: "Sweden (sv-SE)", // Såhär är det i returwebben idag (för admin-flödet, customer baserar på användarens profil)
        OrderId: OrderId,
        ReturnReasonDescription: returnDescription,
        ReturnReasonUserCarNumber: "",
        ReturnReasonUserCarNumberInfo: "",
        CLEARING: "",
        ACCOUNT: "",
        BANKNAME: "",
        TransactionId: "0",
        SetValuesString: setValuesString,
        OrderOwnerNameAndEmail: "na",
        DecliningComment: "na",
        ReturnReasonRefundTypeId: 1,
        OrderDate: order.OrderDate,
      };

      var UserDataDataString = JSON.stringify({
        returnOrder: retObj,
        detailedReason: returnReason,
        specificReason: specificReason,
        dirName: directoryName,
      });

      fetch(config.RETURNWEB_API + "/CustomerService/SaveReturnOrder", {
        credentials: "include",
        method: "POST",
        body: UserDataDataString,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((result) => {
          return resolve(result);
        })
        .catch(() => {
          resolve({ Error: "Error saving return order." });
        });
    });
  };

  ResendToAc = (ReturnOrderId) => {
    var data = JSON.stringify({
      returnOrderId: ReturnOrderId,
    });

    return new Promise(function (resolve, reject) {
      fetch(config.RETURNWEB_API + `/ReturnManager/ResendToAc`, {
        credentials: "include",
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.success) return resolve(result);

          reject(["Error resending order to AC. ", ...result.errors]);
        })
        .catch(() => {
          reject("Error resending order to AC");
        });
    });
  };
}

export default new ReturnManagerApiClient();
