import React from "react";
import { Button, Table, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { SkruvatCheckbox } from "../Components/SkruvatComponents";
import useWindowSize from "../CustomHooks/useWindowSize";

export default function CustomerOrder({
  Order,
  HandleOrderItemsChange,
  HandleNextStep,
}) {
  const { t } = useTranslation("common");
  const { isMobile } = useWindowSize();

  if (Order.OrderItems === undefined || Order.OrderItems.length === 0)
    return null;

  var isAnyProductAlreadyReturned = Order.OrderItems.some(
    (e) => e.Quantity === 0
  );
  var isWholeOrderAlreadyReturned = Order.OrderItems.every(
    (e) => e.Quantity === 0
  );
  var isAnyItemSelected = !Order.OrderItems.some((e) => e.selected);

  return (
    <React.Fragment>
      <Row>
        <Col>
          <div className="font-size22">
            {t("Product.ProductSelectionText1")}
          </div>
          <div className="font-size14 paddingBefore10">
            {t("Product.ProductSelectionText2")}
          </div>
          <div className="font-size14">
            {t("Product.ProductSelectionText3")}
          </div>
          <div className="font-size14">
            {t("Product.ProductSelectionText4")}
          </div>
          {isAnyProductAlreadyReturned && !isWholeOrderAlreadyReturned && (
            <div className="font-size14 bold">
              {t("Order.ReturnProductNote")}
            </div>
          )}

          {isWholeOrderAlreadyReturned && (
            <div className="font-size14 bold">
              <br /> {t("Order.ReturnedWholeOrderText")}
            </div>
          )}
        </Col>
      </Row>
      {!isWholeOrderAlreadyReturned && (
        <div>
          <Row>
            <Col>
              <hr />
            </Col>
          </Row>

          <Row>
            <Col xs="7" className="font-size22">
              {t("Order.Order")} - {Order.OrderId}
            </Col>
            <Col xs="5" className="inline checkboxLabel alignRight">
              {t("Order.Entireorder")}{" "}
              <SkruvatCheckbox
                id="allItemsCheckbox"
                item="allItemsCheckbox"
                checked={Order.OrderItems.every(
                  (e) => e.Quantity === e.ReturnQuantity
                )}
                onChange={SelectAllOrderItems}
              />
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col>
              {isMobile ? (
                <OrderItemsComponentMobile orderItems={Order.OrderItems} />
              ) : (
                <OrderItemsComponent orderItems={Order.OrderItems} />
              )}
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col xs lg="2">
              <Button
                variant="primary"
                onClick={ContinueButtonClick}
                disabled={isAnyItemSelected}
              >
                {t("General.Continued")}
              </Button>
            </Col>
          </Row>
        </div>
      )}
    </React.Fragment>
  );

  function SelectAllOrderItems() {
    var allChecked = Order.OrderItems.every(
      (e) => e.Quantity === e.ReturnQuantity
    );

    Order.OrderItems.forEach((item) => {
      if (item.Quantity <= 0) return;
      item.selected = !allChecked;
      item.ReturnQuantity = item.selected ? item.Quantity : 0;
    });

    HandleOrderItemsChange(Order.OrderItems);
  }

  function ContinueButtonClick(e) {
    HandleNextStep();
  }

  function CheckboxClick(event) {
    var findObj = Order.OrderItems.findIndex(
      (o) => o.OrderItemId === event.target.name
    );

    if (Order.OrderItems[findObj].Quantity <= 0) return;

    Order.OrderItems[findObj].selected = !Order.OrderItems[findObj].selected;
    Order.OrderItems[findObj].ReturnQuantity = Order.OrderItems[findObj]
      .selected
      ? Order.OrderItems[findObj].Quantity
      : 0;

    HandleOrderItemsChange(Order.OrderItems);
  }

  function ReturnQuantityChange(event) {
    var findObj = Order.OrderItems.findIndex(
      (o) => o.OrderItemId === parseInt(event.target.id, 10)
    );

    Order.OrderItems[findObj].ReturnQuantity = parseInt(event.target.value, 10);
    Order.OrderItems[findObj].selected = parseInt(event.target.value, 10) > 0;

    HandleOrderItemsChange(Order.OrderItems);
  }

  function OrderItemsComponent({ orderItems }) {
    return (
      <Table>
        <thead>
          <tr>
            <th></th>
            <th>{t("OrderItems.QuantityEdit")}</th>
            <th>{t("Product.ProductName")}</th>
            <th>{t("Product.ProductId")}</th>
            <th>{t("Product.Price")}</th>
            <th align="text-right">{t("OrderItems.QuantityEdit")}</th>
          </tr>
        </thead>

        <tbody>
          {orderItems.map((order, key) => {
            return <OrderItem key={key} id={key} item={order}></OrderItem>;
          })}
        </tbody>
      </Table>
    );
  }

  function OrderItemsComponentMobile({ orderItems }) {
    return orderItems.map((order, key) => {
      return (
        <Table key={key}>
          <tbody>
            <OrderItemMobile id={key} item={order}></OrderItemMobile>
          </tbody>
        </Table>
      );
    });
  }

  function OrderItem({ id, item }) {
    const options = [];
    for (let index = 0; index < item.Quantity + 1; index++) {
      options.push(<option key={index}>{index}</option>);
    }

    return (
      <tr className={item.Quantity <= 0 ? "table-row-disable" : ""}>
        <td>
          <SkruvatCheckbox
            id={id}
            name={item.OrderItemId}
            checked={item.selected}
            onChange={CheckboxClick}
          />
        </td>
        <td>{item.Quantity}</td>
        <td>{item.Product.Name}</td>
        <td>{item.Product.ProductId}</td>
        <td>{item.Product.Price}</td>
        <td>
          <select
            id={item.OrderItemId}
            value={item.ReturnQuantity}
            onChange={ReturnQuantityChange}
          >
            {options}
          </select>
        </td>
      </tr>
    );
  }

  function OrderItemMobile({ id, item }) {
    const options = [];
    for (let index = 0; index < item.Quantity + 1; index++) {
      options.push(<option key={index}>{index}</option>);
    }

    return (
      <tr className={item.Quantity <= 0 ? "table-row-disable" : ""}>
        <td>
          <Row>
            <Col>
              <SkruvatCheckbox
                id={id}
                name={item.OrderItemId}
                checked={item.selected}
                onChange={CheckboxClick}
              />
            </Col>
            <Col>
              <span className="bold">{t("OrderItems.QuantityEdit")}</span>
              <br />
              {item.Quantity}
            </Col>
          </Row>
          <Row className="paddingBefore10">
            <Col>
              <span className="bold">{t("Product.ProductName")}</span>
              <br />
              {item.Product.Name}
            </Col>
            <Col>
              <span className="bold">{t("Product.ProductId")}</span>
              <br />
              {item.Product.ProductId}
            </Col>
          </Row>
          <Row className="paddingBefore10">
            <Col>
              <span className="bold">{t("Product.Price")}</span>
              <br />
              {item.Product.Price}
            </Col>
            <Col>
              <span className="bold">{t("OrderItems.QuantityEdit")}</span>
              <br />
              <select
                id={item.OrderItemId}
                value={item.ReturnQuantity}
                onChange={ReturnQuantityChange}
              >
                {options}
              </select>
            </Col>
          </Row>
        </td>
      </tr>
    );
  }
}
