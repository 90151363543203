import React, { useState, useEffect } from "react";
import {
  Alert,
  Container,
  Card,
  Row,
  Col,
  Form,
  Button,
  Popover,
  OverlayTrigger,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileExport,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";

import ReturnManagerApiClient from "../ApiClients/ReturnManagerApiClient";

export default function Statistics() {
  const { t } = useTranslation("common");

  const [returnManagers, setReturnManagers] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [selectedReturnManager, setSelectedReturnManager] = useState();
  const [selectedReturnReason, setSelectedReturnReason] = useState();
  const [selectedReturnStatus, setSelectedReturnStatus] = useState();
  const [statisticsFromDate, setStatisticsFromDate] = useState();
  const [statisticsToDate, setStatisticsToDate] = useState();
  const [
    statisticsSecondaryFromDate,
    setStatisticsSecondaryFromDate,
  ] = useState();
  const [statisticsSecondaryToDate, setStatisticsSecondaryToDate] = useState();
  const [returnReasons, setReturnReasons] = useState([]);
  const [returnStatuses, setReturnStatuses] = useState([]);

  useEffect(() => {
    async function GetReturnManagers() {
      return await ReturnManagerApiClient.GetReturnManagers();
    }
    async function GetReturnReasonIdentities() {
      return await ReturnManagerApiClient.GetReturnReasonIdentities();
    }
    async function GetAllReturnStatus() {
      return await ReturnManagerApiClient.GetAllReturnStatus();
    }

    GetReturnManagers().then((result) => {
      setReturnManagers(result.content);
    });
    GetReturnReasonIdentities().then((result) => {
      setReturnReasons(result);
    });
    GetAllReturnStatus().then((result) => {
      setReturnStatuses(result);
    });
  }, []);

  return (
    <Container className="whiteContainer mobileResponsiveMarginFix paddingBefore20 paddingAfter20">
      <div className="font-size22">
        {t("Menu.Statistics")}
        <br />
        <br />
      </div>
      <Alert
        hidden={errorMsg.length === 0}
        variant="danger"
        onClose={() => setErrorMsg("")}
        dismissible
      >
        {errorMsg}
      </Alert>
      <Card>
        <Card.Header>
          <Row>
            <Col>
              <span className="font-size14 bold">
                {t("ReturnManager.Statistics_ReturnManager")}
              </span>
              <Form.Control
                as="select"
                size="sm"
                value={selectedReturnManager}
                onChange={(e) => setSelectedReturnManager(e.target.value)}
              >
                <option value="">{t("ReturnManager.AllReturnManagers")}</option>
                {returnManagers.map((rm, id) => {
                  return (
                    <option key={id} value={rm.UserId}>
                      {rm.FullName}
                    </option>
                  );
                })}
              </Form.Control>
            </Col>
            <Col>
              <span className="font-size14 bold">
                {t("ReturnManager.Statistics_DateFrom")}
              </span>
              <br />
              <DatePicker
                placeholderText={t("ReturnManager.Statistics_SelectfromDate")}
                maxDate={new Date()}
                selected={statisticsFromDate}
                onChange={(date) => setStatisticsFromDate(date)}
                dateFormat="yyyy-MM-dd"
              />
            </Col>
            <Col>
              <span className="font-size14 bold">
                {t("ReturnManager.Statistics_DateTo")}
              </span>
              <br />
              <DatePicker
                placeholderText={t("ReturnManager.Statistics_SelecttoDate")}
                minDate={statisticsFromDate}
                selected={statisticsToDate}
                onChange={(date) => setStatisticsToDate(date)}
                dateFormat="yyyy-MM-dd"
              />
            </Col>
            <Col></Col>
          </Row>
          <Row className="paddingBefore20">
            <Col className="font-size14">
              <b> Notes:</b>
              <br />
              For all exports, selecting Return Manager is optional. It will
              only be useful for the "Export statistics" report.
              <br />
              For all exports, selecting To date and From date is optional. If
              not selected, the export will extract data from one year back for
              all except daily statistics for production. By default daily
              statistics will get report of yesterday.
              <br />
            </Col>
          </Row>
          <Row className="paddingBefore20">
            <Col xs="auto">
              <Button
                size="sm"
                onClick={() =>
                  window.open(
                    "/Statistics/ExportStatisticsReport?SWIFT=" +
                      FormatDateOrEmptyString(statisticsFromDate) +
                      "&DateFrom=" +
                      FormatDateOrEmptyString(statisticsFromDate) +
                      "&DateTo=" +
                      FormatDateOrEmptyString(statisticsToDate),
                    "_blank"
                  )
                }
              >
                <FontAwesomeIcon icon={faFileExport} /> Export statistics
              </Button>
              <br />
              <OverlayTrigger
                placement="bottom"
                delay={{ hide: 400 }}
                overlay={
                  <Popover>
                    <Popover.Content>
                      Export statistics contains following information: <br />
                      1. Returns with status [status], Per country, Per company,
                      Total no of records.
                      <br /> All this within time range one year or as selected
                      before export.
                      <br />
                      2. Returns with return reason [reason], Per country, Per
                      company, Replacement (yes/no), Total no of records.
                      <br /> All this within time range one year or as selected
                      before export.
                      <br />
                      3. Total amount credited, Per country, Per company.
                      <br /> All this within time range one year or as selected
                      before export. <br />
                    </Popover.Content>
                  </Popover>
                }
              >
                <FontAwesomeIcon icon={faQuestionCircle} />
              </OverlayTrigger>
            </Col>
            <Col xs="auto">
              <Button
                size="sm"
                onClick={() =>
                  window.open(
                    "/Statistics/ExportHandlerReport?SWIFT=" +
                      FormatDateOrEmptyString(statisticsFromDate) +
                      "&DateFrom=" +
                      FormatDateOrEmptyString(statisticsFromDate) +
                      "&DateTo=" +
                      FormatDateOrEmptyString(statisticsToDate),
                    "_blank"
                  )
                }
              >
                <FontAwesomeIcon icon={faFileExport} /> Export return handler
                list
              </Button>
              <br />
              <OverlayTrigger
                placement="bottom"
                delay={{ hide: 400 }}
                overlay={
                  <Popover>
                    <Popover.Content>
                      Export return handler list
                      <br />
                      Nr of returns credited, Per country, Per Company, Per
                      Return Manager, + Per Return Manager Id, Within time range
                      (selected before export) <br />
                    </Popover.Content>
                  </Popover>
                }
              >
                <FontAwesomeIcon icon={faQuestionCircle} />
              </OverlayTrigger>
            </Col>
            <Col xs="auto">
              <Button
                size="sm"
                onClick={() =>
                  window.open(
                    "/Statistics/ExportReportForCM?DateFrom=" +
                      FormatDateOrEmptyString(statisticsFromDate) +
                      "&DateTo=" +
                      FormatDateOrEmptyString(statisticsToDate),
                    "_blank"
                  )
                }
              >
                <FontAwesomeIcon icon={faFileExport} /> Export send invoice list
              </Button>
              <br />
              <OverlayTrigger
                placement="bottom"
                delay={{ hide: 400 }}
                overlay={
                  <Popover>
                    <Popover.Content>
                      Export invoicing list
                      <br />
                      Status, OrderNr, Name, Notes, Amount <br />
                    </Popover.Content>{" "}
                  </Popover>
                }
              >
                <FontAwesomeIcon icon={faQuestionCircle} />
              </OverlayTrigger>
            </Col>
            <Col xs="auto">
              <Button
                size="sm"
                onClick={() =>
                  window.open(
                    "/Statistics/GetStatisticsForProduction?DateFrom=" +
                      FormatDateOrEmptyString(statisticsFromDate) +
                      "&DateTo=" +
                      FormatDateOrEmptyString(statisticsToDate) +
                      "&returnManagerId=" +
                      (selectedReturnManager ? selectedReturnManager : ""),
                    "_blank"
                  )
                }
              >
                <FontAwesomeIcon icon={faFileExport} /> Daily statistics for
                Production
              </Button>
              <br />
              <OverlayTrigger
                placement="bottom"
                delay={{ hide: 400 }}
                overlay={
                  <Popover>
                    <Popover.Content>
                      Statistics for Production
                      <br />
                      1. Total RO's created today
                      <br />
                      2. Following production information
                      <br />
                      Total RO's that went from processing to open and number of
                      lines
                      <br />
                      Total RO's that went Processing to Advance replacement and
                      number of lines
                      <br />
                      Total RO's that went from Processing to replacement sent
                      and number of lines
                      <br />
                      Total RO's that went from Open to Send replacement and
                      number of lines
                      <br />
                      Total RO's that went From Open to authorize and number of
                      lines
                      <br />
                      Total RO's that went from Open to decline and number of
                      lines
                      <br />
                      Total RO's that went From Advance replacement to decline
                      (send invoice) and number of lines
                      <br />
                      Total RO's that went Advance replacement to Send
                      replacement and number of lines
                      <br />
                      Errands marked as received and number of lines
                      <br />
                      Total number of errand created as oulöst paket and number
                      of lines.
                      <br />
                      Sum of all above will give us total production
                      <br />
                      Only for Norwegian errand Tull needed ( Total of above
                      mentioned type errands line - Total RO's lines that went
                      from Processing to replacement sent ) <br />
                      Total RO's that went from Authorize to credited(refunded)
                      <br />
                      Total RO's that went from send invoice to invoice sent
                    </Popover.Content>
                  </Popover>
                }
              >
                <FontAwesomeIcon icon={faQuestionCircle} />
              </OverlayTrigger>
            </Col>
          </Row>
        </Card.Header>
      </Card>
      <br />

      <Card>
        <Card.Header>
          <Row>
            <Col>
              <span className="font-size14 bold">Return Reason</span>
              <Form.Control
                as="select"
                size="sm"
                value={selectedReturnReason}
                onChange={(e) => setSelectedReturnReason(e.target.value)}
              >
                <option value="">Select Reason</option>
                {returnReasons.map((rm, id) => {
                  return (
                    <option key={id} value={rm.Id}>
                      {t(rm.Text)}
                    </option>
                  );
                })}
              </Form.Control>
            </Col>
            <Col>
              <span className="font-size14 bold">Select Status</span>
              <Form.Control
                as="select"
                size="sm"
                value={selectedReturnStatus}
                onChange={(e) => setSelectedReturnStatus(e.target.value)}
              >
                <option value="">Select Status</option>
                {returnStatuses.map((rm, id) => {
                  return (
                    <option key={id} value={rm.Id}>
                      {t(rm.Text)}
                    </option>
                  );
                })}
              </Form.Control>
            </Col>
            <Col>
              <span className="font-size14 bold">
                {t("ReturnManager.Statistics_DateFrom")}
              </span>
              <br />
              <DatePicker
                placeholderText={t("ReturnManager.Statistics_SelectfromDate")}
                maxDate={new Date()}
                selected={statisticsSecondaryFromDate}
                onChange={(date) => setStatisticsSecondaryFromDate(date)}
                dateFormat="yyyy-MM-dd"
              />
            </Col>
            <Col>
              <span className="font-size14 bold">
                {t("ReturnManager.Statistics_DateTo")}
              </span>
              <br />
              <DatePicker
                placeholderText={t("ReturnManager.Statistics_SelecttoDate")}
                minDate={statisticsSecondaryFromDate}
                selected={statisticsSecondaryToDate}
                onChange={(date) => setStatisticsSecondaryToDate(date)}
                dateFormat="yyyy-MM-dd"
              />
            </Col>
          </Row>
          <Row className="paddingBefore20">
            <Col xs="auto">
              <Button
                size="sm"
                onClick={() =>
                  window.open(
                    "/Statistics/GetReportForAllItemStatus?RetReasonIdentityId=" +
                      (selectedReturnReason ? selectedReturnReason : "") +
                      "&ReturnStatusId=" +
                      (selectedReturnStatus ? selectedReturnStatus : "") +
                      "&DateFrom=" +
                      FormatDateOrEmptyString(statisticsSecondaryFromDate) +
                      "&DateTo=" +
                      FormatDateOrEmptyString(statisticsSecondaryToDate),
                    "_blank"
                  )
                }
              >
                <FontAwesomeIcon icon={faFileExport} /> Export product status
              </Button>
              <br />
              <OverlayTrigger
                placement="bottom"
                delay={{ hide: 400 }}
                overlay={
                  <Popover>
                    <Popover.Content>
                      User can download reports as per selected reason and
                      selected return order status.
                      <br />
                      For example return manager want to see the report of
                      "Transport Damage" open cases for last two months.
                      <br />
                      In this case user will select return reason "Transport
                      Damage", select return status "OPEN", "Date from" and
                      "Date to" for dates.
                      <br />
                      If user will not select return reason, then it will show
                      result of all reasons.
                      <br />
                      If user will not select return staus, then it will show
                      result of all return statuses.
                      <br />
                      If user will not select date from and date to, then it
                      will select last one year records without any date
                      information.
                    </Popover.Content>
                  </Popover>
                }
              >
                <FontAwesomeIcon icon={faQuestionCircle} />
              </OverlayTrigger>
            </Col>
            <Col xs="auto">
              <Button
                size="sm"
                onClick={() =>
                  window.open(
                    "/Statistics/getCalculatorAdditionalCost?RetReasonIdentityId=" +
                      (selectedReturnReason ? selectedReturnReason : "") +
                      "&ReturnStatusId=" +
                      (selectedReturnStatus ? selectedReturnStatus : "") +
                      "&DateFrom=" +
                      FormatDateOrEmptyString(statisticsSecondaryFromDate) +
                      "&DateTo=" +
                      FormatDateOrEmptyString(statisticsSecondaryToDate),
                    "_blank"
                  )
                }
              >
                <FontAwesomeIcon icon={faFileExport} /> Export Calc Additional
                Cost
              </Button>
              <br />
              <OverlayTrigger
                placement="bottom"
                delay={{ hide: 400 }}
                overlay={
                  <Popover>
                    <Popover.Content>
                      User can download reports for calculator additional
                      charges and any discount/deduction given by return
                      manager.
                      <br />
                      For example return manager want to see the report of total
                      discount given for "warranty" return reason last month for
                      all status.
                      <br />
                      In this case user will select return reason "Warranty",
                      "Date from" and "Date to" for dates.
                      <br />
                      If user will not select return reason, then it will show
                      result of all reasons.
                      <br />
                      If user will not select return staus, then it will show
                      result of all return statuses.
                      <br />
                      If user will not select date from and date to, then it
                      will select last one year records without any date
                      information.
                    </Popover.Content>
                  </Popover>
                }
              >
                <FontAwesomeIcon icon={faQuestionCircle} />
              </OverlayTrigger>
            </Col>
          </Row>
        </Card.Header>
      </Card>
    </Container>
  );
}

function FormatDateOrEmptyString(date) {
  return date ? moment(date).format("YYYY-MM-DD") : "";
}
