import React, { useContext } from "react";
import { Button, Table, Row, Col, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

import { SessionContext } from "../Context";

export default function ConfirmReturn({ Order }) {
  const { t } = useTranslation("common");
  const sessionInfo = useContext(SessionContext);

  if (Order.OrderItems === undefined || Order.OrderItems.length === 0)
    return null;

  return (
    <React.Fragment>
      <Row>
        <Col>
          <div className="font-size22">
            {t("Step3.Return_of_orders")} - {Order.OrderItems[0].OrderId}
          </div>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col>
          <ReturnOrderItemsComponent
            OrderItems={Order.OrderItems}
          ></ReturnOrderItemsComponent>
        </Col>
      </Row>
      <Modal
        show={true}
        onHide={() => {
          return;
        }}
      >
        <Modal.Header className="headerBar text-white">
          <Modal.Title>
            {t("General.GeneralText10")} - {Order.ReturnResult.RetOrderTag}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateModalBody />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              window.location.href = sessionInfo.Domain;
            }}
          >
            {t("General.GeneralText9")}
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );

  function CreateModalBody() {
    if (Order.ReturnResult.IsAutoTALabel && Order.ReturnResult.HandleBySkruvat) {
      return (
        <div className="font-size14">
          {t("General.GeneralText5")}
          <br />
          {t("General.GeneralText5a")}
          <hr />
          <span className="font-size18 ">{t("General.GeneralText6")}</span>
          <br />
          <a
            href={Order.ReturnResult.WaybillLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faPrint} /> {t("General.GeneralText7")}
          </a>
          <br />
          <br />
          {t("General.GeneralText8")}
        </div>
      );
    } else if(Order.ReturnResult.HandleBySkruvat) {
        return (
          <p className="font-size14">
            {t("General.GeneralText3")}
            <br />
            {t("General.GeneralText4")}
            <br />
            {t("General.GeneralText4a")}
            <br />
            {t("General.GeneralText4b")}
          </p>
        );
    } else {
        return (
          <p className="font-size14">
            {t("General.GeneralText3")}
          </p>
        );
    }
  }

  function ReturnOrderItemsComponent({ OrderItems }) {
    return (
      <Table>
        <thead>
          <tr>
            <th>{t("OrderItems.Product")}</th>
            <th>{t("Product.ProductId")}</th>
            <th>{t("OrderItems.QuantityEdit")}</th>
          </tr>
        </thead>
        <tbody>
          {OrderItems.map((order, key) => {
            return (
              <ReturnOrderItem
                key={key}
                id={key}
                item={order}
              ></ReturnOrderItem>
            );
          })}
        </tbody>
      </Table>
    );
  }

  function ReturnOrderItem({ id, item }) {
    return (
      <tr>
        <td>{item.Product.Name}</td>
        <td>{item.Product.ProductId}</td>
        <td>{item.Quantity}</td>
      </tr>
    );
  }
}
