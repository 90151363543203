import React, { useState } from "react";
import { Button, Row, Col, Alert, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export function ListImagesComponent({ ImageList, RemoveImageHandler }) {
  const [errorString, setErrorString] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [modalImageUrl, setModalImageUrl] = useState("");
  const { t } = useTranslation("common");

  return (
    <React.Fragment>
      <ImageModal />
      <ImageListComponent
        List={ImageList}
        RemoveImageHandler={RemoveImageHandler}
      />
      <Row>
        <Col>
          <br />
          <Alert
            variant="danger"
            hidden={errorString.length === 0}
            onClose={() => setErrorString("")}
            dismissible
          >
            {errorString}
          </Alert>
        </Col>
      </Row>
    </React.Fragment>
  );

  function ImageListComponent({ List, RemoveImageHandler }) {
    var bilder = List.map((image, idx) => {
      return (
        <ImageComponent
          key={idx}
          Image={image}
          RemoveImageHandler={RemoveImageHandler}
        />
      );
    });

    return bilder;
  }

  function ImageComponent({ Image, RemoveImageHandler }) {
    var imgUrl = Image.Url;

    if (Image.FileType === "txt") imgUrl = "/return/Images/txt-icon.png";
    if (Image.FileType === "pdf") imgUrl = "/return/Images/pdf-icon.png";
    if (Image.FileType === "xls") imgUrl = "/return/Images/Excel-icon.png";
    if (Image.FileType === "doc") imgUrl = "/return/Images/Word-icon.png";

    return (
      <span className="product-box">
        <div>
          <img
            key={Image.FileName}
            src={imgUrl}
            alt={Image.FileName}
            className="product-img-box product-thumbnail thumbnail"
            onClick={() => {
              if (
                Image.FileType === "txt" ||
                Image.FileType === "pdf" ||
                Image.FileType === "xls" ||
                Image.FileType === "doc"
              ) {
                window.open(Image.Url, "_blank");
              } else {
                ShowImageModal(imgUrl);
              }
            }}
          />
          <br />
          <br />
        </div>
        <div hidden={!RemoveImageHandler} className="width250">
          <div className="vertical-middle">
            {t("Step4.RemoveUploadedPicture")}
            <br />
            <Button
              key={Image.FileName + "btn"}
              variant="outline-secondary"
              size="sm"
              onClick={() => {
                RemoveImageHandler(Image);
              }}
            >
              {t("General.Remove")}
            </Button>
          </div>
        </div>
      </span>
    );
  }

  function ImageModal() {
    return (
      <Modal show={modalShow} onHide={HideImageModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t("ReturnManager.UploadedImages")}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <img className="modalImage" src={modalImageUrl} alt="uploaded file" />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={HideImageModal}>
            {t("General.Close")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  function ShowImageModal(imgUrl) {
    setModalImageUrl(imgUrl);
    setModalShow(true);
  }

  function HideImageModal() {
    setModalImageUrl("");
    setModalShow(false);
  }
}
