import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import CustomerApiClient from "../ApiClients/CustomerApiClient";
import CustomerOrder from "./CustomerOrder";
import ReturnOrClaim from "./ReturnOrClaim";
import Return from "./Return";
import Claim from "./Claim";
import ConfirmReturn from "./ConfirmReturn";
import { SessionContext, LoadingContext } from "../Context";

function ReturnProcess() {
  const sessionInfo = useContext(SessionContext);
  const loader = useContext(LoadingContext);
  const { i18n } = useTranslation("common");
  const history = useHistory();

  const [step, setStep] = useState(0);
  const [order, setOrder] = useState({ OrderId: sessionInfo.OrderId });
  const [orderUnfiltered, setOrderUnfiltered] = useState({
    OrderId: sessionInfo.OrderId,
  });

  // To allow browser button backward/forward navigation we set current step based on location state.
  // If state is 1, we need to reset list of orderitems to unfiltered
  useEffect(() => {
    if (history.location.state === 1) {
      setOrder((prev) => {
        return {
          ...prev,
          OrderItems: orderUnfiltered.OrderItems,
        };
      });
    }

    setStep(history.location.state);
  }, [history.location, orderUnfiltered]);

  // Actual update of step state occur in useEffect triggered by history.location changes above.
  function updateState(state) {
    history.push("ReturnProcess", state);
  }

  useEffect(() => {
    loader.updateShowLoader(true);
    i18n.changeLanguage(sessionInfo.Language);

    async function FetchOrderItems(orderId) {
      return await CustomerApiClient.GetOrderItems(orderId);
    }

    FetchOrderItems(sessionInfo.OrderId).then((result) => {
      result.forEach((e) => {
        e.ReturnQuantity = 0;
        e.selected = false;
      });

      setOrderUnfiltered({
        OrderId: order.OrderId,
        OrderDate: result[0].OrderDate,
        OrderItems: result,
      });

      updateState(1);
      loader.updateShowLoader(false);
    });
  }, [sessionInfo.Language, sessionInfo.OrderId, order.OrderId, i18n]);

  return <SwitchStep step={step} />;

  function SwitchStep({ step }) {
    switch (step) {
      case 1:
        return (
          <Container className="whiteContainer mobileResponsiveMarginFix paddingBefore20 paddingAfter20">
            <CustomerOrder
              Order={order}
              HandleOrderItemsChange={HandleOrderItemsChange}
              HandleNextStep={HandleNextStep}
            />
          </Container>
        );
      case 2:
        return (
          <Container className="whiteContainer mobileResponsiveMarginFix paddingBefore20 paddingAfter20">
            <ReturnOrClaim Order={order} HandleNextStep={HandleNextStep} />
          </Container>
        );
      case 3:
        return (
          <Container className="whiteContainer mobileResponsiveMarginFix paddingBefore20 paddingAfter20">
            <Return
              Order={order}
              HandleNextStep={HandleNextStep}
              OnReturnHandled={HandleReturnCompleted}
            />
          </Container>
        );
      case 4:
        return (
          <Container className="whiteContainer mobileResponsiveMarginFix paddingBefore20 paddingAfter20">
            <Claim
              Order={order}
              HandleNextStep={HandleNextStep}
              OnClaimHandled={HandleReturnCompleted}
            />
          </Container>
        );
      case 5:
        return (
          <Container className="whiteContainer mobileResponsiveMarginFix paddingBefore20 paddingAfter20">
            <ConfirmReturn Order={order} />
          </Container>
        );
      default:
        return null;
    }
  }

  function HandleReturnCompleted(result) {
    setOrder({
      OrderId: order.OrderId,
      OrderItems: order.OrderItems,
      ReturnResult: result,
    });
    setStep(5);
  }

  function HandleOrderItemsChange(updatedOrderItems) {
    setOrder({ OrderId: order.OrderId, OrderItems: [...updatedOrderItems] });
  }

  function HandleNextStep(stepSpecified) {
    // Only include selected orders
    setOrder({
      OrderId: order.OrderId,
      OrderItems: [...order.OrderItems.filter((o) => o.selected)],
    });

    updateState(stepSpecified ? stepSpecified : step + 1);
  }
}

export default ReturnProcess;
