import React, { useState, useEffect } from "react";
import { Pagination, Card, Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

export function SkruvatCheckbox({ checked, onChange, name, disabled = false }) {
  return (
    <input
      name={name}
      type="checkbox"
      checked={checked}
      disabled={disabled}
      onChange={() =>
        onChange({ target: { name: name, value: checked ? false : true } })
      }
      // className="styled-checkbox"
    />
  );
}

export function SkruvatRadioButton({
  checked,
  onChange,
  value,
  name,
  disabled,
  label,
  className="choice-status"
}) {
  return (
    <div className={className}>
      <input
        type="radio"
        checked={checked}
        disabled={disabled}
        onChange={() => {}}
      />
      <label
        onClick={
          disabled
            ? () => {}
            : () => onChange({ target: { name: name, value: value } })
        }
      >
        <div className="control-indicator"></div>
        {label}
      </label>
    </div>
  );
}

export function SkruvatPagination({ onPageChanged, totalPages }) {
  const PageSpan = 5;
  const [currentPage, setCurrentPage] = useState(1);

  const setPage = (pageNo) => {
    setCurrentPage(pageNo);
    onPageChanged(pageNo);
  };

  const firstPage = () => setPage(1);
  const previousPage = () => {
    setPage(currentPage - 1);
  };
  const nextPage = () => {
    setPage(currentPage + 1);
  };
  const lastPage = () => {
    setPage(totalPages);
  };

  return (
    <Pagination>
      <Pagination.First onClick={firstPage} disabled={currentPage === 1} />
      <Pagination.Prev onClick={previousPage} disabled={currentPage === 1} />
      <RenderPages />
      <Pagination.Next
        onClick={nextPage}
        disabled={currentPage === totalPages}
      />
      <Pagination.Last
        onClick={lastPage}
        disabled={currentPage === totalPages}
      />
    </Pagination>
  );

  function RenderPages() {
    var pages = [];

    if (currentPage !== 1) {
      pages.push(
        <Pagination.Item key={1} onClick={() => setPage(1)}>
          {1}
        </Pagination.Item>
      );
    }

    if (currentPage - PageSpan > 0) {
      pages.push(<Pagination.Ellipsis key="ep1" disabled />);
    }

    for (let i = Math.max(2, currentPage - PageSpan); i < currentPage; i++) {
      pages.push(
        <Pagination.Item key={i} onClick={() => setPage(i)}>
          {i}
        </Pagination.Item>
      );
    }

    pages.push(
      <Pagination.Item key={currentPage} active>
        {currentPage}
      </Pagination.Item>
    );

    for (
      let i = currentPage + 1;
      i < Math.min(currentPage + PageSpan, totalPages);
      i++
    ) {
      pages.push(
        <Pagination.Item key={i} onClick={() => setPage(i)}>
          {i}
        </Pagination.Item>
      );
    }

    if (currentPage + PageSpan < totalPages) {
      pages.push(<Pagination.Ellipsis key="ep2" disabled />);
    }

    if (currentPage !== totalPages) {
      pages.push(
        <Pagination.Item key={totalPages} onClick={() => setPage(totalPages)}>
          {totalPages}
        </Pagination.Item>
      );
    }

    return pages;
  }
}

export function SkruvatCollapsableCard({
  Hidden,
  Title,
  children,
  ExternalControl,
  ShowByDefault,
}) {
  const [collapsed, setCollapsed] = useState(
    ExternalControl === undefined && !ShowByDefault
  );

  useEffect(() => {
    if (ExternalControl !== undefined) {
      setCollapsed((previousValue) => !previousValue);
    }
  }, [ExternalControl]);

  return (
    <Card hidden={Hidden}>
      <Card.Header
        className="font-size18 pointer "
        onClick={() => {
          ToggleShow(Title);
        }}
      >
        {Title}
        <div className="float-right">
          <FontAwesomeIcon icon={collapsed ? faAngleDown : faAngleUp} />
        </div>
      </Card.Header>
      <Card.Body hidden={collapsed}>{children}</Card.Body>
    </Card>
  );

  function ToggleShow() {
    setCollapsed((prev) => {
      return !prev;
    });
  }
}

export function ActivityAndEmailLogs({ ActivityLogs, EmailLogs, Translate }) {
  const t = Translate;
  const [emailModalObject, setEmailModalObject] = useState({});

  return (
    <>
      <span className="font-size14 bold">Activity Logs</span>
      <table>
        <thead>
          <tr className="font-size14">
            <th>Date</th>
            <th>Name</th>
            <th>Activity</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <RenderActivityLogs Logs={ActivityLogs} />
        </tbody>
      </table>
      <br />
      <span className="font-size14 bold">Email Logs</span>
      <table>
        <thead>
          <tr className="font-size14">
            <th>Date</th>
            <th>Name</th>
            <th>Activity</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <RenderEmailLogs
            Logs={EmailLogs}
            OnViewEmailClicked={setEmailModalObject}
          />
        </tbody>
      </table>
      <Modal
        show={emailModalObject.Subject !== undefined}
        onHide={() => setEmailModalObject({})}
      >
        <Modal.Header className="headerBar text-white" closeButton>
          <Modal.Title>{emailModalObject.Subject}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div dangerouslySetInnerHTML={{ __html: emailModalObject.Body }} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setEmailModalObject({})}>
            {t("General.Close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );

  function RenderActivityLogs({ Logs }) {
    if (Logs === undefined || Logs.length === 0)
      return (
        <tr>
          <td colSpan="4">{t("General.NoRecordsFound")}</td>
        </tr>
      );

    return Logs?.map((activity, index) => {
      return (
        <tr key={index} className="font-size14">
          <td>
            {moment(activity.CreatedDate, "DD/MM/YYYY").format("YYYY-MM-DD")}
          </td>
          <td>{activity.ReturnManagerName}</td>
          <td>{activity.ActivityName}</td>
          <td>{activity.ActivityState}</td>
        </tr>
      );
    });
  }

  function RenderEmailLogs({ Logs, OnViewEmailClicked }) {
    if (Logs === undefined || Logs.length === 0)
      return (
        <tr>
          <td colSpan="4">{t("General.NoRecordsFound")}</td>
        </tr>
      );

    return Logs?.map((email, index) => {
      return (
        <tr key={index} className="font-size14">
          <td>{moment(email.CreatedDate).format("YYYY-MM-DD")}</td>
          <td>{email.ToMail}</td>
          <td>{email.EmailSubject}</td>
          <td>
            <Button
              variant="outline-secondary"
              onClick={() =>
                OnViewEmailClicked({
                  Subject: email.EmailSubject,
                  Body: email.EmailBody,
                })
              }
            >
              View email
            </Button>
          </td>
        </tr>
      );
    });
  }
}
