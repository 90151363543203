import React from "react";
import { Table, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import "react-datepicker/dist/react-datepicker.css";

import CustomerApiClient from "../ApiClients/CustomerApiClient";
import SkruvatTimelimits from "../SkruvatHelperFunctions";
import useWindowSize from "../CustomHooks/useWindowSize";

import ClaimComponent from "../Components/ClaimComponent";

export default function Claim({ Order, OnClaimHandled }) {
  const { t } = useTranslation("common");
  const { isMobile } = useWindowSize();

  if (Order.OrderItems === undefined || Order.OrderItems.length === 0)
    return null;

  return (
    <React.Fragment>
      <Row>
        <Col>
          <div className="font-size22">
            {t("Step3.Return_of_orders")} - {Order.OrderItems[0].OrderId}
          </div>
          <div className="font-size14">{t("Step3.Step3Header_BelowText1")}</div>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col>
          {isMobile ? (
            <ClaimOrderItemsComponentMobile OrderItems={Order.OrderItems} />
          ) : (
            <ClaimOrderItemsComponent OrderItems={Order.OrderItems} />
          )}
        </Col>
      </Row>
      <hr></hr>
      {ClaimComponent(
        CustomerApiClient,
        Order,
        OnClaimHandled,
        SkruvatTimelimits
      )}
    </React.Fragment>
  );

  function ClaimOrderItemsComponent({ OrderItems }) {
    return (
      <Table>
        <thead>
          <tr>
            <th>{t("OrderItems.Product")}</th>
            <th>{t("Product.ProductId")}</th>
            <th>{t("OrderItems.QuantityEdit")}</th>
          </tr>
        </thead>
        <tbody>
          {OrderItems.map((order, key) => {
            return (
              <ClaimOrderItem key={key} id={key} item={order}></ClaimOrderItem>
            );
          })}
        </tbody>
      </Table>
    );
  }

  function ClaimOrderItemsComponentMobile({ OrderItems }) {
    return OrderItems.map((order, key) => {
      return (
        <Table key={key}>
          <tbody>
            <ClaimOrderItemMobile id={key} item={order} />
          </tbody>
        </Table>
      );
    });
  }

  function ClaimOrderItem({ id, item }) {
    return (
      <tr>
        <td>{item.Product.Name}</td>
        <td>{item.Product.ProductId}</td>
        <td>{item.Quantity}</td>
      </tr>
    );
  }

  function ClaimOrderItemMobile({ id, item }) {
    return (
      <tr>
        <td>
          <Row>
            <Col>
              <span className="bold">{t("OrderItems.Product")}</span>
              <br />
              {item.Product.Name}
            </Col>
            <Col>
              <span className="bold">{t("Product.ProductId")}</span>
              <br />
              {item.Product.ProductId}
            </Col>
          </Row>
          <Row className="paddingBefore10">
            <Col>
              <span className="bold">{t("OrderItems.QuantityEdit")}</span>
              <br />
              {item.Quantity}
            </Col>
            <Col></Col>
          </Row>
        </td>
      </tr>
    );
  }
}
